import React, { useState } from 'react';
import { Drawer, FormControl, FormControlLabel, Radio, RadioGroup, Button, IconButton, Typography, Box, drawerClasses } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RadioControl from '../../elements/RadioControl';
import { OrderBy } from '../../types/types';

interface FilterDrawerProps {
  handleRequestSort: (property: keyof OrderBy, order: string) => void; 
  open: boolean;
  onClose: () => void;
  options: { value: string; label: string }[];
}

const FilterDrawer: React.FC<FilterDrawerProps> = ({ handleRequestSort, open, onClose, options }) => {
  const [selectedValue, setSelectedValue] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const handleApply = () => {
    const value = selectedValue.split('-');
    const order: any = value[0];
    const orderBy = value[1];
    handleRequestSort(order, orderBy);
    onClose();
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          boxShadow: 'none',
          borderLeft: '1px solid #DDDFDD',
          maxWidth: '375px',
          width: '100%',
        }
      }}
    >
      <Box sx={{
        pt: '100px',
        maxWidth: '375px',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Box sx={{
          px: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Typography variant='h1' component={'h2'} sx={{ mb: '16px' }}>Filter</Typography>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              fontSize: '40px',
              cursor: 'pointer'
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
              <rect width="40" height="40" />
              <path d="M27.0713 12.929L12.9292 27.0711" stroke="#666666" />
              <path d="M27.0713 27.071L12.9292 12.9289" stroke="#666666" />
            </svg>
          </IconButton>
        </Box>
        <Box sx={{
          px: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 'auto',
        }}>
          <FormControl component="fieldset">
            <RadioGroup value={selectedValue} onChange={handleChange}>
              {options.map((option, index) => (
                <FormControlLabel key={index} value={option.value} control={<RadioControl />} label={option.label} />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
        <Box sx={{
          borderTop: '1px solid #DDDFDD'
        }}>
          <Box sx={{
            px: '20px',
            py: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Button variant="contained" color="primary" fullWidth onClick={handleApply}>
              Filter
            </Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default FilterDrawer;
