import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  useTheme,
  SvgIcon,
  IconButton,
  Typography,
} from '@mui/material';
import { useForm, SubmitHandler } from "react-hook-form"
import { useNavigate } from 'react-router-dom';
import { useResponseError } from '../../../hooks/useResponseError';
import InputPassword from '../../../elements/InputPassword';
import { ErrorBox } from '../../../elements/ErrorBox';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { passwordRegisterValidation } from '../../../components/Auth/validation';
import { passwordPatch } from '../../../services/userService';

type Inputs = {
  oldPassword: string,
  password: string,
  passwordConfirm: string,
}

const ResetPassword = () => {
  const { palette, typography } = useTheme();
  const { responseError, setResponseError } = useResponseError(50000);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const passwordValidationArr = [
    'Password must have at least 8 characters',
    'Password must contain at least one uppercase letter',
    'Password must contain at least one symbol',
    'Password must contain at least one lowercase letter',
    'Password must contain at least one number',
  ];

  const passwordValidationErrorsDic: any = {
    'Password must have at least 8 characters': 'Minimum 8 characters',
    'Password must contain at least one uppercase letter': 'One uppercase ',
    'Password must contain at least one symbol': 'One symbol',
    'Password must contain at least one lowercase letter': 'One lowercase',
    'Password must contain at least one number': 'One number',
  };

  const renderPasswordErrors = (err: any, fieldName: string) => {
    const validationArr = passwordValidationArr;

    return (
      <Box sx={{
        pr: '12px',
      }}>
        {validationArr.map((item, index) => {
          const errorStepMsg = passwordValidationErrorsDic[item];

          let isValidPoint;
          if (index === 0) {
            isValidPoint = err.types?.min;
          } else {
            isValidPoint = err.types?.matches && err.types.matches.includes(item.replace('Password', fieldName));
          }

          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: !isValidPoint ? palette.text.secondary : palette.text.disabled,
              }}
              key={index}
            >
              <SvgIcon sx={{
                mr: '8px'
              }}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="16" height="16" fill="white" />
                  <path d="M4 7.25L7.46154 11L13 5" stroke={!isValidPoint ? "#3D8F44" : "#DDDFDD"} strokeWidth="2" />
                </svg>
              </SvgIcon>
              {errorStepMsg.trim()}
            </Box>
          )
        })}
      </Box>
    );
  }


  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue
  } = useForm({
    criteriaMode: 'all',
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const formatData = data;

    try {
      setResponseError('');
      const res = await passwordPatch({
        ...formatData,
      })
      handleClickOpen();
    } catch (error: unknown) {
      const errMsg = (error as Error).message;
      if (errMsg === 'invalid credentials') {
        setError('oldPassword', { message: 'Password incorrect' });
      } else {
        setResponseError(errMsg);
        handleClickOpen();
      }
    }
  }

  return <Box
    sx={{
      maxWidth: '315px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}>
    <Box
      component="form"
      sx={[
        {
          width: '100%',
        },
      ]}
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      id="hook-form"
    >
      <Typography
        variant="h1"
        sx={{
          mb: '32px'
        }}
      >
        New password
      </Typography>
      <Box sx={{
        mb: '16px'
      }}>
        <InputPassword
          label={'Old password'}
          inputProps={{ ...register('oldPassword') }}
          placeholder='Old password'
          error={!!errors['oldPassword']}
          fullWidth
        />
        {errors['oldPassword'] &&
          <ErrorBox sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          >{errors['oldPassword'].message}</ErrorBox>}
      </Box>
      <Box sx={{
        mb: '16px'
      }}>
        <InputPassword
          label={'New Password'}
          inputProps={{ ...register('password') }}
          placeholder='Password'
          error={!!errors['password']}
          fullWidth
        />
        {errors['password'] && renderPasswordErrors(errors['password'], 'Password')}
      </Box>
      <Box sx={{
        mb: '16px'
      }}>
        <InputPassword
          label={'Repeat New Password'}
          inputProps={{ ...register('passwordConfirm') }}
          placeholder='Repeat New Password'
          error={!!errors['passwordConfirm']}
          fullWidth
        />
        {errors['passwordConfirm'] &&
          <ErrorBox sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          >{errors['passwordConfirm'].message}</ErrorBox>}
      </Box>
      {responseError && <ErrorBox
        sx={{
          mb: '8px',
          textAlign: 'left',
        }}>
        {responseError || 'Something went wrong'}
      </ErrorBox>
      }
      <Button fullWidth variant="contained" type="submit">Change password</Button>
    </Box>
    <Box sx={{
      mt: '28px',
      width: '100%',
      borderTop: '1px solid #DDDFDD'
    }}>
      <Button
        sx={{ mt: '32px' }}
        variant="table"
        href={'/dashboard/settings'}
      >
        Back to profile
      </Button>
    </Box>
    <Dialog
      sx={[{
        '.MuiDialog-paper': {
          px: '55px',
          paddingBottom: '35px',
          pt: '20px',
        }
      },
      (theme) => ({
        [theme.breakpoints.down('md')]: {
          '.MuiDialog-paper': {
            px: '15px',
          }
        }
      }),
      ]}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          fontSize: '40px',
          cursor: 'pointer'
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <rect width="40" height="40" />
          <path d="M27.0713 12.929L12.9292 27.0711" stroke="#666666" />
          <path d="M27.0713 27.071L12.9292 12.9289" stroke="#666666" />
        </svg>
      </IconButton>
      <DialogTitle sx={{
        fontWeight: '700',
        maxWidth: '300px',
        textAlign: 'center'
      }}>
        {responseError ? "Something went wrong" : `Change has been submitted successfully`}
      </DialogTitle>
      <DialogContent sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Box sx={{ textAlign: 'center', color: palette.error.main }}>{responseError}</Box>
      </DialogContent>
      <DialogActions
        sx={{
          maxWidth: '325px',
          justifyContent: 'center',
        }}
      >
        <Button
          sx={{
            padding: '7px 20px',
            borderRadius: '10px',
            width: '100%',
          }}
          variant="contained"
          onClick={() => {
            handleClose();
          }}
          autoFocus
        >
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  </Box>;
};

export default ResetPassword;

const schema = yup.object().shape({
  oldPassword: yup.string().required('This is required'),
  password: passwordRegisterValidation,
  passwordConfirm: yup.string()
    .required('Please confirm your password')
    .oneOf([yup.ref('password'), ''], 'Passwords do not match')
});