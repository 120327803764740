import React from 'react';
import {
  Drawer,
  IconButton,
  Box,
  Link as MuiLink,
  Typography,
  drawerClasses,
  SvgIcon
} from '@mui/material';

type Props = {
  open: boolean
  onClose: () => void
}

export const ContactsDrawer = ({ open, onClose }: Props) => {

  const handleClose = () => {
    onClose()
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      hideBackdrop
      sx={{
        [`& .${drawerClasses.paper}`]: {
          boxShadow: 'none',
          borderLeft: '1px solid #DDDFDD',
          maxWidth: '570px',
          width: '100%',
        }
      }}
    >
      <Box sx={{
        py: 3.5,
        px: 5,
        height: 1,
        maxWidth: '570px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Box sx={{
          height: 1,
          maxWidth: '315px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center'
        }}>
          <SvgIcon
            sx={{
              width: '50px',
              height: '50px',
              mb: '16px',
            }}>
            <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="48" height="48" transform="translate(0.5)" fill="white" />
              <rect width="40" height="40" transform="translate(4.5 4)" fill="white" />
              <path d="M41.1663 21.2219V26.7775C41.1663 28.3096 39.9207 29.5552 38.3886 29.5552H37.6323C36.57 35.8508 31.0936 40.6663 24.4997 40.6663C23.8855 40.6663 23.3886 40.1694 23.3886 39.5552C23.3886 38.9411 23.8855 38.4441 24.4997 38.4441C30.626 38.4441 35.6108 33.4593 35.6108 27.333V20.6663C35.6108 14.54 30.626 9.55523 24.4997 9.55523C18.3734 9.55523 13.3886 14.54 13.3886 20.6663V28.4441C13.3886 29.0583 12.8916 29.5552 12.2775 29.5552H10.6108C9.07867 29.5552 7.83301 28.3096 7.83301 26.7775V21.2219C7.83301 19.6898 9.07867 18.4441 10.6108 18.4441H11.3671C12.4294 12.1485 17.9057 7.33301 24.4997 7.33301C31.0936 7.33301 36.57 12.1485 37.6323 18.4441H38.3886C39.9207 18.4441 41.1663 19.6898 41.1663 21.2219ZM24.4997 11.7775C19.5984 11.7775 15.6108 15.7651 15.6108 20.6663V26.2219C15.6108 31.1232 19.5984 35.1108 24.4997 35.1108C29.4009 35.1108 33.3886 31.1232 33.3886 26.2219V20.6663C33.3886 15.7651 29.4009 11.7775 24.4997 11.7775Z" fill="#8BC33C" />
              <path d="M21.6484 21.4327L21.6484 28.0737C21.6487 28.3801 21.7725 28.6775 21.9997 28.9174L23.535 30.5362C23.644 30.6492 23.7801 30.7416 23.9346 30.8074C24.0891 30.8733 24.2583 30.911 24.4314 30.9183C24.6044 30.9255 24.7774 30.9021 24.9389 30.8494C25.1004 30.7968 25.2468 30.7162 25.3687 30.6129L25.4697 30.5281L27.005 28.9093C27.231 28.6701 27.3563 28.3749 27.3612 28.0697L27.3563 21.4246L21.6484 21.4327Z" fill="white" />
              <path d="M25.7629 15.6123L23.2506 15.6123C23.04 15.612 22.8314 15.6465 22.6368 15.7139C22.4422 15.7814 22.2653 15.8804 22.1164 16.0053C21.9675 16.1302 21.8495 16.2785 21.7691 16.4417C21.6887 16.605 21.6475 16.78 21.6479 16.9566L21.6479 18.9104L27.3655 18.9104L27.3655 16.9566C27.3659 16.78 27.3248 16.605 27.2444 16.4417C27.164 16.2785 27.0459 16.1302 26.897 16.0053C26.7481 15.8804 26.5713 15.7814 26.3767 15.7139C26.182 15.6465 25.9734 15.612 25.7629 15.6123Z" fill="white" />
            </svg>
          </SvgIcon>
          <Typography variant='h1' component={'h2'} sx={{ mb: '16px' }}>
            Need to make a change?
          </Typography>
          <Typography sx={{
            mb: '32px',
          }}>
            To modify or cancel a Service, please reach out to Lucky Services via phone or via email
          </Typography>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <MuiLink
              sx={{
                mb: '16px',
                textTransform: 'none',
                fontSize: 'inherit',
                lineHeight: 'inherit'
              }}
              underline="always"
              href="tel: 970-749-0028"
              variant="body2"
            >
              970-749-0028
            </MuiLink>
            <MuiLink
              sx={{
                textTransform: 'uppercase',
                fontSize: 'inherit',
                lineHeight: 'inherit',
                borderBottom: 'none',
              }}
              underline="none"
              href="mailto:office@goluckyservices.com"
              variant="body2"
            >
              office@goluckyservices.com
            </MuiLink>
          </Box>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              fontSize: '40px',
              cursor: 'pointer'
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
              <rect width="40" height="40" />
              <path d="M27.0713 12.929L12.9292 27.0711" stroke="#666666" />
              <path d="M27.0713 27.071L12.9292 12.9289" stroke="#666666" />
            </svg>
          </IconButton>
        </Box>

      </Box>
    </Drawer>
  )
}