import axios, { AxiosInstance } from 'axios';
import { STORAGE_AUTH_TOKEN } from '../constants/storage';

export type ResponseError = {
  response: {
    data: {
      message: string | string[];
    };
  };
};

export const baseURL = process.env.REACT_APP_API_SERVER;

export const instance: AxiosInstance = axios.create({
  baseURL: `${baseURL}`,
  withCredentials: false,
  // headers: {
  //   'Authorization': '',
  // },
});

instance.interceptors.request.use(async (config) => {
  if (typeof window !== 'undefined') {
    const token = localStorage.getItem(STORAGE_AUTH_TOKEN) || '';
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
  }
  return config;
});

export default instance;
