import React from 'react';
import {
  Drawer,
  IconButton,
  Box,
  Link as MuiLink,
  Typography,
  drawerClasses,
  SvgIcon,
  Button
} from '@mui/material';

type Props = {
  open: boolean
  handleClose: () => void
  handleCloseDrawer: () => void
}

export const CancelDrawer = ({ open, handleClose, handleCloseDrawer }: Props) => {

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleCloseDrawer}
      hideBackdrop
      sx={{
        zIndex: '1300',
        [`& .${drawerClasses.paper}`]: {
          zIndex: '5',
          boxShadow: 'none',
          borderLeft: '1px solid #DDDFDD',
          maxWidth: '570px',
          width: '100%',
        }
      }}
    >
      <Box sx={{
        py: 3.5,
        px: 5,
        height: 1,
        maxWidth: '570px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Box sx={{
          height: 1,
          maxWidth: '315px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center'
        }}>
          <Typography variant='h1' component={'h2'} sx={{ mb: '16px' }}>
            Do you wish to cancel the service request creation?
          </Typography>
          <Typography sx={{
            mb: '32px',
          }}>
            Data will not be saved.
          </Typography>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%'
          }}>
            <Button sx={{ mb: '16px' }} fullWidth onClick={handleCloseDrawer} variant="table">Back to service request creation</Button>
            <Button
              fullWidth
              onClick={handleClose}
              variant="contained"
              color="error"
            >
              Cancel
            </Button>
          </Box>
          <IconButton
            onClick={handleCloseDrawer}
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              fontSize: '40px',
              cursor: 'pointer'
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
              <rect width="40" height="40" />
              <path d="M27.0713 12.929L12.9292 27.0711" stroke="#666666" />
              <path d="M27.0713 27.071L12.9292 12.9289" stroke="#666666" />
            </svg>
          </IconButton>
        </Box>

      </Box>
    </Drawer>
  )
}