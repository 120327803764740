import { useState, useEffect, createContext, useContext } from 'react';
import { Outlet, Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Container,
  SvgIcon,
  Typography,
  Tooltip,
  Button,
  CssBaseline,
} from '@mui/material';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import { Footer } from './Footer';
import { TBreadcrumbs } from '../../types/types';
import Breadcrumbs from './../../elements/Breadcrumbs';
import PostJobDialog from '../Job/PostJobDialog';
import { STORAGE_USER } from '../../constants/storage';

interface LaoyutContextType {
  value?: any
  isServiceUpdated: boolean
  openPostJob: boolean
  openPostJobRebook: (id: string) => void
}

const LaoyutContext = createContext<LaoyutContextType>(null!);

const LayoutDashboard = () => {
  const [user, setUser] = useState<any>(null);
  const location = useLocation();
  const theme = useTheme();
  const [route, setRoute] = useState('');
  const [rebookId, setRebookId] = useState('');
  const [openPostJob, setOpenPostJob] = useState(false);
  const userProfile = {
    firstName: 'Test'
  };
  const [links, setLinks] = useState<TBreadcrumbs[]>([{
    title: 'Home', url: '/'
  }]);
  const [isServiceUpdatedState, setIsServiceUpdatedState] = useState(false);
  const [showBreadcrumbs, setShowBreadcrumbs] = useState(false);

  useEffect(() => {
    if (localStorage && localStorage.getItem(STORAGE_USER)) {
      setUser(JSON.parse(localStorage.getItem(STORAGE_USER) || ''));
    }
  }, [location, location.pathname]);

  useEffect(() => {
    setRoute(location.pathname);
    const segments = location.pathname.split('/');
    const linksArr: TBreadcrumbs[] = segments.reduce((result: any, item) => {
      if (item !== "") {
        result.push({
          title: item === 'dashboard' ? `${user?.role === "ADMIN" ? 'Admin' : 'Client'} Dashboard` : item,
          url: `/${item}`
        });
      }
      return result;
    }, []);
    setLinks(linksArr);
    setShowBreadcrumbs(location.pathname.includes('/dashboard/questionnaire') ? false : true);
  }, [location.pathname]);

  const handleClickOpenPostJobRebook = (id: string) => {
    setRebookId(id);
    setOpenPostJob(true);
    setIsServiceUpdatedState(false);
  };


  const handleClickOpen = () => {
    setRebookId('');
    setOpenPostJob(true);
    setIsServiceUpdatedState(false);
  };

  const handleClickClose = () => {
    setOpenPostJob(false);
    setIsServiceUpdatedState(true);
    setRebookId('');
  };

  return (
    <Box sx={[
      {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '60px',
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        overflow: 'auto'
      },
    ]}>
      <CssBaseline />
      <Box sx={[
        {
          minHeight: '60px',
          borderBottom: '1px solid #DDDFDD'
        },
      ]}>
        <Box component={'header'}>
          <Container maxWidth={false}>
            <Box sx={{
              py: '14px',
              display: 'flex',
              alignItems: 'center',
              maxWidth: '100%',
              width: '100%'
            }}>
              <Box sx={{
                display: 'flex',
                maxWidth: '80px',
                width: '100%',
              }}>
                <Tooltip title="Home">
                  <Link to={'/dashboard'}>
                    <img src="/Logo80.png" alt="logo" />
                  </Link>
                </Tooltip>
              </Box>
              <Box sx={{
                ml: 'auto'
              }} component={'nav'}>
                <Box sx={{
                  ml: 2,
                  'display': 'flex',
                  'alignItems': 'center',
                  '& > a': {
                    display: 'flex',
                    alignItems: "center",
                    textDecoration: 'none',
                  }
                }}>
                  {user?.role === "CLIENT" && <Button sx={{ mr: '20px' }} variant="contained" onClick={handleClickOpen}>Request Service</Button>}
                  <Tooltip title="Settings">
                    <Link to={'/dashboard/settings'}>
                      <Typography
                        variant="subtitle1"
                        sx={[
                          {
                            mr: '15px',
                            color: '#A3AAA3',
                            textDecoration: 'none'
                          },
                          (theme) => ({
                            [theme.breakpoints.down('xs')]: {
                              display: 'none'
                            }
                          }),
                        ]}>
                        Settings
                      </Typography>
                      <Box sx={{
                        width: '20px',
                        height: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '10px',
                        'svg path': {
                          fill: '#A3AAA3'
                        },
                        '&:hover svg path': {
                          fill: theme.palette.secondary.main
                        },
                      }}>
                        <SvgIcon sx={{ fontSize: 20 }}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.00125 8.3084C10.5548 8.3084 12.6422 6.43993 12.6422 4.1542C12.6422 1.86847 10.5548 0 8.00125 0C5.44771 0 3.36035 1.86847 3.36035 4.1542C3.36035 6.43993 5.44775 8.3084 8.00125 8.3084Z" fill="#3D9B3D" />
                            <path d="M15.9658 11.628C15.8442 11.3559 15.6821 11.1019 15.4997 10.8661C14.5674 9.63253 13.1286 8.81621 11.5073 8.61667C11.3047 8.59854 11.0817 8.63479 10.9196 8.74364C10.0684 9.30601 9.05512 9.59625 8.00126 9.59625C6.94739 9.59625 5.93411 9.30601 5.08293 8.74364C4.92078 8.63479 4.69785 8.58038 4.49521 8.61667C2.87392 8.81621 1.41478 9.63253 0.502813 10.8661C0.32042 11.1019 0.158276 11.3741 0.0367065 11.628C-0.0240782 11.7369 -0.00382961 11.8639 0.0569551 11.9727C0.2191 12.2267 0.421741 12.4807 0.604134 12.6983C0.887848 13.043 1.19185 13.3514 1.53639 13.6416C1.8201 13.8956 2.14435 14.1314 2.46864 14.3673C4.06964 15.4376 5.99493 15.9999 7.98101 15.9999C9.96708 15.9999 11.8924 15.4375 13.4934 14.3673C13.8176 14.1496 14.1419 13.8956 14.4256 13.6416C14.7499 13.3514 15.0741 13.043 15.3579 12.6983C15.5605 12.4625 15.743 12.2267 15.9051 11.9727C16.0063 11.8639 16.0266 11.7368 15.9658 11.628Z" fill="#3D9B3D" />
                          </svg>
                        </SvgIcon>
                      </Box>
                    </Link>
                  </Tooltip>
                </Box>
              </Box>
              {user?.role === "CLIENT" && <PostJobDialog
                rebookId={rebookId}
                isOpen={openPostJob}
                handleClose={handleClickClose}
              />}
            </Box>
          </Container>
        </Box>
      </Box>
      <LaoyutContext.Provider value={{
        value: 'test',
        isServiceUpdated: isServiceUpdatedState,
        openPostJob: openPostJob,
        openPostJobRebook: handleClickOpenPostJobRebook
      }}>
        <Box sx={{ display: 'flex', position: 'relative', flexGrow: 1, }}>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              maxWidth: '100%',
              width: '100%',
              display: 'flex',
              minHeight: '100%',
            }}>
            <Container maxWidth={false}>
              {showBreadcrumbs && <Box sx={{
                marginTop: '16px',
                marginBottom: '48px',
              }}>
                <Breadcrumbs
                  links={links}
                />
              </Box>}
              <Outlet />
            </Container>
          </Box>
        </Box>
      </LaoyutContext.Provider>
      <Footer />
    </Box >
  )
};

export const useLayout = () => {
  return useContext(LaoyutContext);
};

export default LayoutDashboard;