import React, { useEffect, useState } from "react";
import {
  Box,
  useTheme,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Button,
} from "@mui/material";
import RadioControl from "../../../elements/RadioControl";
import SelectControl from "../../../elements/SelectControl";
import { ErrorBox } from "../../../elements/ErrorBox";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CustomDatePicker } from "../../../elements/CustomDatePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface IJobState {
  [key: string]: string;
}

type TPostJobStep1Props = {
  jobState: any;
  jobStep: number;
  rebookJob?: any;
  responseError: string | null;
  handleUpdateJobState: (data: IJobState) => void;
  changeStep: (step: number) => void;
};

export const PostJobStep1 = ({
  jobState,
  handleUpdateJobState,
  changeStep,
  jobStep,
  responseError,
  rebookJob,
}: TPostJobStep1Props) => {
  const { palette } = useTheme();

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      repeats: false,
      often: "1",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (rebookJob) {
      setValue("repeats", rebookJob?.repeats);
    }
  }, [rebookJob]);

  const onSubmit = async (data: any): Promise<any> => {
    handleUpdateJobState(data);
    changeStep(jobStep + 1);
  };

  const oftenArr = [
    {
      label: "Every week",
      value: 1,
    },
    {
      label: "Every 2 weeks",
      value: 2,
    },
    {
      label: "Every 3 weeks",
      value: 3,
    },
    {
      label: "Every 4 weeks",
      value: 4,
    },
    {
      label: "Every 5 weeks",
      value: 5,
    },
    {
      label: "Every 6 weeks",
      value: 6,
    },
  ];

  return (
    <>
      <Box sx={{ width: "100%", mb: "32px" }}>
        <Typography
          align="center"
          variant="h2"
          sx={{
            mb: "32px",
          }}
        >
          Frequency of work
        </Typography>
        <Box
          sx={{
            mb: "48px",
          }}
        >
          <Controller
            rules={{ required: true }}
            control={control}
            name="repeats"
            render={({ field }) => (
              <RadioGroup {...field}>
                {["One-time job", "Recurring job"].map((item: any, id: any) => (
                  <FormControlLabel
                    key={id}
                    control={
                      <RadioControl
                        value={item === "One-time job" ? false : true}
                      />
                    }
                    label={item}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </Box>
        {String(watch("repeats")) === "true" ? (
          <>
            <Box
              sx={{
                mb: "48px",
              }}
            >
              <FormLabel id="radio-buttons-often">
                How often do you need this service?
              </FormLabel>
              <Controller
                rules={{ required: true }}
                control={control}
                name="often"
                render={({ field }) => (
                  <RadioGroup sx={{ mt: "7px" }} {...field}>
                    {oftenArr.map(
                      (
                        item: {
                          label: string;
                          value: number;
                        },
                        id: number
                      ) => (
                        <FormControlLabel
                          key={id}
                          control={<RadioControl key={id} value={item.value} />}
                          label={item.label}
                        />
                      )
                    )}
                  </RadioGroup>
                )}
              />
            </Box>

            <Box
              sx={{
                position: "relative",
                mb: "16px",
              }}
            >
              <FormLabel id="radio-buttons-isEndDate">
                When will you no longer need this service?
              </FormLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  control={control}
                  name="expirationDate"
                  defaultValue={dayjs().toDate()}
                  render={({ field: { onChange, value, ref } }) => (
                    <CustomDatePicker
                      fullWidth
                      onChange={onChange}
                      value={dayjs(value)}
                      inputRef={ref}
                      label={"Expiration date"}
                      views={["month", "day"]}
                      minDate={dayjs()}
                      slotProps={{
                        textField: {
                          helperText: errors["expirationDate"]
                            ? errors["expirationDate"].message
                            : "",
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </>
        ) : (
          ""
        )}
        {responseError && (
          <ErrorBox
            sx={{
              mt: "8px",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {responseError}
          </ErrorBox>
        )}
      </Box>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Button fullWidth variant="contained" onClick={handleSubmit(onSubmit)}>
          Next step
        </Button>
      </Box>
    </>
  );
};

const schema = yup.object().shape({
  repeats: yup.boolean().required("Type is required"),
  often: yup.string(),
  expirationDate: yup.date(),
});
