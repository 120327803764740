import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TableRow,
  Button,
  TextField,
  Tooltip,
  IconButton,
  SvgIcon,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { OrderBy, Order } from "../../types/types";
import { visuallyHidden } from "@mui/utils";
import { CustomPagination } from "../../elements/CustomPagination";
import dayjs from "dayjs";
import { CustomTooltip } from "../../elements/CustomTooltip";

interface Data {
  startDate: string;
  name: string;
  priceEstimate: string;
  "assistant.fullName": string;
  "site.address": string;
  status: boolean;
  action?: any;
}

interface Column {
  id:
    | "startDate"
    | "name"
    | "priceEstimate"
    | "assistant.fullName"
    | "site.address"
    | "status"
    | "action";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const getColumns = () => {
  const columns: Column[] = [
    {
      id: "startDate",
      label: "Date",
      minWidth: 70,
    },
    {
      id: "name",
      label: "Name of service",
      minWidth: 180,
    },
    {
      id: "priceEstimate",
      label: "Price Estimate",
      minWidth: 180,
    },
    {
      id: "assistant.fullName",
      label: "Assistant",
      minWidth: 130,
    },
    {
      id: "site.address",
      label: "Service site",
      minWidth: 250,
    },
    {
      id: "status",
      label: "Status",
      minWidth: 70,
    },
    {
      id: "action",
      label: "Action",
      minWidth: 300,
    },
  ];

  return columns;
};

const AscendingIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" fill="white" />
    <path
      d="M6.50004 8.90822H13.4999C13.6354 8.90822 13.7526 8.85428 13.8515 8.74633C13.9504 8.63841 14 8.51061 14 8.3629C14 8.21519 13.9504 8.08745 13.8515 7.97938L10.3515 4.16188C10.2526 4.05408 10.1355 4 10 4C9.8645 4 9.74735 4.05408 9.64838 4.16188L6.14842 7.97938C6.04945 8.08733 6 8.21519 6 8.3629C6 8.51058 6.04945 8.63841 6.14842 8.74633C6.2475 8.85428 6.36464 8.90822 6.50004 8.90822Z"
      fill="#0A1E0B"
    />
    <path
      d="M13.4999 11.0898H6.50004C6.36453 11.0898 6.24739 11.1438 6.14842 11.2516C6.04945 11.3596 6 11.4874 6 11.6351C6 11.7828 6.04945 11.9106 6.14842 12.0185L9.64838 15.836C9.74746 15.9439 9.86461 15.998 10 15.998C10.1354 15.998 10.2526 15.9439 10.3515 15.836L13.8515 12.0185C13.9504 11.9106 14 11.7828 14 11.635C14 11.4874 13.9504 11.3596 13.8515 11.2516C13.7526 11.1437 13.6354 11.0898 13.4999 11.0898Z"
      fill="#A3AAA3"
    />
  </svg>
);

const DescendingIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" fill="white" />
    <path
      d="M6.50004 8.90822H13.4999C13.6354 8.90822 13.7526 8.85428 13.8515 8.74633C13.9504 8.63841 14 8.51061 14 8.3629C14 8.21519 13.9504 8.08745 13.8515 7.97938L10.3515 4.16188C10.2526 4.05408 10.1355 4 10 4C9.8645 4 9.74735 4.05408 9.64838 4.16188L6.14842 7.97938C6.04945 8.08733 6 8.21519 6 8.3629C6 8.51058 6.04945 8.63841 6.14842 8.74633C6.2475 8.85428 6.36464 8.90822 6.50004 8.90822Z"
      fill="#A3AAA3"
    />
    <path
      d="M13.4999 11.0898H6.50004C6.36453 11.0898 6.24739 11.1438 6.14842 11.2516C6.04945 11.3596 6 11.4874 6 11.6351C6 11.7828 6.04945 11.9106 6.14842 12.0185L9.64838 15.836C9.74746 15.9439 9.86461 15.998 10 15.998C10.1354 15.998 10.2526 15.9439 10.3515 15.836L13.8515 12.0185C13.9504 11.9106 14 11.7828 14 11.635C14 11.4874 13.9504 11.3596 13.8515 11.2516C13.7526 11.1437 13.6354 11.0898 13.4999 11.0898Z"
      fill="#0A1E0B"
    />
  </svg>
);

interface ServiceTableProps {
  data: any;
  handleRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof OrderBy
  ) => void;
  order: Order;
  orderBy: string;
  page: number;
  totalPages: number;
  inputPage: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleInputKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  openPostJobRebook: (id: string) => void;
}

const ServiceTable: React.FC<ServiceTableProps> = ({
  data,
  handleRequestSort,
  order,
  orderBy,
  page,
  handleChangePage,
  inputPage,
  totalPages,
  handleInputChange,
  handleInputKeyPress,
  openPostJobRebook,
}) => {
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const columns = getColumns();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setRows(formatData(data));
    }
  }, [data]);

  const dictionaryStatus = {
    completed: (
      <Box>
        <CustomTooltip title="Completed" placement="right" enterTouchDelay={0}>
          <IconButton disableRipple>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="24" fill="none" />
              <path
                d="M10.2886 10.459L10.2886 14.4437C10.2888 14.6275 10.3631 14.8059 10.4994 14.9499L11.4206 15.9211C11.486 15.9889 11.5677 16.0444 11.6604 16.0839C11.753 16.1234 11.8546 16.146 11.9584 16.1504C12.0623 16.1547 12.166 16.1407 12.2629 16.1091C12.3598 16.0775 12.4477 16.0291 12.5208 15.9671L12.5814 15.9163L13.5026 14.945C13.6382 14.8015 13.7134 14.6244 13.7163 14.4412L13.7134 10.4542L10.2886 10.459Z"
                fill="white"
              />
              <path
                d="M12.7581 6.9672L11.2507 6.9672C11.1244 6.96699 10.9992 6.98771 10.8825 7.02817C10.7657 7.06863 10.6596 7.12803 10.5702 7.20297C10.4809 7.2779 10.4101 7.3669 10.3618 7.46485C10.3136 7.5628 10.2889 7.66778 10.2892 7.77375L10.2892 8.94604L13.7197 8.94604L13.7197 7.77375C13.72 7.66778 13.6953 7.5628 13.647 7.46485C13.5988 7.3669 13.528 7.2779 13.4386 7.20297C13.3493 7.12803 13.2432 7.06863 13.1264 7.02817C13.0096 6.98771 12.8845 6.96699 12.7581 6.9672Z"
                fill="white"
              />
              <path d="M6 12L10 16L19 7" stroke="#6FBB4B" strokeWidth="4" />
            </svg>
          </IconButton>
        </CustomTooltip>
      </Box>
    ),
    questionnaire: (
      <Box>
        <CustomTooltip
          title="Questionnaire Incomplete"
          placement="right"
          enterTouchDelay={0}
        >
          <IconButton disableRipple>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="24" fill="none" />
              <path
                d="M7 7.96296V7.96296C7 6.32656 8.32656 5 9.96296 5H14C15.6569 5 17 6.34315 17 8V8.7037L12 12.4074V15"
                stroke="#FF5F5C"
                strokeWidth="3"
              />
              <path d="M12 20L12 17" stroke="#FF5F5C" strokeWidth="3" />
            </svg>
          </IconButton>
        </CustomTooltip>
      </Box>
    ),
    pending: (
      <Box>
        <CustomTooltip title="Pending" placement="right" enterTouchDelay={0}>
          <IconButton disableRipple>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="24" fill="none" />
              <path d="M12 8V12H16" stroke="#F09E44" strokeWidth="3" />
              <circle cx="12" cy="12" r="8" stroke="#F09E44" strokeWidth="3" />
            </svg>
          </IconButton>
        </CustomTooltip>
      </Box>
    ),
    confirmed: (
      <Box>
        <CustomTooltip title="Confirmed" placement="right" enterTouchDelay={0}>
          <IconButton disableRipple>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="24" fill="none" />
              <g clipPath="url(#clip0_692_3035)">
                <path
                  d="M12.0008 12.3084C14.2866 12.3084 16.155 10.4399 16.155 8.1542C16.155 5.86847 14.2866 4 12.0008 4C9.71512 4 7.84668 5.86847 7.84668 8.1542C7.84668 10.4399 9.71515 12.3084 12.0008 12.3084Z"
                  fill="#447EF0"
                />
                <path
                  d="M19.1302 15.628C19.0213 15.3559 18.8762 15.1019 18.713 14.8661C17.8785 13.6325 16.5905 12.8162 15.1393 12.6167C14.9579 12.5985 14.7584 12.6348 14.6132 12.7436C13.8513 13.306 12.9443 13.5962 12.001 13.5962C11.0576 13.5962 10.1506 13.306 9.3887 12.7436C9.24356 12.6348 9.04402 12.5804 8.86263 12.6167C7.41138 12.8162 6.10527 13.6325 5.28895 14.8661C5.12568 15.1019 4.98054 15.3741 4.87172 15.628C4.81731 15.7369 4.83544 15.8639 4.88985 15.9727C5.03499 16.2267 5.21638 16.4807 5.37964 16.6983C5.6336 17.043 5.90572 17.3514 6.21412 17.6416C6.46808 17.8956 6.75832 18.1314 7.0486 18.3673C8.48169 19.4376 10.2051 19.9999 11.9828 19.9999C13.7606 19.9999 15.484 19.4375 16.9171 18.3673C17.2073 18.1496 17.4976 17.8956 17.7516 17.6416C18.0418 17.3514 18.332 17.043 18.586 16.6983C18.7674 16.4625 18.9307 16.2267 19.0758 15.9727C19.1665 15.8639 19.1846 15.7368 19.1302 15.628Z"
                  fill="#447EF0"
                />
              </g>
              <defs>
                <clipPath id="clip0_692_3035">
                  <rect
                    width="16"
                    height="16"
                    fill="none"
                    transform="translate(4 4)"
                  />
                </clipPath>
              </defs>
            </svg>
          </IconButton>
        </CustomTooltip>
      </Box>
    ),
  };

  const formatData = (arr: any) => {
    return arr.map((obj: any) => {
      const { ...rest } = obj;
      const assistantFullName = obj?.assistant
        ? `${obj?.assistant?.firstName} ${obj?.assistant?.lastName}`
        : "";
      const siteAddress = obj.site?.address;
      const priceEstimate = obj.priceEstimate
        ? `$${Number(obj.priceEstimate).toFixed(0)}`
        : "N/A";
      const lowerCaseStatus = obj.status.toLowerCase();

      const status =
        dictionaryStatus[lowerCaseStatus as keyof typeof dictionaryStatus];
      let action = <Box></Box>;
      const startDate = dayjs(obj.startDate).format("MM.DD.YY");

      switch (lowerCaseStatus) {
        case "completed":
          action = (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                sx={{ mr: "16px" }}
                onClick={() => navigate(`/dashboard/questionnaire/${obj.id}`)}
                variant="table"
                disabled
              >
                View Questionnaire
              </Button>
              <Button onClick={() => openPostJobRebook(obj.id)} variant="table">
                Rebook
              </Button>
            </Box>
          );
          break;

        case "questionnaire":
          action = (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={() => navigate(`/dashboard/questionnaire/${obj.id}`)}
                variant="table"
              >
                Take Questionnaire
              </Button>
            </Box>
          );
          break;

        case "pending":
          action = (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={() => navigate(`/dashboard/questionnaire/${obj.id}`)}
                variant="table"
              >
                {obj.questionnaireCompleted
                  ? "View Questionnaire"
                  : "Take Questionnaire"}
              </Button>
            </Box>
          );
          break;

        case "confirmed":
          action = (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={() => navigate(`/dashboard/questionnaire/${obj.id}`)}
                variant="table"
              >
                View Questionnaire
              </Button>
            </Box>
          );
          break;
        default:
          break;
      }

      return {
        ...rest,
        startDate,
        priceEstimate,
        "assistant.fullName": assistantFullName,
        "site.address": siteAddress,
        status,
        action,
      };
    });
  };

  const renderRows = (arrRows: any) => {
    return arrRows.map((row: any, rowId: any) => {
      return (
        <TableRow
          sx={{
            cursor: "pointer",
          }}
          hover
          role="checkbox"
          tabIndex={-1}
          key={row.id + rowId}
        >
          {columns.map((column, id) => {
            const value = row[column.id];
            return (
              <TableCell
                sx={{
                  color: "#333333",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "400",
                  backgroundColor: "#FFFFFF",
                  py: "20px",
                  px: "15px",
                  textTransform: column.id === "status" ? "capitalize" : "none",
                }}
                key={row.id + id + rowId}
                align={column.align}
              >
                {column.format && typeof value === "number"
                  ? column.format(value)
                  : value}
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
  };

  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      if (property === "action") {
        return;
      }
      handleRequestSort(event, property);
    };

  return (
    <Paper
      sx={{
        width: "100%",
        backgroundColor: "transparent",
        boxShadow: "none",
      }}
    >
      <TableContainer>
        <Table
          sx={{
            backgroundColor: "transparent",
          }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column, id) => (
                <TableCell
                  sx={[
                    {
                      color: theme.palette.text.disabled,
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "400",
                      py: "20px",
                      px: "15px",
                      border: "none",
                      minWidth: column.minWidth,
                    },
                  ]}
                  key={column.id}
                  align={column.align}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  {column.label !== "Action" && (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={createSortHandler(column.id)}
                      hideSortIcon={true}
                      IconComponent={
                        order === "asc" ? AscendingIcon : DescendingIcon
                      }
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{renderRows(rows)}</TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={[
          {
            mt: "80px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          },
          (theme) => ({
            [theme.breakpoints.down("xs")]: {
              flexDirection: "column",
            },
          }),
        ]}
      >
        <CustomPagination
          totalPages={totalPages}
          page={page}
          handleChangePage={handleChangePage}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: theme.palette.text.disabled,
              mr: "12px",
            }}
            component={"span"}
          >
            Or move to
          </Typography>
          <TextField
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "4px",
                borderColor: "#DDDFDD",
                lineHeight: "20px",
                maxWidth: "45px",
                color: theme.palette.text.disabled,
              },
              "& .MuiInputBase-input": {
                p: "8px 15px",
                pr: "0px",
                textAlign: "center",
              },
              "& .Mui-focused": {
                color: theme.palette.text.secondary,
              },
            }}
            type={"number"}
            value={inputPage}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyPress}
            InputProps={{ inputProps: { min: 1, max: totalPages } }}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default ServiceTable;
