import { handleApiError } from "./handleApiError";
import instance from './index';

type TGetUser = {
  token?: string,
};

type TUserData = {
  id: string,
  phoneNumber?: string,
  firstName: string,
  lastName: string,
  serviceAreaId: string,
}

type TGetAllUsers = {
  page?: number,
  limit?: number,
  orderBy?: string
};

type TSuspendUser = {
  id: string;
  suspend: boolean;
}

type TPatchPassword = {
  oldPassword: string,
  password: string,
  passwordConfirm: string,
}

export const getUserData = async ({ token }: TGetUser) => {
  try {
    const response = await instance.get(`/user/me`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error: unknown) {
    handleApiError(error);
  }
}

export const updateUserData = async ({
  id,
  phoneNumber,
  firstName,
  lastName,
  serviceAreaId,
}: TUserData) => {
  try {
    const response = await instance.patch(`/user/${id}`, {
      phoneNumber,
      firstName,
      lastName,
      serviceAreaId,
    });
    return response.data;
  } catch (error: unknown) {
    handleApiError(error);
  }
};

export const getServiceAreas = async () => {
  try {
    const response = await instance.get(`/user/service-area`);

    return response.data;
  } catch (error: unknown) {
    handleApiError(error);
  }
}


export const getUsers = async ({
  page = 1,
  limit = 12,
  orderBy = 'startDate-asc',
}: TGetAllUsers) => {
  try {

    const response = await instance.get(`/user`,
      {
        params: {
          orderBy,
          page,
          limit,
        }
      }
    );
    return response.data;
  } catch (error: unknown) {
    handleApiError(error);
  }
};

export const suspendUser = async ({ id, suspend }: TSuspendUser) => {
  try {
    const response = await instance.patch(`/user/suspend/${id}?suspend=${suspend}`);
    return response.data;
  } catch (error: unknown) {
    handleApiError(error);
  }
};

export const passwordPatch = async ({ oldPassword, password, passwordConfirm }: TPatchPassword) => {
  try {
    await instance.patch(`/user/password`, { 
      oldPassword, 
      password, 
      passwordConfirm
    });
  } catch (error: unknown) {
    handleApiError(error);
  }
};