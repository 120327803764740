import { handleApiError } from "./handleApiError";
import instance from './index';

type TGetQuestionnaireById = {
  id: string
};

type TPostQuestionnaire = {
  id: string
  content: any,
};

type TGetQuestionnaire = {
  id: string
};

export const getQuestionnaireById = async ({ id }: TGetQuestionnaireById) => {
  try {
    const response = await instance.get(`/questinnaire/job/${id}`);
    return response.data;
  } catch (error: unknown) {
    handleApiError(error);
  }
}


export const postQuestionnaireAnswers = async ({ id, content }: TPostQuestionnaire) => {
  try {
    const response = await instance.post(`/questinnaire/job/${id}/answers`, {
      content: JSON.stringify(content)
    });

    return response.data;
  } catch (error: unknown) {
    handleApiError(error);
  }
}

export const getQuestionnaireAnswers = async ({ id }: TGetQuestionnaire) => {
  try {
    const response = await instance.get(`/questinnaire/job/${id}/answers`);

    return response.data;
  } catch (error: unknown) {
    handleApiError(error);
  }
}


