import {
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { OrderBy, Order } from '../../types/types';
import { visuallyHidden } from '@mui/utils';
import { CustomPagination } from '../../elements/CustomPagination';
import dayjs from 'dayjs';
import { CustomTooltip } from '../../elements/CustomTooltip';
import FilterDrawer from '../Modals/FilterDrawer';

interface Data {
  startDate: string;
  name: string;
  'assistant.fullName': string;
  'site.address': string;
  status: boolean;
  action?: any;
}

interface Column {
  id: 'startDate' | 'name' | 'assistant.fullName' | 'site.address' | 'status' | 'action';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const getColumns = () => {

  const columns: Column[] = [
    {
      id: 'startDate',
      label: 'Date',
      minWidth: 70,
    },
    {
      id: 'name',
      label: 'Name of service',
      minWidth: 180,
    },
    {
      id: 'assistant.fullName',
      label: 'Assistant',
      minWidth: 130,
    },
    {
      id: 'site.address',
      label: 'Service address',
      minWidth: 250,
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 70,
    },
    {
      id: 'action',
      label: 'Action',
      minWidth: 300,
    },
  ];


  return columns
}

interface ServiceTableProps {
  data: any;
  handleRequestSort: (property: keyof OrderBy, order: string) => void; 
  page: number,
  totalPages: number;
  inputPage: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleInputKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  openPostJobRebook: (id: string) => void;
};


const ServiceMobileTable: React.FC<ServiceTableProps> = (
  {
    data,
    handleRequestSort,
    page,
    handleChangePage,
    inputPage,
    totalPages,
    handleInputChange,
    handleInputKeyPress,
    openPostJobRebook,
  }
) => {
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const columns = getColumns();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const options = [
    { value: 'startDate-asc', label: 'Date Ascending' },
    { value: 'startDate-desc', label: 'Date Descending' },
    { value: 'name-asc', label: 'Service Ascending' },
    { value: 'name-desc', label: 'Service Descending' },
    { value: 'assistant.fullName-asc', label: 'Assistant Ascending' },
    { value: 'assistant.fullName-desc', label: 'Assistant Descending' },
    { value: 'site.address-asc', label: 'Address Ascending' },
    { value: 'site.address-desc', label: 'Address Descending' },
    { value: 'status-asc', label: 'Status Ascending' },
    { value: 'status-desc', label: 'Status Descending' },
  ];

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    if (data) {
      setRows(formatData(data));
    }
  }, [data]);


  const dictionaryStatus = {
    "completed": <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton sx={{ pl: 0, py: 0 }} disableRipple>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" fill="none" />
          <path d="M10.2886 10.459L10.2886 14.4437C10.2888 14.6275 10.3631 14.8059 10.4994 14.9499L11.4206 15.9211C11.486 15.9889 11.5677 16.0444 11.6604 16.0839C11.753 16.1234 11.8546 16.146 11.9584 16.1504C12.0623 16.1547 12.166 16.1407 12.2629 16.1091C12.3598 16.0775 12.4477 16.0291 12.5208 15.9671L12.5814 15.9163L13.5026 14.945C13.6382 14.8015 13.7134 14.6244 13.7163 14.4412L13.7134 10.4542L10.2886 10.459Z" fill="white" />
          <path d="M12.7581 6.9672L11.2507 6.9672C11.1244 6.96699 10.9992 6.98771 10.8825 7.02817C10.7657 7.06863 10.6596 7.12803 10.5702 7.20297C10.4809 7.2779 10.4101 7.3669 10.3618 7.46485C10.3136 7.5628 10.2889 7.66778 10.2892 7.77375L10.2892 8.94604L13.7197 8.94604L13.7197 7.77375C13.72 7.66778 13.6953 7.5628 13.647 7.46485C13.5988 7.3669 13.528 7.2779 13.4386 7.20297C13.3493 7.12803 13.2432 7.06863 13.1264 7.02817C13.0096 6.98771 12.8845 6.96699 12.7581 6.9672Z" fill="white" />
          <path d="M6 12L10 16L19 7" stroke="#6FBB4B" strokeWidth="4" />
        </svg>
      </IconButton>
      <Typography sx={{
        fontSize: '14px',
        lineHeight: '20px',
        color: '#A3AAA3'
      }}>
        Completed
      </Typography>
    </Box>,
    "questionnaire": <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton sx={{ pl: 0, py: 0 }} disableRipple>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" fill="none" />
          <path d="M7 7.96296V7.96296C7 6.32656 8.32656 5 9.96296 5H14C15.6569 5 17 6.34315 17 8V8.7037L12 12.4074V15" stroke="#FF5F5C" strokeWidth="3" />
          <path d="M12 20L12 17" stroke="#FF5F5C" strokeWidth="3" />
        </svg>
      </IconButton>
      <Typography sx={{
        fontSize: '14px',
        lineHeight: '20px',
        color: '#A3AAA3'
      }}>
        Questionnaire Incomplete
      </Typography>
    </Box>,
    "pending": <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton sx={{ pl: 0, py: 0 }} disableRipple>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" fill="none" />
          <path d="M12 8V12H16" stroke="#F09E44" strokeWidth="3" />
          <circle cx="12" cy="12" r="8" stroke="#F09E44" strokeWidth="3" />
        </svg>
      </IconButton>
      <Typography sx={{
        fontSize: '14px',
        lineHeight: '20px',
        color: '#A3AAA3'
      }}>
        Pending
      </Typography>
    </Box>,
    "confirmed": <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton sx={{ pl: 0, py: 0 }} disableRipple>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" fill="none" />
          <g clipPath="url(#clip0_692_3035)">
            <path d="M12.0008 12.3084C14.2866 12.3084 16.155 10.4399 16.155 8.1542C16.155 5.86847 14.2866 4 12.0008 4C9.71512 4 7.84668 5.86847 7.84668 8.1542C7.84668 10.4399 9.71515 12.3084 12.0008 12.3084Z" fill="#447EF0" />
            <path d="M19.1302 15.628C19.0213 15.3559 18.8762 15.1019 18.713 14.8661C17.8785 13.6325 16.5905 12.8162 15.1393 12.6167C14.9579 12.5985 14.7584 12.6348 14.6132 12.7436C13.8513 13.306 12.9443 13.5962 12.001 13.5962C11.0576 13.5962 10.1506 13.306 9.3887 12.7436C9.24356 12.6348 9.04402 12.5804 8.86263 12.6167C7.41138 12.8162 6.10527 13.6325 5.28895 14.8661C5.12568 15.1019 4.98054 15.3741 4.87172 15.628C4.81731 15.7369 4.83544 15.8639 4.88985 15.9727C5.03499 16.2267 5.21638 16.4807 5.37964 16.6983C5.6336 17.043 5.90572 17.3514 6.21412 17.6416C6.46808 17.8956 6.75832 18.1314 7.0486 18.3673C8.48169 19.4376 10.2051 19.9999 11.9828 19.9999C13.7606 19.9999 15.484 19.4375 16.9171 18.3673C17.2073 18.1496 17.4976 17.8956 17.7516 17.6416C18.0418 17.3514 18.332 17.043 18.586 16.6983C18.7674 16.4625 18.9307 16.2267 19.0758 15.9727C19.1665 15.8639 19.1846 15.7368 19.1302 15.628Z" fill="#447EF0" />
          </g>
          <defs>
            <clipPath id="clip0_692_3035">
              <rect width="16" height="16" fill="none" transform="translate(4 4)" />
            </clipPath>
          </defs>
        </svg>
      </IconButton>
      <Typography sx={{
        fontSize: '14px',
        lineHeight: '20px',
        color: '#A3AAA3'
      }}>
        Confirmed
      </Typography>
    </Box>,
  };

  const formatData = (arr: any) => {
    return arr.map((obj: any) => {
      const { ...rest } = obj;
      const assistantFullName = obj?.assistant ? `${obj?.assistant?.firstName} ${obj?.assistant?.lastName}` : '';
      const siteAddress = obj.site?.address;
      const lowerCaseStatus = obj.status.toLowerCase();
      const status = dictionaryStatus[lowerCaseStatus as keyof typeof dictionaryStatus];
      let action = <Box></Box>;
      const startDate = dayjs(obj.startDate).format('MM.DD.YY');

      switch (lowerCaseStatus) {
        case "completed":
          action = <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Button
              sx={{ mr: '16px' }}
              onClick={() => navigate(`/dashboard/questionnaire/${obj.id}`)}
              variant="table"
              disabled
            >
              View Questionnaire
            </Button>
            <Button
              onClick={() => openPostJobRebook(obj.id)}
              variant="table"
            >
              Rebook
            </Button>
          </Box>;
          break;

        case "questionnaire":
          action = <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Button
              onClick={() => navigate(`/dashboard/questionnaire/${obj.id}`)}
              variant="table"
            >
              Take Questionnaire
            </Button>
          </Box>;
          break;

        case "pending":
          action = <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Button
              onClick={() => navigate(`/dashboard/questionnaire/${obj.id}`)}
              variant="table"
            >
              Take Questionnaire
            </Button>
          </Box>;
          break;

        case "confirmed":
          action = <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Button
              onClick={() => navigate(`/dashboard/questionnaire/${obj.id}`)}
              variant="table"
            >
              View Questionnaire
            </Button>
          </Box>;
          break;
        default:
          break;
      }

      return {
        ...rest,
        startDate,
        'assistant.fullName': assistantFullName,
        'site.address': siteAddress,
        status,
        action,
      };
    });
  }

  const renderRows = (arrRows: any) => {
    return arrRows.map((row: any, rowId: any) => {
      return (
        <Box
          sx={{
            py: '32px',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            borderBottom: '1px solid #DDDFDD'
          }}
          tabIndex={-1} key={row.id + rowId}
        >
          {columns.map((column, id) => {
            const value = row[column.id];
            const valueLength = String(value).length;
            return (
              <React.Fragment key={id}>
                {valueLength > 0 && <Box
                  sx={{
                    color: '#333333',
                    fontSize: '16px',
                    fontWeight: '400',
                    backgroundColor: '#FFFFFF',
                    py: '4px',
                    px: '0px',
                    textTransform: column.id === 'status' ? 'capitalize' : 'none',
                  }}
                  key={row.id + id + rowId}
                >
                  {column.format && typeof value === 'number'
                    ? column.format(value)
                    : value}
                </Box>}
              </React.Fragment>
            );
          })}
        </Box>
      );
    })
  }


  return (
    <Box sx={{
      width: '100%',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    }}>
      <Box sx={{ mb: '8px' }}>
        <Button variant="contained" color="primary" onClick={handleDrawerOpen}>
          Sorting
        </Button>
        <FilterDrawer handleRequestSort={handleRequestSort} open={drawerOpen} onClose={handleDrawerClose} options={options} />
      </Box>
      <Box>
        <Box>
          {renderRows(rows)}
        </Box>
      </Box>
      <Box sx={[
        {
          mt: '80px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        },
        (theme) => ({
          [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
          },
        }),
      ]}>
        <CustomPagination
          totalPages={totalPages}
          page={page}
          handleChangePage={handleChangePage}
        />
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <Typography
            sx={{
              color: theme.palette.text.disabled,
              mr: '12px'
            }}
            component={'span'}
          >
            Or move to
          </Typography>
          <TextField
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '4px',
                borderColor: '#DDDFDD',
                lineHeight: '20px',
                maxWidth: '45px',
                color: theme.palette.text.disabled
              },
              '& .MuiInputBase-input': {
                p: '8px 15px',
                pr: '0px',
                textAlign: 'center',
              },
              '& .Mui-focused': {
                color: theme.palette.text.secondary
              }
            }}
            type={'number'}
            value={inputPage}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyPress}
            InputProps={{ inputProps: { min: 1, max: totalPages } }}
          />
        </Box>
      </Box>
    </Box>);
};

export default ServiceMobileTable;