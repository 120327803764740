import React, { FC } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface PrivacyPolicyDialogProps {
  open: boolean;
  onClose: () => void;
}

const PrivacyPolicyDialog: FC<PrivacyPolicyDialogProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h6">Privacy Policy</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">Effective Date: April 18, 2024</Typography>
        <Typography variant="h6">Introduction</Typography>
        <Typography variant="body1">
          This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from Lucky Services (the "company") or use luckyservices.com's web application (the "app") (collectively with the company, the "Services").
        </Typography>
        <Typography variant="h6">Information Collection</Typography>
        <Typography variant="body1">
          We receive information about you through your user account when you register on our app; and when you communicate with our company. When you communicate with the company or use this app, you are consenting to the collection, transfer, manipulation, storage, disclosure and other uses of your information as described in this Privacy Policy.
        </Typography>
        <Typography variant="h6">Account Information</Typography>
        <Typography variant="body1">
          Your account information, such as your name, email address, and password is collected, stored, and processed by our third-party Authentication Processing company (the "Authentication Processor"), and use and storage of that information is governed by the Authentication Processor’s applicable privacy policy.
        </Typography>
        <Typography variant="h6">User Content</Typography>
        <Typography variant="body1">
          We collect information that you include in your profile, such as your name, mailing and street addresses, email address, date of birth, and zip code.
        </Typography>
        <Typography variant="h6">Financial Information</Typography>
        <Typography variant="body1">
          Your financial information, such as your payment method (valid credit card number, type, expiration date or other financial information), is collected and stored by our third-party payment processing company (the "Payment Processor"), and use and storage of that information is governed by the Payment Processor’s applicable privacy policy. As of the Effective Date of this Privacy Policy listed below, Stripe is the Payment Processor used within the Services and its privacy policy is available at <Link to={'https://stripe.com/privacy'}>https://stripe.com/privacy</Link> or such other URL designated by Stripe.
        </Typography>
        <Typography variant="h6">Other Information</Typography>
        <Typography variant="body1">
          We may collect your unique device id number of the mobile device on which you use the app.
        </Typography>
        <Typography variant="h6">IP Address Information</Typography>
        <Typography variant="body1">
          When you use our Services, some information is automatically collected. For example, when you use our Services, how you use the Services, information about the type of device you use, your Open Device Identification Number, date/time stamps for your visit, your unique device identifier, operating system, Internet Protocol (IP) address, and domain name are all collected.
        </Typography>
        <Typography variant="h6">Email Communications</Typography>
        <Typography variant="body1">
          We may receive a confirmation when you open an email from us. We use this confirmation to improve our customer service.
        </Typography>
        <Typography variant="h6">Information Collected Using Cookies</Typography>
        <Typography variant="body1">
          Cookies are pieces of text that may be provided to your computer through your web browser or mobile device when you access a website. The information we gather helps us to better understand how you use the Services in order to improve your user experience and the way the Services operate.
        </Typography>
        <Typography variant="h6">General Uses of Information</Typography>
        <Typography variant="body1">
          We use personal information collected through our Services for purposes described in this Privacy Policy or disclosed to you in connection with our Services. We use your information to operate, maintain, and improve our services.
        </Typography>
        <Typography variant="h6">Information Sharing</Typography>
        <Typography variant="body1">
          As stated above, some of your information is collected, stored, and processed by our Payment Processor and Authentication Processor.
        </Typography>
        <Typography variant="h6">Information Protection</Typography>
        <Typography variant="body1">
          Your Account information will be protected by a password for your privacy and security. You need to prevent unauthorized access to your Account and Personal Information by selecting and protecting your password appropriately and limiting access to your computer, phone, and browser or other device by signing off after you have finished accessing your Account.
        </Typography>
        <Typography variant="h6">California Privacy Rights</Typography>
        <Typography variant="body1">
          Under California Civil Code sections 1798.83-1798.84, California residents are entitled to ask us for a notice identifying the categories of personal customer information which we share with our affiliates and/or third parties for marketing purposes, and providing contact information for such affiliates and/or third parties. If you are a California resident and would like a copy of this notice, please send an email to contact@goluckyservices.com.
        </Typography>
        <Typography variant="h6">Policy Updates</Typography>
        <Typography variant="body1">
          We may amend this Privacy Policy from time to time. Use of information we collect now is subject to the Privacy Policy in effect at the time such information is used. If we make changes in the way we collect or use information, we will notify you by posting an announcement on the Services or sending you an email and will change the Effective Date (below).
        </Typography>
        <Typography variant="h6">Contact Information</Typography>
        <Typography variant="body1">
          If you have any questions or concerns regarding privacy using the Services, please send us a detailed message to <Link
            to={'mailto:contact@goluckyservices.com'}
            style={{ textDecoration: 'none' }}
          >
            contact@goluckyservices.com.</Link> We will make every effort to resolve your concerns.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrivacyPolicyDialog;
