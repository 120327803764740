import { handleApiError } from "./handleApiError";
import instance from './index';

export const addPaymentMethod = async ({ confirmedFutureCharges }: {
  confirmedFutureCharges: boolean
}) => {
  try {
    const response = await instance.post(`/payment/setup-intent`, {
      confirmedFutureCharges
    });
    return response.data;
  } catch (error: unknown) {
    handleApiError(error);
  }
}


export const getPaymentMethod = async () => {
  try {
    const response = await instance.get(`/payment/my/payment-method`);

    return response.data;
  } catch (error: unknown) {
    handleApiError(error);
  }
}

export const deletePaymentMethod = async (paymentMethodId: string) => {
  try {
    const response = await instance.delete(`/payment/payment-method/${paymentMethodId}`);

    return response.data;
  } catch (error: unknown) {
    handleApiError(error);
  }
}


