import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { emailValidation, passwordConfirmRegisterValidation, passwordRegisterValidation } from './validation';
import {
  filledInputClasses,
  Box,
  useTheme,
  Button,
  Typography,
  FormControlLabel,
  SvgIcon,
  ButtonBase
} from '@mui/material';
import { InputText } from '../../elements/InputText';
import SelectControl from '../../elements/SelectControl';
import InputPassword from '../../elements/InputPassword';
import { BasicCheckbox } from '../../elements/BasicCheckbox';
import { useResponseError } from '../../hooks/useResponseError';
import { STORAGE_USER_CONFIRM_EMAIL, STORAGE_USER_CONFIRM_TOKEN } from '../../constants/storage';
import { registerAuth } from '../../services/authService';
import { getServiceAreas } from '../../services/userService';
import { phoneRegex } from '../../utils';
import PrivatPolicyModal from '../Modals/PrivatePoliceModal';
import { ErrorBox } from '../../elements/ErrorBox';

export const SignupForm = () => {
  const { palette, typography } = useTheme();
  const [checked, setChecked] = useState(true);
  const { responseError, setResponseError } = useResponseError();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectOptions, setSelectOptions] = useState();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const getServiceAreasValues = async () => {
      try {
        const res = await getServiceAreas();
        const formatedItems = res.map((item: {
          id: string,
          name: string,
        }) => ({
          value: item.id,
          label: item.name
        }));

        setSelectOptions(formatedItems);
        // setValue('serviceAreaId', formatedItems[0].id);
      } catch (error: unknown) {
        setResponseError((error as Error)?.message || "Something went wrong");
      }
    };

    getServiceAreasValues();

    return () => { };
  }, []);

  const passwordValidationArr = [
    'Password must have at least 8 characters',
    'Password must contain at least one uppercase letter',
    'Password must contain at least one symbol',
    'Password must contain at least one lowercase letter',
    'Password must contain at least one number',
  ];

  const passwordValidationErrorsDic: any = {
    'Password must have at least 8 characters': 'Minimum 8 characters',
    'Password must contain at least one uppercase letter': 'One uppercase ',
    'Password must contain at least one symbol': 'One symbol',
    'Password must contain at least one lowercase letter': 'One lowercase',
    'Password must contain at least one number': 'One number',
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: {
      serviceAreaId: '',
      policy: false
    },
    criteriaMode: 'all',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any): Promise<any> => {
    delete data.policy

    const reqData = {
      ...data,
      passwordConfirm: data.password
    };

    try {
      const res = await registerAuth(reqData);
      localStorage.setItem(STORAGE_USER_CONFIRM_TOKEN, res.accessToken);
      localStorage.setItem(STORAGE_USER_CONFIRM_EMAIL, data.email);
      navigate(`/verify`, {
        replace: true
      });
    } catch (error: unknown) {
      setResponseError((error as Error).message)
    }
  };

  const renderPasswordErrors = (err: any) => {
    return (
      <Box sx={{
        pr: '12px',
      }}>
        {passwordValidationArr.map((item, index) => {
          const errorStepMsg = passwordValidationErrorsDic[item];
          let isValidPoint;
          if (index === 0) {
            isValidPoint = err.types?.min;
          } else {
            isValidPoint = err.types?.matches && err.types.matches.includes(item);
          }

          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: !isValidPoint ? palette.text.secondary : palette.text.disabled,
              }}
              key={index}
            >
              <SvgIcon sx={{
                mr: '8px'
              }}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="16" height="16" fill="white" />
                  <path d="M4 7.25L7.46154 11L13 5" stroke={!isValidPoint ? "#3D8F44" : "#DDDFDD"} strokeWidth="2" />
                </svg>
              </SvgIcon>
              {errorStepMsg.trim()}
            </Box>
          )
        })}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        maxWidth: '315px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography
          align="center"
          variant="h1"
          sx={{
            mb: '32px'
          }}
        >
          Registration
        </Typography>
        <Box>
          <InputText
            inputProps={{ ...register('email') }}
            type='email'
            label='Email address'
            placeholder='Email address'
            error={!!errors['email']}
            helperText={errors['email'] ? errors['email'].message : ''}
            fullWidth
          />
        </Box>
        <Box>
          <InputText
            inputProps={{ ...register('phoneNumber') }}
            type='tel'
            label='Phone number'
            placeholder='Phone number'
            error={!!errors['phoneNumber']}
            helperText={errors['phoneNumber'] ? errors['phoneNumber'].message : ''}
            fullWidth
          />
        </Box>
        <Box>
          <InputText
            inputProps={{ ...register('firstName') }}
            type='text'
            label='First name'
            placeholder='First name'
            error={!!errors['firstName']}
            helperText={errors['firstName'] ? 'This is required' : ''}
            fullWidth
          />
        </Box>
        <Box>
          <InputText
            inputProps={{ ...register('lastName') }}
            type='text'
            label='Last name'
            placeholder='Last name'
            error={!!errors['lastName']}
            helperText={errors['lastName'] ? 'This is required' : ''}
            fullWidth
          />
        </Box>
        {selectOptions && <Box sx={{
          position: 'relative',
        }}>
          <Controller
            control={control}
            name='serviceAreaId'
            render={({ field: { onChange, onBlur, value } }) => (
              <SelectControl
                label='Primary service area'
                items={selectOptions}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />
          {errors['serviceAreaId'] &&
            <ErrorBox
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {errors['serviceAreaId'].message}
            </ErrorBox>
          }
        </Box>}
        <Box>
          <InputPassword
            inputProps={{ ...register('password') }}
            placeholder='Password'
            error={!!errors['password']}
            fullWidth
          />
          {errors['password'] && renderPasswordErrors(errors['password'])}
        </Box>
        <Box sx={{
          mb: '9px',
        }}>
          <FormControlLabel
            sx={{
              ml: '3px',
              color: '#666666',
              '.MuiFormControlLabel-label': {
                fontSize: '12px',
                lineHeight: '16px',
                color: '#A3AAA3',
              }
            }}
            control={
              <Controller
                name="policy"
                control={control}
                rules={{ required: true }}
                render={({ field }) =>
                  <BasicCheckbox
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                }
              />
            }
            label={<>Acceptance of <ButtonBase onClick={() => setOpenModal(true)} sx={{ pb: '2px', borderBottom: '1px solid #A3AAA3' }}>PRIVACY POLICY</ButtonBase></>}
          />
          {errors['policy'] &&
            <ErrorBox
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {errors['policy'].message}
            </ErrorBox>
          }
        </Box>
        <PrivatPolicyModal isOpen={openModal} setOpen={setOpenModal} />
        {responseError && <ErrorBox
          sx={{
            mb: '8px',
            textAlign: 'left',
          }}>
          {responseError || 'Something went wrong'}
        </ErrorBox>
        }
        <Button fullWidth variant="contained" type="submit">Register</Button>
      </Box>
    </Box>
  );
};

const schema = yup.object().shape({
  email: emailValidation,
  password: passwordRegisterValidation,
  phoneNumber: yup.string()
    .min(10, 'Please enter a 10-digit phone number')
    .required('Phone number is required')
    .matches(phoneRegex, 'Phone number is not valid')
    .max(12, 'Phone number should not exceed 12 numbers'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  serviceAreaId: yup.string().required('This is required'),
  policy: yup.boolean().oneOf([true], 'You must accept the privacy policy'),
});
