import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  Link,
  FormControlLabel,
  SvgIcon,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputText } from "../../../elements/InputText";
import SelectControl from "../../../elements/SelectControl";
import { ErrorBox } from "../../../elements/ErrorBox";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CustomTimePicker } from "../../../elements/CustomTimePicker";
import { DatePicker } from "@mui/x-date-pickers";
import { CustomDatePicker } from "../../../elements/CustomDatePicker";
import GooglePlacesAutocomplete from "../../../elements/GooglePlacesAutocomplete";
import { TermsOfServiceDialog } from "../../Modals/TermsOfServiceDialog";
import { PaymentElement } from "@stripe/react-stripe-js";
import { BasicCheckbox } from "../../../elements/BasicCheckbox";

interface IJobState {
  [key: string]: any;
}

type TPostJobStep3Props = {
  jobState: any;
  jobStep: number;
  rebookJob?: any;
  priceEstimate: string;
  paymentMethod: any;
  responseError: string | null;
  handleUpdateJobState: (data: IJobState) => void;
  changeStep: (step: number) => void;
  startDateTime: Date | null;
  setStartDateTime: (date: Date) => void;
  endDateTime: Date | null;
  setEndDateTime: (date: Date) => void;
};

export const PostJobStep3 = ({
  jobState,
  priceEstimate,
  rebookJob,
  paymentMethod,
  handleUpdateJobState,
  changeStep,
  jobStep,
  responseError,
  startDateTime,
  setStartDateTime,
  endDateTime,
  setEndDateTime,
}: TPostJobStep3Props) => {
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    setError,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      saveMethod: false,
      termsService: false,
    },
    resolver: yupResolver(schema),
  });

  const defaultDate = dayjs().toDate();
  const startDate = watch("date");
  const startTime = watch("startTime");
  const endTime = watch("endTime");

  useEffect(() => {
    if (startDate) {
      const dateDayjs = dayjs(startDate);
      let startDateobj = dayjs(startDate)
      .date(dateDayjs.date())
      .month(dateDayjs.month());

      let endDateobj = dayjs(startDate)
      .date(dateDayjs.date())
      .month(dateDayjs.month());

      if (startTime) {
        const timeDayis = dayjs(startTime);
        startDateobj = startDateobj.hour(timeDayis.hour()).minute(timeDayis.minute());
      }

      setStartDateTime(
        startDateobj.toDate()
      );

      if (endTime) {
        const endTimeDayis = dayjs(endTime);
        endDateobj = endDateobj.hour(endTimeDayis.hour()).minute(endTimeDayis.minute());
      }

      setEndDateTime(
        endDateobj.toDate()
      );
    }
  }, [setStartDateTime, setEndDateTime, startDate, startTime, endTime]);


  useEffect(() => {
    if (rebookJob) {
      setValue("name", rebookJob?.name);
      setValue("description", rebookJob?.description);
    }
  }, [rebookJob]);

  const onSubmit = async (data: any): Promise<any> => {
    if (!paymentMethod) {
      if (getValues("saveMethod") === false) {
        setError("saveMethod", { type: "custom", message: "This is required" });
      }

      if (getValues("termsService") === false) {
        setError("termsService", {
          type: "custom",
          message: "This is required",
        });
      }

      if (
        getValues("saveMethod") === false ||
        getValues("termsService") === false
      ) {
        return;
      }
    }

    handleUpdateJobState(data);
  };

  const handleChangeAutocomplete = (data: any) => {
    setValue("address", data?.description);
    setValue("latitude", data?.cordinates?.lat);
    setValue("longitude", data?.cordinates?.lng);
  };

  return (
    <>
      <Box sx={{ width: "100%", mb: "24px" }}>
        <Typography
          align="center"
          variant="h2"
          sx={{
            mb: "32px",
          }}
        >
          Client information
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box sx={{ mb: "4px" }}>
              <InputText
                inputProps={{ ...register("name") }}
                type="text"
                label="Service name"
                placeholder="Service name"
                error={!!errors["name"]}
                helperText={errors["name"] ? errors["name"].message : ""}
                fullWidth
              />
            </Box>
            <Box sx={{ mb: "4px" }}>
              <InputText
                inputProps={{ ...register("description") }}
                type="text"
                multiline
                rows={2}
                label="Description"
                placeholder="Description"
                error={!!errors["description"]}
                helperText={
                  errors["description"] ? errors["description"].message : ""
                }
                fullWidth
              />
            </Box>
            <Box sx={{ mb: "4px" }}>
              <GooglePlacesAutocomplete
                handleChange={handleChangeAutocomplete}
                label="Service address"
                placeholder="Service address"
                rebookSite={rebookJob?.site}
              />
              {errors["address"] && (
                <ErrorBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "left",
                  }}
                >
                  {errors["address"].message}
                </ErrorBox>
              )}
            </Box>
            <Box
              sx={{
                position: "relative",
                mb: "4px",
                width: "100%",
              }}
            >
              <Controller
                control={control}
                name="date"
                defaultValue={defaultDate}
                render={({ field: { onChange, value, ref } }) => (
                  <CustomDatePicker
                    fullWidth
                    onChange={onChange}
                    value={dayjs(value)}
                    inputRef={ref}
                    label={"Date"}
                    views={["month", "day"]}
                    minDate={dayjs()}
                    slotProps={{
                      textField: {
                        helperText: errors.date ? errors.date.message : "",
                      },
                    }}
                  />
                )}
              />
            </Box>
            <Box sx={{ display: "flex" }}>
              <Controller
                name="startTime"
                control={control}
                defaultValue={dayjs().toDate()}
                render={({ field: { onChange, value, ref } }) => (
                  <CustomTimePicker
                    sx={{ mr: "16px" }}
                    label="Start Time"
                    onChange={onChange}
                    onAccept={onChange}
                    value={dayjs(value)}
                    inputRef={ref}
                    orientation={"portrait"}
                    slotProps={{
                      textField: {
                        helperText: errors.startTime
                          ? errors.startTime.message
                          : "",
                      },
                    }}
                  />
                )}
              />
              <Controller
                name="endTime"
                control={control}
                defaultValue={dayjs().add(5, 'minute').toDate()}
                render={({ field: { onChange, value, ref } }) => (
                  <CustomTimePicker
                    label="End Time"
                    onChange={onChange}
                    onAccept={onChange}
                    value={dayjs(value)}
                    inputRef={ref}
                    orientation={"portrait"}
                    slotProps={{
                      textField: {
                        helperText: errors.endTime
                          ? errors.endTime.message
                          : "",
                      },
                    }}
                  />
                )}
              />
            </Box>
          </Box>
        </LocalizationProvider>
        {priceEstimate && (
          <Box
            sx={{
              mt: "20px",
              px: "12px",
              py: "24px",
              borderRadius: "4px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#8BC33C33",
            }}
          >
            <Typography
              align="center"
              variant="body1"
              sx={{
                fontWeight: "500",
                mb: "12px",
              }}
            >
              Service Price Estimate
            </Typography>
            <Typography
              variant="h1"
              component={"span"}
              sx={{
                mb: "12px",
              }}
            >
              ${Number(priceEstimate).toFixed(0)}
            </Typography>
            <Typography align="center" variant="body2">
              This is an estimate - it does not include applicable taxes and
              fees, such as mileage or reimbursements. See
              <Link
                sx={{
                  textTransform: "none",
                  fontSize: "inherit",
                  lineHeight: "inherit",
                }}
                component="button"
                variant="body2"
                onClick={handleOpen}
              >
                Terms of Service.
              </Link>
            </Typography>
          </Box>
        )}

        {!paymentMethod && (
          <Box
            sx={{
              mt: "32px",
            }}
          >
            <Box
              sx={{
                maxWidth: "315px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <SvgIcon sx={{ width: "48px", height: "48px", mb: "16px" }}>
                <svg
                  width="49"
                  height="48"
                  viewBox="0 0 49 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="48"
                    height="48"
                    transform="translate(0.5)"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M33.5 21.375C29.567 21.375 26.375 24.567 26.375 28.5C26.375 32.433 29.567 35.625 33.5 35.625C37.433 35.625 40.625 32.433 40.625 28.5C40.625 24.567 37.433 21.375 33.5 21.375ZM30.5825 30.045L32.0825 31.545C32.3105 31.773 32.6225 31.8915 32.9435 31.8735C33.263 31.854 33.5615 31.701 33.7595 31.449L36.632 27.828C37.0175 27.3405 36.935 26.6325 36.449 26.247C35.963 25.8615 35.2535 25.9425 34.868 26.43L32.7815 29.0625L32.174 28.455C31.7345 28.0155 31.022 28.0155 30.5825 28.455C30.1445 28.893 30.1445 29.607 30.5825 30.045Z"
                    fill="#6FBB4B"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29.9795 20.625H8.375V30C8.375 31.0935 8.81 32.1435 9.5825 32.9175C10.3565 33.69 11.4065 34.125 12.5 34.125H26.963C25.6625 32.6145 24.875 30.648 24.875 28.5C24.875 24.993 26.972 21.9735 29.9795 20.625ZM18.5015 27.375H14C13.379 27.375 12.875 27.879 12.875 28.5C12.875 29.121 13.379 29.625 14 29.625H18.5015C19.1225 29.625 19.6265 29.121 19.6265 28.5C19.6265 27.879 19.1225 27.375 18.5015 27.375ZM8.375 18.375H37.625V16.5C37.625 15.4065 37.19 14.3565 36.4175 13.5825C35.6435 12.81 34.5935 12.375 33.5 12.375C28.3055 12.375 17.6945 12.375 12.5 12.375C11.4065 12.375 10.3565 12.81 9.5825 13.5825C8.81 14.3565 8.375 15.4065 8.375 16.5V18.375Z"
                    fill="#6FBB4B"
                  />
                </svg>
              </SvgIcon>
              <Typography
                align="center"
                variant="h2"
                sx={{
                  mb: "16px",
                  color: palette.text.primary,
                }}
              >
                Please add a payment method
              </Typography>
              <Typography
                align="center"
                variant="body1"
                sx={{
                  mb: "20px",
                }}
              >
                This is required in order to request a service
              </Typography>
            </Box>
            <Box sx={{ mb: "16px" }}>
              <PaymentElement />
              {/* {stripeErrorMessage && <ErrorBox sx={{ mt: '8px' }}>{stripeErrorMessage}</ErrorBox>} */}
            </Box>
            <Box
              sx={{
                mb: "16px",
              }}
            >
              <FormControlLabel
                sx={{
                  ml: "3px",
                  color: "#666666",
                  ".MuiFormControlLabel-label": {
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: palette.text.primary,
                    ".MuiLink-button": {
                      borderBottomColor: palette.text.primary,
                      letterSpacing: "0px",
                    },
                  },
                }}
                control={
                  <Controller
                    name="saveMethod"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <BasicCheckbox
                        sx={{ py: "6px" }}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label={<>Save this method for future payments</>}
              />
              {errors["saveMethod"] && (
                <ErrorBox
                  sx={{
                    ml: "37px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {errors["saveMethod"].message}
                </ErrorBox>
              )}
              <FormControlLabel
                sx={{
                  ml: "3px",
                  color: "#666666",
                  ".MuiFormControlLabel-label": {
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: palette.text.primary,
                    ".MuiLink-button": {
                      borderBottomColor: palette.text.primary,
                      letterSpacing: "0px",
                    },
                  },
                }}
                control={
                  <Controller
                    name="termsService"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <BasicCheckbox
                        sx={{ py: "5px" }}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label={
                  <>
                    Acceptance of{" "}
                    <Link
                      sx={{
                        fontSize: "inherit",
                        lineHeight: "inherit",
                      }}
                      component="button"
                      variant="body2"
                      onClick={handleOpen}
                    >
                      Terms of Service
                    </Link>
                  </>
                }
              />
              {errors["termsService"] && (
                <ErrorBox
                  sx={{
                    ml: "37px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {errors["termsService"].message}
                </ErrorBox>
              )}
            </Box>
          </Box>
        )}
        {responseError && (
          <ErrorBox
            sx={{
              mt: "30px",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {responseError}
          </ErrorBox>
        )}
      </Box>
      <Box
        sx={{
          borderTop: "1px solid #DDDFDD",
          pt: "32px",
          display: "flex",
          width: "100%",
        }}
      >
        <Button
          sx={{ mr: "16px" }}
          variant="table"
          onClick={() => changeStep(jobStep - 1)}
        >
          Back
        </Button>
        <Button fullWidth variant="contained" onClick={handleSubmit(onSubmit)}>
          Request Service
        </Button>
      </Box>
      {!paymentMethod && (
        <Box
          sx={{
            mt: "32px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <SvgIcon sx={{ width: "25px", height: "24px", mb: "8px" }}>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="24"
                height="24"
                transform="translate(0.5)"
                fill="white"
              />
              <path
                d="M20.527 15.8019L14.7453 5.17367C13.8164 3.60982 11.5519 3.60774 10.6218 5.17367L4.84035 15.8019C3.89074 17.3999 5.04043 19.4235 6.90164 19.4235H18.4652C20.3249 19.4235 21.4766 17.4015 20.527 15.8019ZM12.6835 17.5055C12.1549 17.5055 11.7245 17.0751 11.7245 16.5465C11.7245 16.0178 12.1549 15.5874 12.6835 15.5874C13.2122 15.5874 13.6425 16.0178 13.6425 16.5465C13.6425 17.0751 13.2122 17.5055 12.6835 17.5055ZM13.6425 13.6694C13.6425 14.1981 13.2122 14.6284 12.6835 14.6284C12.1549 14.6284 11.7245 14.1981 11.7245 13.6694V8.87439C11.7245 8.34572 12.1549 7.91538 12.6835 7.91538C13.2122 7.91538 13.6425 8.34572 13.6425 8.87439V13.6694Z"
                fill="#F09E44"
              />
            </svg>
          </SvgIcon>
          <Typography
            align="center"
            variant="body1"
            sx={{
              mb: "20px",
            }}
          >
            You will be able to publish your request after adding a payment
            method.
          </Typography>
        </Box>
      )}
      <TermsOfServiceDialog open={open} onClose={handleClose} />
    </>
  );
};

const schema = yup.object().shape({
  name: yup.string().required("Service Name is required"),
  description: yup.string().required("Description is required"),
  address: yup.string().required("Service address is required"),
  date: yup.date().required("Date is required"),
  startTime: yup.date().required("Start Time is required"),
  latitude: yup.number().required("Latitude is required"),
  longitude: yup.number().required("Longitude is required"),
  endTime: yup
    .date()
    .required("End Time is required")
    .test(
      "is-greater",
      "End Time must be later than Start Time",
      function (value) {
        const { startTime } = this.parent;
        return dayjs(value).isAfter(dayjs(startTime));
      }
    ),
  saveMethod: yup.boolean(),
  termsService: yup.boolean(),
});
