import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, SvgIcon, Tooltip, Typography, Link as MuiLink, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import ClientsTable from './ClientsTable';
import { Order, OrderBy } from '../../types/types';
import { mockClients } from '../../data';
import { useResponseError } from '../../hooks/useResponseError';
import { getUsers, suspendUser } from '../../services/userService';
import { SuspendDrawer } from './SuspendDrawer';
import ClientsMobileTable from './ClientsMobileTable';

const ClientsList = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [sort, setSort] = useState('ALL');
  const [page, setPage] = useState(1);
  const [inputPage, setInputPage] = useState(1);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('firstName');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const { responseError, setResponseError } = useResponseError(50000);
  const [clientsList, setClientsList] = useState<any>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>({
    firstName: '',
    lastName: '',
    email: '',
    disabled: ''
  });

  useEffect(() => {
    if (page) {
      sortServices(sort, order, orderBy, page);
    }
  }, [page]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof OrderBy,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    const orderValue = isAsc ? 'desc' : 'asc';
    setOrder(orderValue);
    setOrderBy(property);
    setPage(1);
    sortServices(sort, orderValue, property, 1);
  };

  const handleRequestSortMobile = (
    property: keyof OrderBy,
    order: string
  ) => {
    setOrder(order as Order);
    setOrderBy(property);
    setPage(1);
    sortServices(sort, order, property, 1);
  };

  const sortServices = useCallback(async (sortField: string, orderField: string, orderByField: string, pgNumber: number
  ) => {

    setIsLoading(true);
    let data: {
      limit: number,
      page: number,
      orderBy: string,
    } = {
      page: pgNumber,
      limit: rowsPerPage,
      orderBy: `${orderByField}-${orderField}`,
    }

    try {
      const res = await getUsers(data);
      const formatData = res.data;
      setClientsList(formatData);
      if (res?.totalCount) {
        const totalPages = Math.ceil(res?.totalCount / res?.limit);
        setTotalPages(totalPages);
        setHasMore(totalPages > data.page ? true : false);
      } else {
        setTotalPages(0);
        setHasMore(false);
      }
    } catch (error: unknown) {
      setResponseError((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [setResponseError]);


  const handleNextPage = () => {
    setPage((prevPage) => Math.min(prevPage + 1, totalPages));
    setInputPage((prevPage) => Math.min(prevPage + 1, totalPages));
  }
  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
    setInputPage((prevPage) => Math.max(prevPage - 1, 1));
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setInputPage(newPage);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pageNumber = parseInt(event.target.value);
    setInputPage(pageNumber);
  };

  const handleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const pageNumber = inputPage;
      if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
        setPage(pageNumber);
      }
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(1);
  };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  }

  const handleClickUser = (user: any) => {
    setSelectedUser(user)
    handleDrawerOpen();
  }

  const onSubmitSuspend = async (id: string, suspend: boolean) => {

    try {
      await suspendUser({ id, suspend });
      setIsDrawerOpen(false);
      sortServices(sort, order, orderBy, page);
    } catch (error: unknown) {
      setResponseError((error as Error).message);
    }
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', mb: '48px' }}>
        <Typography variant='h1' component={'h1'}>Clients</Typography>
      </Box>
      {
        isLoading ?
          <Box sx={{
            mt: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '300px'
          }}>
            <CircularProgress />
          </Box>
          : <Box>
            <Box sx={[
              {
                position: 'relative',
                maxWidth: '100%',
                width: '100%',
                minHeight: `${clientsList?.length * 90 + 200}px`,
                marginBottom: '30px',
              },
              (theme) => ({
                [theme.breakpoints.down('md')]: {
                  maxWidth: '100%',
                },
                [theme.breakpoints.down('xs')]: {
                  marginBottom: '60px',
                },
              }),
            ]}>
              {isMobile ?
                <Box>
                  <ClientsMobileTable
                    handleRequestSort={handleRequestSortMobile}
                    data={clientsList}
                    page={page}
                    inputPage={inputPage}
                    totalPages={totalPages}
                    handleChangePage={handleChangePage}
                    handleInputChange={handleInputChange}
                    handleInputKeyPress={handleInputKeyPress}
                    handleClickUser={handleClickUser}
                  />
                </Box>
                : <Box sx={[{
                  'display': 'flex',
                  flexWrap: 'wrap',
                  pb: '15px',
                  pr: '10px',
                  maxWidth: '100%',
                  width: '100%',
                  overflow: 'auto',
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: '-12px',
                  right: '-10px',
                }]}>
                  <ClientsTable
                    order={order}
                    orderBy={orderBy}
                    handleRequestSort={handleRequestSort}
                    data={clientsList}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    inputPage={inputPage}
                    totalPages={totalPages}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handlePrevPage={handlePrevPage}
                    handleInputChange={handleInputChange}
                    handleInputKeyPress={handleInputKeyPress}
                    handleNextPage={handleNextPage}
                    handleClickUser={handleClickUser}
                  />
                </Box>
              }
              <SuspendDrawer
                open={isDrawerOpen}
                onClose={handleDrawerClose}
                onSubmitSuspend={onSubmitSuspend}
                isSuspended={selectedUser?.disabled === 'Suspended'}
                fullName={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
                email={selectedUser?.email}
                id={selectedUser?.id}
              />
            </Box>
          </Box>
      }
    </Box>
  );
};

export default ClientsList;
