import { Button, Typography, Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type TAuthFooter = {
  title: string;
  buttonText: string;
  buttonUrl: string;
};

type TProps = {
  children: React.ReactNode;
  footer?: TAuthFooter;
};

export const AuthTemplate = ({ children, footer }: TProps) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={[
        {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        px: '15px',
      },
      (theme) => ({
        [theme.breakpoints.down('sm')]: {
          px: '30px',
        },
      }),
    ]}
    >
      {children}

      {footer ? (
        <Box sx={{
          borderTop: '1px solid #DDDFDD',
          mt: '32px',
          pt: '32px',
          maxWidth: '315px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <Typography
            sx={{
              mb: '14px',
              fontSize: '12px',
            }}
            variant="body1">
            {footer.title}
          </Typography>
          <Button variant="outlined" onClick={() => navigate(footer.buttonUrl)}>
            {footer.buttonText}
          </Button >
        </Box >
      ) : null}
    </Box>
  );
};
