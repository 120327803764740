import React, { useEffect, useMemo, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { GOOGLE_MAPS_API_KEY } from '../constants/storage';
import { InputText } from './InputText';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const placesService: any = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id: string;
}

export default function GooglePlacesAutocomplete({ handleChange, label, placeholder, rebookSite }: any) {
  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [servicesLoaded, setServicesLoaded] = useState(false);
  const [showRebookLocation, setShowRebookLocation] = useState(true);
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const loaded = useRef(false);

  useEffect(() => {
    if (rebookSite && servicesLoaded) {
      setInputValue(rebookSite?.address)
    }
  }, [servicesLoaded, rebookSite]);

  useEffect(() => {
    if (rebookSite && options.length > 0 && showRebookLocation) {
      const match = options.find(option => option.description.toLowerCase().includes(rebookSite.address.toLowerCase()));
      if (match) {
        handlePlaceSelect(match);
      }
      setShowRebookLocation(false);
    }
  }, [options, rebookSite, inputValue, showRebookLocation]);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback,
          );
        },
        400,
      ),
    [],
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      if ((window as any).google) {
        if (!autocompleteService.current) {
          autocompleteService.current = new (
            window as any
          ).google.maps.places.AutocompleteService();
        }
        
        if (!placesService.current) {
          placesService.current = new (
            window as any
          ).google.maps.places.PlacesService(document.createElement('div'));
        }

        if (autocompleteService.current && placesService.current) {
          setServicesLoaded(true);
          clearInterval(intervalId);
        }
      }
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current || !placesService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handlePlaceSelect = (place: PlaceType | null) => {
    if (!place) return;

    setOptions(place ? [place, ...options] : options);
    setValue(place);
    
    const request = { placeId: place.place_id };

    placesService?.current.getDetails(request, (placeDetails: any, status: any) => {
      if (status === (window as any).google.maps.places.PlacesServiceStatus.OK) {
        const cordinates = {
          lat: placeDetails.geometry.location.lat(),
          lng: placeDetails.geometry.location.lng(),
        };
        setCoordinates(cordinates);
        const data = {...place, cordinates}
        handleChange(data);
      }
    });

  };

  return (
    <>
      <Autocomplete
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-root': {
            py: '0px',
          },
        }}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText="No locations"
        onChange={(event: any, newValue: PlaceType | null) => handlePlaceSelect(newValue)}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <InputText
            {...params}
            label={label}
            placeholder={placeholder}
            fullWidth
          />
        )}
        renderOption={(props: any, option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [match.offset, match.offset + match.length]),
          );

          const { key, ...restProps } = props;

          return (
            <li key={key} {...restProps}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  {parts.map((part, index) => (
                    <Box
                      key={index}
                      component="span"
                      sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                    >
                      {part.text}
                    </Box>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </>
  );
}
