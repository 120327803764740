import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';

interface PrivacyPolicyDialogProps {
  isOpen: boolean;
  setOpen: (status: boolean) => void;
}

const PrivatPolicyModal = ({ isOpen, setOpen }: PrivacyPolicyDialogProps) => {

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} aria-labelledby="privat-policy-title">
        <DialogTitle id="privat-policy-title">Privacy Policy</DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            This Privacy Policy describes how your personal information is collected, used, and shared when
            you visit or make a purchase from Lucky Services (the “company”) or use luckyservices.com’s
            web application (the “app”) (collectively with the company, the “Services”).
            We receive information about you through your user account when you register on our app; and
            when you communicate with our company. When you communicate with the company or use
            this app, you are consenting to the collection, transfer, manipulation, storage, disclosure and
            other uses of your information as described in this Privacy Policy.
          </Typography>
          <Typography
            variant="h3"
          >
            What Does This Privacy Policy Cover?
          </Typography>
          <Typography gutterBottom>
            This Privacy Policy covers the treatment of personally identifiable information (“Personal
            Information”) gathered when you are using the app. This Privacy Policy also covers our
            treatment of any Personal Information that we share with our Payment Processors (defined
            below).
          </Typography>
          <Typography
            variant="h3"
          >
            What Information Do We Collect and How is such Information Used?
          </Typography>
          <Typography gutterBottom>The information we gather enables us to operate business operations and services. In connection
            with certain aspects of the Services, we may request, collect and/or display some of your
            Personal Information. We collect the following types of information from our users.
            Account Information:
            Your account information, such as your name, email address, and password is collected, stored,
            and processed by our third party Authentication Processing company (the “Authentication
            Processor”), and use and storage of that information is governed by the Authentication
            Processor’s applicable privacy policy.
            When you create an Account, you may provide information that could be Personal Information,
            such as your username, password, mailing and street address, and email address. You
            acknowledge that this information may be personal to you, and by creating an account and
            providing Personal Information to us, you allow others, including us, to identify you and
            therefore may not be anonymous. We may use your contact information to send you messages
            about our Services. You may unsubscribe from these messages through your Account settings or
            by clicking on the unsubscribe link included at the bottom of marketing emails, although we,
            regardless, reserve the right to continue sending you non-marketing emails, such as emails about
            your accounts and our business dealings with you.
            User Content:
            We collect information that you include in your profile, such as your name, mailing and street
            addresses, email address, date of birth, and zip code.
            Financial Information:
            Your financial information, such as your payment method (valid credit card number, type,
            expiration date or other financial information), is collected and stored by our third party payment
            processing company (the "Payment Processor"), and use and storage of that information is
            governed by the Payment Processors applicable privacy policy. As of the Effective Date of this
            Privacy Policy listed below, Stripe is the Payment Processor used within the Services and its
            privacy policy is available at https://stripe.com/privacy or such other URL designated by Stripe.
            In order to facilitate your order, we collect and store your credit card type, the last four digits of
            your credit card number, and expiration date, but no other financial information.
            Other Information:
            We may collect your unique device id number of the mobile device on which you use the app.
            If you provide us feedback or contact us via e-mail, we will collect your name and e-mail
            address, as well as any other content included in the e-mail, in order to send you a reply.
            When you participate in one of our surveys, we may collect additional personal information.
            We also collect other types of personal information that you provide to us voluntarily.
            If you participate in a contest, sweepstakes or other promotion, we will collect any data you
            provide in connection with that promotion.
            We may also collect personal information at other points in our Services that state that personal
            information is being collected.
            IP Address Information and Other Information Collected Automatically:
            When you use our Services, some information is automatically collected. For example, when you
            use our Services, how you use the Services, information about the type of device you use, your
            Open Device Identification Number, date/time stamps for your visit, your unique device
            identifier, operating system, Internet Protocol (IP) address, and domain name are all collected.
            This information is generally used to help us deliver the most relevant information to you and
            administer and improve the Services. In addition, in the event our app crashes on your mobile
            device, we will receive information about your mobile device model software version and device
            carrier, which allows us to identify and fix bugs and otherwise improve the performance of our
            app.
            We gather certain information automatically and store it in log files, including IP addresses,
            device type, Internet service provider (ISP), operating system, date/time stamp, and clickstream
            data. We use this information to improve the Services.
            Email Communications:
            We may receive a confirmation when you open an email from us. We use this confirmation to
            improve our customer service.
            Information Collected Using Cookies:
            Cookies are pieces of text that may be provided to your computer through your web browser or
            mobile device when you access a website. The information we gather helps us to better
            understand how you use the Services in order to improve your user experience and the way the
            Services operate. Your browser or mobile device stores cookies in a manner associated with each
            website or application you visit. We use cookies to enable our servers to recognize your web
            browser or mobile device and tell us how and when you use the Services.
            Our cookies do not, by themselves, contain Personal Information, and we do not combine the
            general information collected through cookies with other Personal Information to tell us who you
            are. As noted, however, we do use cookies to identify that your web browser or mobile device
            has accessed aspects of the Services and may associate that information with your Account if
            you have one.
            Most browsers or mobile devices have an option for turning off the cookie feature, which will
            prevent your browser or mobile device from accepting new cookies, as well as (depending on the
            sophistication of your browser or mobile device software) allowing you to decide on acceptance
            of each new cookie in a variety of ways. We strongly recommend that you leave cookies active,
            because they may enable you to take advantage of some features of the Services.
            This Privacy Policy covers our use of cookies only and does not cover the use of cookies by third
            parties, including Payment Processor and Authentication Processor. We do not control when or
            how third parties place cookies on your computer or mobile device.
            General Uses of Information:
            We use personal information collected through our Services for purposes described in this
            Privacy Policy or disclosed to you in connection with our Services. We use your information to
            operate, maintain, and improve our services.
            How, and With Whom, Is My Information Shared?
            As stated above, some of your information is collected, stored, and processed by our Payment
            Processor and Authentication Processor.
            IP Address Information:
            While we collect and store IP address information, that information is not made public. We do at
            times, however, share this information with our partners, service providers and other persons
            with whom we conduct business, and as otherwise specified in this Privacy Policy.
            User Profile Information:
            As stated above, some of your user profile information such as email address, name, date of
            birth, and password is collected, stored, and processed by our Authentication Processor.
            Financial Information:
            As stated above, your financial information is collected and stored by our Payment Processor; we
            collect and store your credit card type, the last four digits of your credit card number, and the
            expiration date only. However, we may from time to time request and receive some of your other
            financial information from such Payment Processor for the purposes of completing transactions
            you have initiated through the Services, enrolling you in discount, rebate, and other programs in
            which you elect to participate, protecting against or identifying possible fraudulent transactions,
            and otherwise as needed to manage our business.
            Information Shared with Our Agents:
            We employ and contract with people and other entities that perform certain tasks on our behalf
            and who are under our control (our "Agents"). We may need to share Personal Information with
            our Agents in order to provide products or services to you. Unless we tell you differently, our
            Agents do not have any right to use Personal Information or other information we share with
            them beyond what is necessary to assist us. You hereby consent to our sharing of Personal
            Information with our Agents.
            Information Disclosed Pursuant to Business Transfers:
            In some cases, we may choose to buy or sell assets. In these types of transactions, user
            information is typically one of the transferred business assets. Moreover, if we, or substantially
            all of our assets, were acquired, or if we go out of business or enter bankruptcy, user information
            would be one of the assets that is transferred or acquired by a third party. You acknowledge that
            such transfers may occur, and that any acquirer of us or our assets may continue to use your
            Personal Information as set forth in this Privacy Policy. In addition, we may share your Personal
            Information with our subsidiaries and affiliates.
            Information Disclosed for Our Protection and the Protection of Others:
            We also reserve the right to access, read, preserve, and disclose any Personal Information as it
            reasonably believes is necessary to (i) satisfy any applicable law, regulation, legal process or
            governmental request, (ii) enforce this Privacy Policy and our Terms of Service, including
            investigation of potential violations hereof, (iii) detect, prevent, or otherwise address fraud,
            security or technical issues, (iv) respond to user support requests, or (v) protect our rights,
            property or safety, our users and the public. This includes exchanging information with other
            companies and organizations for fraud protection and spam/malware prevention.
            Information We Share With Your Consent:
            We may share your Personal Information to fulfill the purpose for which you provide it, for any
            other purpose disclosed by us when you provide the information, with your consent, or to third
            parties designated by you.
          </Typography>
          <Typography
            variant="h3"
          >
            Is Information About Me Secure?
          </Typography>
          <Typography gutterBottom>
            Your Account information will be protected by a password for your privacy and security. You
            need to prevent unauthorized access to your Account and Personal Information by selecting and
            protecting your password appropriately and limiting access to your computer, phone, and
            browser or other device by signing off after you have finished accessing your Account.
            We seek to protect Account information to ensure that it is kept private; however, we cannot
            guarantee the security of any Account information. Unauthorized entry or use, hardware or
            software failure, and other factors, may compromise the security of user information at any time.
            We otherwise store all of our information, including your IP address information, using
            industry-standard techniques. While we use reasonable efforts to protect your personal
            information, we cannot guarantee its absolute security and we do not guarantee or warrant that
            such techniques will prevent unauthorized access to information about you that we store,
            Personal Information or otherwise.
          </Typography>
          <Typography
            variant="h3"
          >
            What Information of Mine Can I Access?
          </Typography>
          <Typography gutterBottom>
            If you are a registered user, you can access and edit information associated with your Account by
            logging into the Services.
            California Privacy Rights:
            Under California Civil Code sections 1798.83-1798.84, California residents are entitled to ask us
            for a notice identifying the categories of personal customer information which we share with our
            affiliates and/or third parties for marketing purposes, and providing contact information for such
            affiliates and/or third parties. If you are a California resident and would like a copy of this notice,
            please send an email to contact@goluckyservices.com.
          </Typography>
          <Typography
            variant="h3"
          >
            How Can I Delete My Account?
          </Typography>
          <Typography gutterBottom>
            Should you ever decide to delete your Account, you may do so by emailing
            office@goluckyservices.com. If you terminate your Account, any association between your
            Account and information we store will no longer be accessible through your Account. We may
            retain an archived copy of your records as required by law or for legitimate business purposes.
          </Typography>
          <Typography
            variant="h3"
          >
            What Choices Do I Have Regarding My Information?
          </Typography>
          <Typography gutterBottom>
            You cannot use our Services without registering for an Account. You can delete your Account.
            Please note that we will need to verify that you have the authority to delete the Account, and
            activity generated prior to deletion will remain stored by us.
          </Typography>
          <Typography
            variant="h3"
          >
            What Happens When There Are Changes to this Privacy Policy?
          </Typography>
          <Typography gutterBottom>
            We may amend this Privacy Policy from time to time. Use of information we collect now is
            subject to the Privacy Policy in effect at the time such information is used. If we make changes in
            the way we collect or use information, we will notify you by posting an announcement on the
            Services or sending you an email and will change the Effective Date (below). A user is bound by
            any changes to the Privacy Policy when he or she uses the Services after such changes have been
            first posted.
          </Typography>
          <Typography
            variant="h3"
          >
            What If I Have Questions or Concerns?
          </Typography>
          <Typography gutterBottom>If you have any questions or concerns regarding privacy using the Services, please send us a
            detailed message to contact@goluckyservices.com. We will make every effort to resolve your
            concerns.
          </Typography>
          Effective Date: Apr 18, 2024
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PrivatPolicyModal;
