import { SvgIcon, IconButton } from "@mui/material"

export const CloseModal = ({ close }: any) => {
  return (
    <IconButton onClick={close} sx={{
      position: 'absolute',
      top: '10px',
      right: '10px',
      fontSize: '40px',
      cursor: 'pointer'
    }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <rect width="40" height="40" fill="white" />
        <path d="M27.0713 12.929L12.9292 27.0711" stroke="#666666" />
        <path d="M27.0713 27.071L12.9292 12.9289" stroke="#666666" />
      </svg>
    </IconButton>
  )
} 