import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAuth } from '../../hooks/useAuth';

export const ResetPassword = () => {

  return (
    <Box>
      <Typography
        variant="h2"
        sx={{
          mb: '16px'
        }}
      >
        Password
      </Typography>
      <Button
        variant="table"
        href={'/dashboard/settings/password'}
      >
        Reset password
      </Button>
    </Box>
  );
}
