import { handleApiError } from "./handleApiError";
import instance from "./index";

type TGetAllJobs = {
  page?: number;
  limit?: number;
  orderBy?: string;
};

type TPostJob = {
  name: string;
  description: string;
  categoryId: string;
  repeats: boolean;
  repeatInterval: string;
  repeatEach: number;
  repeatUntil: string;
  startDate: string;
  endDate: string;
  address: string;
  latitude: number;
  longitude: number;
};

type TGetPriceEstimate = {
  categoryId: string;
  startDate: string;
  endDate: string;
};

export const getAllJobs = async ({
  page = 1,
  limit = 12,
  orderBy = "startDate-asc",
}: TGetAllJobs) => {
  try {
    const response = await instance.get(`/job/my`, {
      params: {
        orderBy,
        page,
        limit,
      },
    });
    return response.data;
  } catch (error: unknown) {
    handleApiError(error);
  }
};

export const addJob = async (data: TPostJob) => {
  try {
    const response = await instance.post(`/job`, data);
    return response.data;
  } catch (error: unknown) {
    handleApiError(error);
  }
};

export const getJob = async ({ id }: { id: string }) => {
  try {
    const response = await instance.get(`/job/${id}`);
    return response.data;
  } catch (error: unknown) {
    handleApiError(error);
  }
};

export const getJobCategories = async () => {
  try {
    const response = await instance.get(`/job/categories`);

    return response.data;
  } catch (error: unknown) {
    handleApiError(error);
  }
};

export const getPriceEstimate = async ({
  categoryId,
  startDate,
  endDate,
}: TGetPriceEstimate) => {
  try {
    const response = await instance.get(`/price-estimate`, {
      params: {
        categoryId,
        startDate,
        endDate,
      },
    });
    return response.data;
  } catch (error: unknown) {
    handleApiError(error);
  }
};
