import {
  Box,
  Button,
  FormControlLabel,
  Typography,
  useTheme,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import { BasicCheckbox } from "../../elements/BasicCheckbox";
import { useState } from "react";
import { InputText } from "../../elements/InputText";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ErrorBox } from "../../elements/ErrorBox";
import { PaymentElement } from "@stripe/react-stripe-js";
import PaymentCard from "./PaymentCard";
import { useLayout } from "../Layouts/LayoutDashboard";
import { TermsOfServiceDialog } from "../Modals/TermsOfServiceDialog";

export const BillingInfo = ({
  control,
  register,
  errors,
  userProfile,
  paymentMethod,
  stripeErrorMessage,
  changeCard,
  setChangeCard,
}: any) => {
  const { palette } = useTheme();
  const { openPostJob } = useLayout();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Typography
        variant="h2"
        sx={{
          mb: "16px",
        }}
      >
        Billing information
      </Typography>
      {!changeCard && paymentMethod && (
        <Box sx={{ mb: "16px" }}>
          <PaymentCard
            cardNumber={`123456781234${paymentMethod.cardLast4}`}
            expires={`${
              paymentMethod.cardExpMonth.length > 1
                ? paymentMethod.cardExpMonth
                : "0" + paymentMethod.cardExpMonth
            }/${paymentMethod.cardExpYear}`}
            brand={paymentMethod.cardBrand}
          />
          <Box>
            <Button
              sx={{ mt: "16px" }}
              variant="table"
              onClick={() => setChangeCard(true)}
            >
              Change payment method
            </Button>
          </Box>
        </Box>
      )}
      {changeCard && !openPostJob && (
        <Box>
          <Box sx={{ mb: "16px" }}>
            <PaymentElement />
            {stripeErrorMessage && (
              <ErrorBox sx={{ mt: "8px" }}>{stripeErrorMessage}</ErrorBox>
            )}
          </Box>
          <Box
            sx={{
              mb: "16px",
            }}
          >
            <FormControlLabel
              sx={{
                ml: "3px",
                color: "#666666",
                ".MuiFormControlLabel-label": {
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: palette.text.primary,
                },
              }}
              control={
                <Controller
                  name="saveMethod"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <BasicCheckbox
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label={<>Save this method for future payments</>}
            />
            {errors["saveMethod"] && (
              <ErrorBox
                sx={{
                  ml: "37px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {errors["saveMethod"].message}
              </ErrorBox>
            )}
            <FormControlLabel
              sx={{
                ml: "3px",
                color: "#666666",
                ".MuiFormControlLabel-label": {
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: palette.text.primary,
                  ".MuiLink-button": {
                    borderBottomColor: palette.text.primary,
                    letterSpacing: "0px",
                  },
                },
              }}
              control={
                <Controller
                  name="termsService"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <BasicCheckbox
                      sx={{ py: "5px" }}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label={
                <>
                  Acceptance of{" "}
                  <Link
                    sx={{
                      fontSize: "inherit",
                      lineHeight: "inherit",
                    }}
                    component="button"
                    variant="body2"
                    onClick={handleOpen}
                  >
                    Terms of Service
                  </Link>
                </>
              }
            />
            {errors["termsService"] && (
              <ErrorBox
                sx={{
                  ml: "37px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {errors["termsService"].message}
              </ErrorBox>
            )}
            <TermsOfServiceDialog open={open} onClose={handleClose} />
          </Box>
          {paymentMethod && (
            <Button variant="outlined" onClick={() => setChangeCard(false)}>
              Cancel
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};
