import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { emailValidation, passwordConfirmRegisterValidation, passwordRegisterValidation } from './validation';
import {
  filledInputClasses,
  Box,
  useTheme,
  Button,
  Typography,
  FormControlLabel,
  Link,
  SvgIcon
} from '@mui/material';
import { InputText } from '../../elements/InputText';
import InputPassword from '../../elements/InputPassword';
import { useResponseError } from '../../hooks/useResponseError';
import { passwordResetAuth, passwordResetVerify } from '../../services/authService';
import { ErrorBox } from '../../elements/ErrorBox';

export const CreatePassword = () => {
  const { palette, typography } = useTheme();
  const [checked, setChecked] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { responseError, setResponseError } = useResponseError();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const userId = searchParams.get("userId") || '';

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    criteriaMode: 'all',
    resolver: yupResolver(schema),
  });


  const onSubmit = async (data: any) => {
    const { password, confirmPassword } = data

    if (token) {
      try {
        const formatData: {
          token: string,
          userId: string,
          password: string,
        } = {
          userId: userId,
          token,
          password: password
        };

        const verifyResetPass = await passwordResetVerify({
          userId: userId,
          token
        });

        const resPassword = await passwordResetAuth(formatData);
        navigate(`/reset-complete`, {
          replace: true
        });
      } catch (error: unknown) {
        setResponseError((error as Error).message)
      }
    } else {
      setResponseError('Invalid reset token')
    }
  }

  const passwordValidationArr = [
    'Password must have at least 8 characters',
    'Password must contain at least one uppercase letter',
    'Password must contain at least one symbol',
    'Password must contain at least one lowercase letter',
    'Password must contain at least one number',
  ];

  const passwordValidationErrorsDic: any = {
    'Password must have at least 8 characters': 'Minimum 8 characters',
    'Password must contain at least one uppercase letter': 'One uppercase ',
    'Password must contain at least one symbol': 'One symbol',
    'Password must contain at least one lowercase letter': 'One lowercase',
    'Password must contain at least one number': 'One number',
  };


  const renderPasswordErrors = (err: any, fieldName: string) => {
    const validationArr = passwordValidationArr;

    return (
      <Box sx={{
        pr: '12px',
      }}>
        {validationArr.map((item, index) => {
          const errorStepMsg = passwordValidationErrorsDic[item];

          let isValidPoint;
          if (index === 0) {
            isValidPoint = err.types?.min;
          } else {
            isValidPoint = err.types?.matches && err.types.matches.includes(item.replace('Password', fieldName));
          }

          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: !isValidPoint ? palette.text.secondary : palette.text.disabled,
              }}
              key={index}
            >
              <SvgIcon sx={{
                mr: '8px'
              }}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="16" height="16" fill="white" />
                  <path d="M4 7.25L7.46154 11L13 5" stroke={!isValidPoint ? "#3D8F44" : "#DDDFDD"} strokeWidth="2" />
                </svg>
              </SvgIcon>
              {errorStepMsg.trim()}
            </Box>
          )
        })}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        maxWidth: '315px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box
        sx={{
          width: '100%'
        }}
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography
          align="center"
          variant="h1"
          sx={{
            mb: '32px'
          }}
        >
          Create new password
        </Typography>
        <Box sx={{
          mb: '16px'
        }}>
          <InputPassword
            label={'New Password'}
            inputProps={{ ...register('password') }}
            placeholder='Password'
            error={!!errors['password']}
            fullWidth
          />
          {errors['password'] && renderPasswordErrors(errors['password'], 'Password')}
        </Box>
        <Box sx={{
          mb: '16px'
        }}>
          <InputPassword
            label={'Repeat New Password'}
            inputProps={{ ...register('confirmPassword') }}
            placeholder='Repeat New Password'
            error={!!errors['confirmPassword']}
            fullWidth
          />
          {errors['confirmPassword'] &&
            <ErrorBox sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            >{errors['confirmPassword'].message}</ErrorBox>}
        </Box>
        {responseError && <ErrorBox sx={{
          textAlign: 'left',
          mb: '8px'
        }}>
          {responseError || 'Something went wrong'}
        </ErrorBox>
        }
        <Button fullWidth variant="contained" type="submit">Create new password</Button>
      </Box>
    </Box>
  );
};

const schema = yup.object().shape({
  password: passwordRegisterValidation,
  confirmPassword: yup.string()
    .required('Please confirm your password')
    .oneOf([yup.ref('password'), ''], 'Passwords do not match')
});
