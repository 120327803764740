import React from 'react';
import { Outlet } from 'react-router-dom';
import { CssBaseline, Box, Button, Typography } from '@mui/material';

const Layout = () => {
  return (
    <div style={{ height: '100%' }}>
      <CssBaseline />
      <main className='root'>
        <Box sx={[
          {
            display: 'flex',
          },
          (theme) => ({
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column'
            }
          }),
        ]}>
          <Box sx={[
            {
              pt: '96px',
              pb: '75px',
              maxWidth: '50%',
              width: '100%',
              borderRight: '1px solid #DDDFDD',
              display: 'flex',
              justifyContent: 'center'
            },
            (theme) => ({
              [theme.breakpoints.down('sm')]: {
                pt: '0px',
                pb: '40px',
                maxWidth: '100%',
                borderTop: '1px solid #DDDFDD',
                borderBottom: '1px solid #DDDFDD',
                borderRight: 'none'
              }
            }),
          ]}>
            <Box sx={[
              {
                maxWidth: '450px',
                width: '100%'
              },
              (theme) => ({
                [theme.breakpoints.down('sm')]: {
                  px: '0px',
                  maxWidth: '100%',
                  borderRight: 'none'
                }
              }),
            ]}>
              <Box sx={[
                {
                  mb: '46px'
                },
                (theme) => ({
                  [theme.breakpoints.down('sm')]: {
                    borderBottom: '1px solid #DDDFDD',
                  }
                }),
              ]}>
                <Box sx={[
                  {
                    px: '20px',
                    display: 'flex',
                    maxWidth: '260px',
                    '& img': {
                      maxWidth: '100%',
                    }
                  },
                  (theme) => ({
                    [theme.breakpoints.down('sm')]: {
                      px: '20px',
                      py: '15px',
                      maxWidth: '120px',
                    }
                  }),
                ]}>
                  <img src="/logo192.png" alt="logo" />
                </Box>
              </Box>
              <Box
                sx={[
                  {
                    px: '20px',
                  },
                ]}>
                <Typography
                  sx={{
                    fontWeight: '800',
                    fontSize: '32px',
                    lineHeight: '40px',
                    color: '#0A1E0B',
                    mb: '16px'
                  }}
                  variant="h1"
                  component="div">
                  SO YOU CAN FOCUS ON THE THINGS <Typography sx={{
                    fontWeight: '800',
                    fontSize: '32px',
                    lineHeight: '40px',
                    color: '#3D8F44'
                  }} variant="h1" component="span">YOU LOVE</Typography>
                </Typography>
                <Button href="/" variant="outlined">home page</Button>
              </Box>
            </Box>
          </Box>
          <Box sx={[
            {
              maxWidth: '50%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              pt: '96px',
              pb: '75px'
            },
            (theme) => ({
              [theme.breakpoints.down('sm')]: {
                pt: '40px',
                pb: '50px',
                maxWidth: '100%',
              },
              [theme.breakpoints.down('xs')]: {
                mr: '0px',
                paddingLeft: '0px',
                paddingRight: '10px',
              },
            }),
          ]}>
            <Outlet />
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default Layout;
