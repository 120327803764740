export const handleApiError = (error: any): void => {
  console.error('Handle Api Error', error)
  const errorMessage = error.response?.data?.message;
  const isDashboard = window.location.pathname.includes('/dashboard');

  if (error.response?.status === 401) {
    if (error.response?.data.type === 'GEN-0003' && isDashboard) {
      window.location.replace("/login");
    }

    if (error.response?.data.type !== 'GEN-0007') {
      if (!isDashboard) {
        throw new Error('Incorrect credentials or account not verified')
      } else {
        window.location.replace("/login");
      }
    }

    if (error.response?.data.type === 'GEN-0007' && isDashboard) {
      localStorage.clear();
      sessionStorage.clear();
      setTimeout(() => {
        window.location.replace("/login");
      }, 2500);
    }
  }

  if (Array.isArray(errorMessage) && errorMessage.length) {
    throw new Error(errorMessage[0]);
  }
  if (errorMessage) {
    throw new Error(errorMessage);
  }
  if (error.message) {
    throw new Error(error.message);
  }
};
