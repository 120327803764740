import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, SvgIcon } from '@mui/material';

const ResetCompleteSection = () => {
  const navigate = useNavigate();

  const handleSubmit = async () => {
    navigate(`/login`, {
      replace: true
    });
  }

  return <>
    <Box
      sx={{
        maxWidth: '315px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <SvgIcon sx={{ width: '24px', height: '34px', mb: '16px' }}>
        <svg width="24" height="34" viewBox="0 0 24 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.4957 7.68596C23.4957 8.00261 23.7819 7.07809 19.6692 19C19.4413 18.3133 19.5096 19.7987 19.5096 6.75937C20.6901 5.36271 23.4957 6.02185 23.4957 7.68596Z" fill="#8BC33C" />
          <path d="M18.5 2.0837V17C14.6697 10.0894 16.3586 13.135 14.5 9.78107V2.0837C14.5 0.934925 15.3974 0 16.5 0C17.6198 0 18.5 0.945617 18.5 2.0837Z" fill="#8BC33C" />
          <path d="M16.5 15L14.8911 13.3978C13.4903 12.0017 11.6481 11.2013 9.45218 11.2013L5.77624 5.0941C5.2091 4.11234 5.5409 2.8496 6.53638 2.27684C7.53765 1.70164 8.80105 2.0559 9.36764 3.03277C14.9755 12.3176 12.1056 7.41691 16.5 15Z" fill="#8BC33C" />
          <path d="M19.5 22.7952C19.5 26.262 18.123 29.6036 15.6632 32.0681C14.4629 33.2683 12.8687 33.9542 11.174 33.9979C10.904 33.9979 12.2133 34 8.22967 34C3.96785 34 0.5 30.5291 0.5 26.262V15.5993C0.5 15.2522 0.782226 14.9704 1.12894 14.9704C1.18331 14.9704 1.22796 14.9673 1.29467 14.9489C1.46741 15.0175 3.02707 14.9937 4.08226 16.5226C4.76377 17.4341 4.71606 18.4959 4.71606 19.1535C4.71606 21.5297 5.74095 23.7893 7.52653 25.3547C9.46023 27.0491 12.4853 25.6596 12.4853 23.102C12.4846 20.2623 12.5936 19.6079 12.0997 18.4857C11.6578 17.4862 11.1017 17.0546 10.3564 16.374C10.1028 16.1706 10.4157 16.2289 5.20562 16.2289C4.51892 15.0067 3.23052 14.0804 1.69547 13.8944C1.75788 13.2968 2.07846 12.7262 2.62675 12.3596C3.53875 11.9142 2.52059 12.0083 9.43069 12.0083C11.1788 12.0083 12.823 12.69 14.0593 13.9277L18.6242 18.4975L18.9695 19.0966C19.2931 20.2865 19.5 21.3816 19.5 22.7952Z" fill="#8BC33C" />
        </svg>
      </SvgIcon>
      <Typography
        align="center"
        variant="h1"
        sx={{
          mb: '16px'
        }}
      >
        Your password has been <br /> successfully changed.
      </Typography>
      <Typography
        align="center"
        variant="body1"
        sx={{
          mb: '16px'
        }}
      >
        Use your new password to log in.
      </Typography>
      <Button variant="contained" onClick={handleSubmit}>Login</Button>
    </Box>
  </>;
};

export default ResetCompleteSection;
