import React from 'react';
import { AuthTemplate } from '../../../components/Auth/AuthTemplate';
import { ResetPassword } from '../../../components/Auth/ResetPassword';
import { useSearchParams } from 'react-router-dom';
import { CreatePassword } from '../../../components/Auth/CreatePassword';

const ResetPasswordRequest = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const footerObj = {
    title: 'Back to login?',
    buttonText: 'login',
    buttonUrl: '/login',
  };

  return (
    <AuthTemplate
      footer={footerObj}
    >
      {token ? <CreatePassword /> : <ResetPassword />}
    </AuthTemplate>
  );
};

export default ResetPasswordRequest;
