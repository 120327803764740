import React from 'react';
import { AuthTemplate } from '../../../components/Auth/AuthTemplate';
import ResetCompleteSection from '../../../components/Auth/ResetCompleteSection';

const ResetComplete = () => {
  return (
    <AuthTemplate>
      <ResetCompleteSection />
    </AuthTemplate>
  );
};

export default ResetComplete;
