import { Box, useTheme } from '@mui/material';

export const ErrorBox = ({children, sx}: any) => {
  const { palette, typography } = useTheme();

  return (
    <Box
      sx={{
        ...typography.subtitle2,
        mx: '14px',
        color: palette.error.main,
        ...sx
      }}
    >
      {children}
    </Box>
  );
}