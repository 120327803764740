import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, SvgIcon, Tooltip, Typography, Link as MuiLink, useTheme, useMediaQuery, CircularProgress } from '@mui/material';
import ServiceTable from './ServiceTable';
import { Order, OrderBy } from '../../types/types';
import { useResponseError } from '../../hooks/useResponseError';
import { getAllJobs } from '../../services/jobService';
import { ContactsDrawer } from './ContactsDrawer';
import { useLayout } from '../Layouts/LayoutDashboard';
import ServiceMobileTable from './ServiceMobileTable';

const ServiceList = () => {
  const { isServiceUpdated, openPostJobRebook } = useLayout();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [sort, setSort] = useState('ALL');
  const [page, setPage] = useState(1);
  const [inputPage, setInputPage] = useState(1);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('startDate');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const { responseError, setResponseError } = useResponseError(50000);
  const [servicesList, setServicesList] = useState<any>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isContactsDrawerOpen, setIsContactsDrawerOpen] = useState(false);

  useEffect(() => {
    if (page && isServiceUpdated) {
      sortServices(sort, order, orderBy, page);
    }
  }, [isServiceUpdated]);

  useEffect(() => {
    if (page) {
      sortServices(sort, order, orderBy, page);
    }
  }, [page]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof OrderBy,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    const orderValue = isAsc ? 'desc' : 'asc';
    setOrder(orderValue);
    setOrderBy(property);
    setPage(1);
    sortServices(sort, orderValue, property, 1);
  };

  const handleRequestSortMobile = (
    property: keyof OrderBy,
    order: string
  ) => {
    setOrder(order as Order);
    setOrderBy(property);
    setPage(1);
    sortServices(sort, order, property, 1);
  };

  const sortServices = useCallback(async (sortField: string, orderField: string, orderByField: string, pgNumber: number
  ) => {

    setIsLoading(true);
    let data: {
      limit: number,
      page: number,
      orderBy: string,
    } = {
      page: pgNumber,
      limit: rowsPerPage,
      orderBy: `${orderByField}-${orderField}`,
    }

    try {
      const res = await getAllJobs(data);
      const formatData = res.data;
      setServicesList(formatData);
      if (res?.totalCount) {
        const totalPages = Math.ceil(res?.totalCount / res?.limit);
        setTotalPages(totalPages);
        setHasMore(totalPages > data.page ? true : false);
      } else {
        setTotalPages(0);
        setHasMore(false);
      }
    } catch (error: unknown) {
      setResponseError((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [setResponseError]);


  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setInputPage(newPage);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pageNumber = parseInt(event.target.value);
    setInputPage(pageNumber);
  };

  const handleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const pageNumber = inputPage;
      if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
        setPage(pageNumber);
      }
    }
  };

  const handleContactsDrawerOpen = () => {
    setIsContactsDrawerOpen(true)
  }

  const handleContactsDrawerClose = () => {
    setIsContactsDrawerOpen(false);
  }

  return (
    <Box>
      <Box sx={[
        { display: 'flex', mb: '48px' },
        (theme) => ({
          [theme.breakpoints.down('xs')]: {
            mb: '32px',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }
        }),
      ]}>
        <Typography
          sx={[
            (theme) => ({
              [theme.breakpoints.down('xs')]: {
                mb: '8px'
              }
            }),
          ]}
          variant='h1'
          component={'h1'}>
          Service History
        </Typography>
        <Box sx={[
          { ml: 'auto', display: 'flex' },
          (theme) => ({
            [theme.breakpoints.down('xs')]: {
              ml: 0
            }
          }),
        ]}>
          {servicesList?.length > 0 && <>
            <ContactsDrawer
              open={isContactsDrawerOpen}
              onClose={handleContactsDrawerClose}
            />
            <Tooltip
              sx={{ textAlign: 'center' }}
              title={<>
                <Typography sx={{ color: 'white' }} align='center'>
                  To modify or cancel a service request, please reach out to Lucky Services via phone at 970-749-0028 or via email at <MuiLink
                    sx={{
                      textTransform: 'none',
                      borderBottomColor: '#fff',
                      fontSize: 'inherit',
                      lineHeight: 'inherit'
                    }}
                    color='#fff'
                    underline="always"
                    href="mailto:office@goluckyservices.com"
                    variant="body2"
                  >
                    office@goluckyservices.com
                  </MuiLink>.
                </Typography>
              </>}
            >
              <Button
                onClick={handleContactsDrawerOpen}
                sx={[
                  {
                    textTransform: 'none',
                    '&:hover svg path:nth-of-type(1)': {
                      fill: theme.palette.secondary.main,
                    },
                  },
                  (theme) => ({
                    [theme.breakpoints.down('xs')]: {
                      pl: 0
                    }
                  }),
                ]}
              >
                <Typography sx={[
                  { mr: '8px' },
                  (theme) => ({
                    [theme.breakpoints.down('xs')]: {
                      color: '#A3AAA3'
                    }
                  }),
                ]}>
                  Need to make a change?
                </Typography>
                <SvgIcon>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="24" height="24" fill="white" />
                    <path d="M22 10.3333V13.6667C22 14.5859 21.2526 15.3333 20.3333 15.3333H19.8796C19.2422 19.1107 15.9564 22 12 22C11.6315 22 11.3333 21.7018 11.3333 21.3333C11.3333 20.9648 11.6315 20.6667 12 20.6667C15.6758 20.6667 18.6667 17.6758 18.6667 14V10C18.6667 6.32422 15.6758 3.33333 12 3.33333C8.32422 3.33333 5.33333 6.32422 5.33333 10V14.6667C5.33333 15.0352 5.03516 15.3333 4.66667 15.3333H3.66667C2.7474 15.3333 2 14.5859 2 13.6667V10.3333C2 9.41406 2.7474 8.66667 3.66667 8.66667H4.12044C4.75781 4.88932 8.04362 2 12 2C15.9564 2 19.2422 4.88932 19.8796 8.66667H20.3333C21.2526 8.66667 22 9.41406 22 10.3333ZM12 4.66667C9.05925 4.66667 6.66667 7.05925 6.66667 10V13.3333C6.66667 16.2741 9.05925 18.6667 12 18.6667C14.9408 18.6667 17.3333 16.2741 17.3333 13.3333V10C17.3333 7.05925 14.9408 4.66667 12 4.66667Z" fill="#A3AAA3" />
                    <path d="M10.2886 10.4595L10.2886 14.4441C10.2888 14.628 10.3631 14.8064 10.4994 14.9504L11.4206 15.9216C11.486 15.9894 11.5677 16.0449 11.6604 16.0844C11.753 16.1239 11.8546 16.1465 11.9584 16.1509C12.0623 16.1552 12.166 16.1411 12.2629 16.1096C12.3598 16.078 12.4477 16.0296 12.5208 15.9676L12.5814 15.9168L13.5026 14.9455C13.6382 14.802 13.7134 14.6248 13.7163 14.4417L13.7134 10.4547L10.2886 10.4595Z" fill="white" />
                    <path d="M12.7581 6.9672L11.2507 6.9672C11.1244 6.96699 10.9992 6.98771 10.8825 7.02817C10.7657 7.06863 10.6596 7.12803 10.5702 7.20297C10.4809 7.2779 10.4101 7.3669 10.3618 7.46485C10.3136 7.5628 10.2889 7.66778 10.2892 7.77375L10.2892 8.94604L13.7197 8.94604L13.7197 7.77375C13.72 7.66778 13.6953 7.5628 13.647 7.46485C13.5988 7.3669 13.528 7.2779 13.4386 7.20297C13.3493 7.12803 13.2432 7.06863 13.1264 7.02817C13.0096 6.98771 12.8845 6.96699 12.7581 6.9672Z" fill="white" />
                  </svg>
                </SvgIcon>
              </Button>
            </Tooltip>
          </>
          }
        </Box>
      </Box>
      {
        isLoading ?
          <Box sx={{
            mt: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '300px'
          }}>
            <CircularProgress />
          </Box>
          : <Box>
            {servicesList?.length > 0 ?
              <Box sx={[
                {
                  position: 'relative',
                  maxWidth: '100%',
                  width: '100%',
                  minHeight: `${servicesList?.length * 150 + 200}px`,
                  marginBottom: '30px',
                },
                (theme) => ({
                  [theme.breakpoints.down('md')]: {
                    minHeight: `${servicesList?.length * 180 + 200}px`,
                    maxWidth: '100%',
                  },
                  [theme.breakpoints.down('sm')]: {
                    marginBottom: 'auto',
                  },
                  [theme.breakpoints.down('xs')]: {
                    marginBottom: '60px',
                  },
                }),
              ]}>
                {isMobile ?
                  <Box>
                    <ServiceMobileTable
                      handleRequestSort={handleRequestSortMobile}
                      data={servicesList}
                      page={page}
                      inputPage={inputPage}
                      totalPages={totalPages}
                      handleChangePage={handleChangePage}
                      handleInputChange={handleInputChange}
                      handleInputKeyPress={handleInputKeyPress}
                      openPostJobRebook={openPostJobRebook}
                    />
                  </Box>
                  : <Box sx={[{
                    'display': 'flex',
                    flexWrap: 'wrap',
                    pb: '15px',
                    pr: '10px',
                    maxWidth: '100%',
                    width: '100%',
                    overflow: 'auto',
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: '-12px',
                    right: '-10px',
                  }]}>
                    <ServiceTable
                      order={order}
                      orderBy={orderBy}
                      handleRequestSort={handleRequestSort}
                      data={servicesList}
                      page={page}
                      inputPage={inputPage}
                      totalPages={totalPages}
                      handleChangePage={handleChangePage}
                      handleInputChange={handleInputChange}
                      handleInputKeyPress={handleInputKeyPress}
                      openPostJobRebook={openPostJobRebook}
                    />
                  </Box>
                }
              </Box>
              : <Box>
                <SvgIcon sx={{
                  m: '9px'
                }}>
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_523_357)">
                      <path d="M29.5577 2.57575C30.1402 1.99331 30.1469 1.05317 29.5781 0.462346C29.5662 0.449972 29.5539 0.43802 29.5417 0.42619C29.5417 0.42619 28.0165 0.0486242 27.4304 0.634681L24.6942 3.37603C24.6514 3.41883 24.5029 3.33136 24.4468 3.32176C24.4272 3.31838 24.4033 3.30528 24.3853 3.29545C24.3762 3.29044 24.3673 3.28458 24.3589 3.27782C22.5157 1.80414 20.3175 0.755948 17.9131 0.282467C17.9131 0.282467 16.8473 0.178463 16.3161 0.13126C15.8731 0.0918435 15.4531 0.126129 14.9997 0.126129C6.711 0.126069 0.639894 6.92025 0.639894 15.2089C0.639894 18.7492 1.64776 21.8083 3.69899 24.374C3.75669 24.4462 3.75162 24.5498 3.68625 24.6152L0.800397 27.32C0.21579 27.9046 0.418908 29.5345 0.418908 29.5345C0.419995 29.5356 0.421081 29.5368 0.422228 29.5379C0.71927 29.8457 1.11398 29.9996 1.50875 29.9996C1.89494 29.9996 2.28114 29.8523 2.57583 29.5576L5.39927 26.7342C5.46464 26.6688 5.56828 26.6638 5.64048 26.7215C8.20611 28.7727 11.4596 29.9997 14.9999 29.9997C23.2885 29.9995 29.9998 23.2881 29.9997 14.9995C29.9997 11.4594 28.7727 8.20603 26.7215 5.64045C26.6638 5.56826 26.6689 5.46462 26.7343 5.39925L29.5577 2.57575ZM3.0178 14.9994C3.01786 8.37885 8.37917 3.01766 14.9998 3.01772C17.6902 3.01772 20.173 3.90492 22.1728 5.40196C22.2605 5.4677 22.2686 5.59657 22.1911 5.67414L5.67422 22.1909C5.59665 22.2685 5.46778 22.2604 5.40205 22.1726C3.90494 20.1728 3.01774 17.6898 3.0178 14.9994ZM26.9816 14.9995C26.9816 21.6202 21.6203 26.9816 14.9996 26.9815C12.3093 26.9815 9.82647 26.0943 7.82667 24.5973C7.73891 24.5315 7.73082 24.4026 7.80838 24.3251L24.3252 7.8083C24.4027 7.73074 24.5316 7.73883 24.5973 7.82659C26.0944 9.82639 26.9816 12.3092 26.9816 14.9995Z" fill="#8BC33C" />
                      <path d="M27.3996 0.466382L24.6006 3.26598C24.5427 3.32332 24.4552 3.33485 24.3857 3.29622C24.5928 3.43324 24.8149 3.59622 25.0334 3.76336C25.4173 4.05672 25.96 4.01809 26.3016 3.67584L29.5421 0.426966C28.9511 -0.145871 27.9857 -0.119735 27.3996 0.466382Z" fill="#8BC33C" />
                      <path d="M17.9398 0.288109C17.5469 0.257324 17.1513 0.241449 16.7505 0.241449C8.46217 0.241449 1.7505 6.95251 1.7505 15.2414C1.7505 18.3137 2.67435 21.1696 4.25909 23.5476C4.73614 24.2634 4.6538 25.2135 4.05175 25.8279L0.419276 29.5348C-0.152958 28.9385 -0.11831 27.9845 0.465996 27.3996L3.26559 24.6006C3.33078 24.5348 3.33561 24.4316 3.27827 24.3592C1.22716 21.7938 0 18.5402 0 15C0 6.71107 6.71167 0 15 0C16.0062 0 16.9885 0.099175 17.9398 0.288109Z" fill="#8BC33C" />
                    </g>
                    <defs>
                      <clipPath id="clip0_523_357">
                        <rect width="30" height="30" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </SvgIcon>
                <Typography>You haven't made any service requests yet.</Typography>
              </Box>
            }
          </Box>
      }
    </Box>
  );
};

export default ServiceList;
