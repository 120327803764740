import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TableRow,
  Button,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { OrderBy, Order } from '../../types/types';
import { visuallyHidden } from '@mui/utils';
import { CustomPagination } from '../../elements/CustomPagination';

interface Data {
  firstName: string;
  lastName: string;
  email: string;
  'client.phoneNumber': string;
  'client.serviceArea.name': string;
  disabled: string;
}

interface Column {
  id: 'firstName' | 'lastName' | 'email' | 'client.phoneNumber' | 'client.serviceArea.name' | 'disabled';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const getColumns = () => {
  const columns: Column[] = [
    {
      id: 'firstName',
      label: 'First Name',
      minWidth: 130,
    },
    {
      id: 'lastName',
      label: 'Last Name',
      minWidth: 130,
    },
    {
      id: 'email',
      label: 'Email Address',
      minWidth: 200,
    },
    {
      id: 'client.phoneNumber',
      label: 'Phone Number',
      minWidth: 170,
    },
    {
      id: 'client.serviceArea.name',
      label: 'Service Area',
      minWidth: 150,
    },
    {
      id: 'disabled',
      label: 'Status',
      minWidth: 100,
    },
  ];

  return columns;
};

interface ClientsTableProps {
  data: any;
  handleRequestSort: any,
  order: Order,
  orderBy: string,
  page: number,
  rowsPerPage: number;
  totalPages: number;
  inputPage: number;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePage: (event: unknown, newPage: number) => void;
  handlePrevPage: () => void;
  handleNextPage: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleInputKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleClickUser: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const AscendingIcon: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" fill="white" />
    <path d="M6.50004 8.90822H13.4999C13.6354 8.90822 13.7526 8.85428 13.8515 8.74633C13.9504 8.63841 14 8.51061 14 8.3629C14 8.21519 13.9504 8.08745 13.8515 7.97938L10.3515 4.16188C10.2526 4.05408 10.1355 4 10 4C9.8645 4 9.74735 4.05408 9.64838 4.16188L6.14842 7.97938C6.04945 8.08733 6 8.21519 6 8.3629C6 8.51058 6.04945 8.63841 6.14842 8.74633C6.2475 8.85428 6.36464 8.90822 6.50004 8.90822Z" fill="#0A1E0B" />
    <path d="M13.4999 11.0898H6.50004C6.36453 11.0898 6.24739 11.1438 6.14842 11.2516C6.04945 11.3596 6 11.4874 6 11.6351C6 11.7828 6.04945 11.9106 6.14842 12.0185L9.64838 15.836C9.74746 15.9439 9.86461 15.998 10 15.998C10.1354 15.998 10.2526 15.9439 10.3515 15.836L13.8515 12.0185C13.9504 11.9106 14 11.7828 14 11.635C14 11.4874 13.9504 11.3596 13.8515 11.2516C13.7526 11.1437 13.6354 11.0898 13.4999 11.0898Z" fill="#A3AAA3" />
  </svg>
);

const DescendingIcon: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" fill="white" />
    <path d="M6.50004 8.90822H13.4999C13.6354 8.90822 13.7526 8.85428 13.8515 8.74633C13.9504 8.63841 14 8.51061 14 8.3629C14 8.21519 13.9504 8.08745 13.8515 7.97938L10.3515 4.16188C10.2526 4.05408 10.1355 4 10 4C9.8645 4 9.74735 4.05408 9.64838 4.16188L6.14842 7.97938C6.04945 8.08733 6 8.21519 6 8.3629C6 8.51058 6.04945 8.63841 6.14842 8.74633C6.2475 8.85428 6.36464 8.90822 6.50004 8.90822Z" fill="#A3AAA3" />
    <path d="M13.4999 11.0898H6.50004C6.36453 11.0898 6.24739 11.1438 6.14842 11.2516C6.04945 11.3596 6 11.4874 6 11.6351C6 11.7828 6.04945 11.9106 6.14842 12.0185L9.64838 15.836C9.74746 15.9439 9.86461 15.998 10 15.998C10.1354 15.998 10.2526 15.9439 10.3515 15.836L13.8515 12.0185C13.9504 11.9106 14 11.7828 14 11.635C14 11.4874 13.9504 11.3596 13.8515 11.2516C13.7526 11.1437 13.6354 11.0898 13.4999 11.0898Z" fill="#0A1E0B" />
  </svg>
);


const ClientsTable: React.FC<ClientsTableProps> = (
  {
    data,
    handleRequestSort,
    order,
    orderBy,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    inputPage,
    totalPages,
    handlePrevPage,
    handleInputChange,
    handleInputKeyPress,
    handleNextPage,
    handleClickUser,
  }
) => {
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const columns = getColumns();

  useEffect(() => {
    if (data) {
      setRows(formatData(data));
    }
  }, [data]);

  const formatData = (arr: any) => {
    return arr.map((obj: any) => {
      const { firstName, lastName, email, client, disabled, id } = obj;
      const phoneNumber = client?.phoneNumber;
      const serviceArea = client?.serviceArea?.name;
      const disabledValue = disabled ? 'Suspended' : 'Active';

      return {
        firstName,
        lastName,
        email,
        'client.phoneNumber': phoneNumber,
        'client.serviceArea.name': serviceArea,
        disabled: disabledValue,
        id
      };
    });
  };

  const renderRows = (arrRows: any) => {
    return arrRows.map((row: any, rowId: any) => {
      return (
        <TableRow
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => {
            handleClickUser(row);
          }}
          hover
          role="checkbox"
          tabIndex={-1}
          key={row.email + rowId}
        >
          {columns.map((column, id) => {
            const value = row[column.id];
            return (
              <TableCell
                sx={{
                  color: '#333333',
                  fontSize: '16px',
                  fontWeight: '400',
                  backgroundColor: '#FFFFFF',
                  py: '20px',
                  px: '15px',
                  textTransform: column.id === 'disabled' ? 'capitalize' : 'none',
                }}
                key={row.email + id + rowId}
                align={column.align}
              >
                {column.format && typeof value === 'number'
                  ? column.format(value)
                  : value}
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
  };

  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  return (
    <Paper sx={{
      width: '100%',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    }}>
      <TableContainer>
        <Table sx={{
          backgroundColor: 'transparent',
        }}>
          <TableHead>
            <TableRow>
              {columns.map((column, id) => (
                <TableCell
                  sx={[
                    {
                      color: theme.palette.text.disabled,
                      fontSize: '16px',
                      fontWeight: '500',
                      py: '20px',
                      px: '15px',
                      textTransform: 'capitalize',
                      border: 'none',
                      minWidth: column.minWidth
                    },
                  ]}
                  key={column.id}
                  align={column.align}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={createSortHandler(column.id)}
                    hideSortIcon={true}
                    IconComponent={order === 'asc' ? AscendingIcon : DescendingIcon}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {renderRows(rows)}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={[
        {
          mt: '80px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        },
        (theme) => ({
          [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
          },
        }),
      ]}>
        <CustomPagination
          totalPages={totalPages}
          page={page}
          handleChangePage={handleChangePage}
        />
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <Typography
            sx={{
              color: theme.palette.text.disabled,
              mr: '12px'
            }}
            component={'span'}
          >
            Or move to
          </Typography>
          <TextField
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '4px',
                borderColor: '#DDDFDD',
                lineHeight: '20px',
                maxWidth: '45px',
                color: theme.palette.text.disabled
              },
              '& .MuiInputBase-input': {
                p: '8px 15px',
                pr: '0px',
                textAlign: 'center',
              },
              '& .Mui-focused': {
                color: theme.palette.text.secondary
              }
            }}
            type={'number'}
            value={inputPage}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyPress}
            InputProps={{ inputProps: { min: 1, max: totalPages } }}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default ClientsTable;
