import {
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { OrderBy, Order } from '../../types/types';
import { visuallyHidden } from '@mui/utils';
import { CustomPagination } from '../../elements/CustomPagination';
import dayjs from 'dayjs';
import FilterDrawer from '../Modals/FilterDrawer';

interface Data {
  firstName: string;
  lastName: string;
  email: string;
  'client.phoneNumber': string;
  'client.serviceArea.name': string;
  disabled: string;
}

interface Column {
  id: 'firstName' | 'lastName' | 'email' | 'client.phoneNumber' | 'client.serviceArea.name' | 'disabled';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const getColumns = () => {
  const columns: Column[] = [
    {
      id: 'firstName',
      label: 'First Name',
      minWidth: 130,
    },
    {
      id: 'lastName',
      label: 'Last Name',
      minWidth: 130,
    },
    {
      id: 'email',
      label: 'Email Address',
      minWidth: 200,
    },
    {
      id: 'client.phoneNumber',
      label: 'Phone Number',
      minWidth: 170,
    },
    {
      id: 'client.serviceArea.name',
      label: 'Service Area',
      minWidth: 150,
    },
    {
      id: 'disabled',
      label: 'Status',
      minWidth: 100,
    },
  ];

  return columns;
};

interface ServiceTableProps {
  data: any;
  handleRequestSort: (property: keyof OrderBy, order: string) => void;
  page: number,
  totalPages: number;
  inputPage: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleInputKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleClickUser: (event: React.ChangeEvent<HTMLInputElement>) => void;
};


const ClientsMobileTable: React.FC<ServiceTableProps> = (
  {
    data,
    handleRequestSort,
    page,
    handleChangePage,
    inputPage,
    totalPages,
    handleInputChange,
    handleInputKeyPress,
    handleClickUser,
  }
) => {
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const columns = getColumns();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const options = [
    { value: 'firstName-asc', label: 'First Name Ascending' },
    { value: 'firstName-desc', label: 'First Name Descending' },
    { value: 'lastName-asc', label: 'Last Name Ascending' },
    { value: 'lastName-desc', label: 'Last Name Descending' },
    { value: 'email-asc', label: 'Email Address Ascending' },
    { value: 'email-desc', label: 'Email Address Descending' },
    { value: 'client.phoneNumber-asc', label: 'Phone Number Ascending' },
    { value: 'client.phoneNumber-desc', label: 'Phone Number Descending' },
    { value: 'client.serviceArea.name-asc', label: 'Service Area Ascending' },
    { value: 'client.serviceArea.name-desc', label: 'Service Area Descending' },
    { value: 'disabled-asc', label: 'Status Ascending' },
    { value: 'disabled-desc', label: 'Status Descending' },
  ];

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    if (data) {
      setRows(formatData(data));
    }
  }, [data]);


  const formatData = (arr: any) => {
    return arr.map((obj: any) => {
      const { firstName, lastName, email, client, disabled, id } = obj;
      const phoneNumber = client?.phoneNumber;
      const serviceArea = client?.serviceArea?.name;
      const disabledValue = disabled ? 'Suspended' : 'Active';

      return {
        firstName,
        lastName,
        email,
        'client.phoneNumber': phoneNumber,
        'client.serviceArea.name': serviceArea,
        disabled: disabledValue,
        id
      };
    });
  };

  const renderRows = (arrRows: any) => {
    return arrRows.map((row: any, rowId: number) => {
      return (
        <Box
          sx={{
            py: '32px',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            borderBottom: '1px solid #DDDFDD'
          }}
          onClick={() => {
            handleClickUser(row);
          }}
          tabIndex={-1} key={row.id + rowId}
        >
          {columns.map((column, id) => {
            const value = row[column.id];
            const valueLength = String(value).length;
            return (
              <React.Fragment key={id}>
                {valueLength > 0 && <Box
                  sx={{
                    color: '#333333',
                    fontSize: '16px',
                    fontWeight: '400',
                    backgroundColor: '#FFFFFF',
                    py: '4px',
                    px: '0px',
                    textTransform: column.id === 'disabled' ? 'capitalize' : 'none',
                  }}
                  key={id}
                >
                  {column.format && typeof value === 'number'
                    ? column.format(value)
                    : value}
                </Box>}
              </React.Fragment>
            );
          })}
        </Box>
      );
    })
  }


  return (
    <Box sx={{
      width: '100%',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    }}>
      <Box sx={{ mb: '8px' }}>
        <Button variant="contained" color="primary" onClick={handleDrawerOpen}>
          Sorting
        </Button>
        <FilterDrawer handleRequestSort={handleRequestSort} open={drawerOpen} onClose={handleDrawerClose} options={options} />
      </Box>
      <Box>
        <Box>
          {renderRows(rows)}
        </Box>
      </Box>
      <Box sx={[
        {
          mt: '80px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        },
        (theme) => ({
          [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
          },
        }),
      ]}>
        <CustomPagination
          totalPages={totalPages}
          page={page}
          handleChangePage={handleChangePage}
        />
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <Typography
            sx={{
              color: theme.palette.text.disabled,
              mr: '12px'
            }}
            component={'span'}
          >
            Or move to
          </Typography>
          <TextField
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '4px',
                borderColor: '#DDDFDD',
                lineHeight: '20px',
                maxWidth: '45px',
                color: theme.palette.text.disabled
              },
              '& .MuiInputBase-input': {
                p: '8px 15px',
                pr: '0px',
                textAlign: 'center',
              },
              '& .Mui-focused': {
                color: theme.palette.text.secondary
              }
            }}
            type={'number'}
            value={inputPage}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyPress}
            InputProps={{ inputProps: { min: 1, max: totalPages } }}
          />
        </Box>
      </Box>
    </Box>);
};

export default ClientsMobileTable;