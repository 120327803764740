import {
  useTheme,
  Box,
  Typography,
  Button,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormControl,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useResponseError } from "../../hooks/useResponseError";
import { useNavigate, useParams } from "react-router-dom";
import {
  getQuestionnaireAnswers,
  getQuestionnaireById,
  postQuestionnaireAnswers,
} from "../../services/questionnaireService";
import { Controller, FieldError, useForm } from "react-hook-form";
import { InputText } from "../../elements/InputText";
import RadioControl from "../../elements/RadioControl";
import { ErrorBox } from "../../elements/ErrorBox";
import { AlertIcon } from "../../elements/AlertIcon";
import dayjs from "dayjs";
import utcPlugin from "dayjs/plugin/utc";
import { getJob } from "../../services/jobService";
dayjs.extend(utcPlugin);

const FillQuestionnaire = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    watch,
    setValue,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    getValues,
  } = useForm();
  const [questionnaireFields, setQuestionnaireFields] = useState<any>();
  const [questionnaireFieldsAnswers, setQuestionnaireFieldsAnswers] =
    useState<any>();
  const [jobState, setJobState] = useState<any>();
  const [disabledFields, setDisabledFields] = useState<Record<string, boolean>>(
    {}
  );
  const [isRebook, setIsRebook] = useState(false);
  const [isSuccessResult, setSuccessResult] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const { responseError, setResponseError } = useResponseError(50000);
  const theme = useTheme();

  const getQuestionnaireObj = async (jobId: string) => {
    try {
      setIsloading(true);

      const res = await getQuestionnaireById({
        id: jobId,
      });

      const questionRes = res;
      setQuestionnaireFields(
        questionRes?.content ? JSON.parse(questionRes?.content) : null
      );
      setIsloading(false);
    } catch (error: unknown) {
      setIsloading(false);
      console.error("error", error);
      setResponseError((error as Error).message);
    }
  };

  const getQuestionnaireAnswersObj = async (jobId: string) => {
    try {
      const res = await getQuestionnaireAnswers({
        id: jobId,
      });

      const questionAnswersRes = res;
      setQuestionnaireFieldsAnswers(
        questionAnswersRes?.content
          ? JSON.parse(questionAnswersRes?.content || '')
          : null
      );
    } catch (error: unknown) {
      console.error("error", error);
    }
  };

  const getJobById = async (jobId: string) => {
    try {
      const res = await getJob({
        id: jobId,
      });

      setJobState(res);
      if (
        !res?.questionnaireCompleted &&
        res?.rebooksJob &&
        res?.category?.id === res?.rebooksJob?.category?.id
      ) {
        getQuestionnaireAnswersObj(res?.rebooksJob?.id);
        setIsRebook(res?.status !== 'CONFIRMED' ? true : false);
      }
    } catch (error: unknown) {
      setResponseError((error as Error)?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    if (id) {
      getQuestionnaireObj(id);
      getQuestionnaireAnswersObj(id);
      getJobById(id);
    }

    return () => { };
  }, [id]);

  useEffect(() => {
    if (questionnaireFieldsAnswers && questionnaireFields) {
      questionnaireFieldsAnswers.forEach((field: any, index: number) => {
        const question = questionnaireFields.find(
          (que: any) => que.question === field.question
        );
        const answers = question?.options
          ? field?.answers?.id
          : field?.answers;
        setValue(question?.id, answers, { shouldTouch: true });
        if (!isRebook) {
          setDisabledFields((prev) => ({ ...prev, [question?.id]: true }));
        }
      });
    }
  }, [questionnaireFieldsAnswers, questionnaireFields, isRebook]);

  const watchAllFields = watch();

  const onSubmit = async (data: any): Promise<any> => {
    const dataForReq = Object.keys(data).reduce((acc: any, item: any) => {
      if (item) {
        const questionField = questionnaireFields.find(
          (que: any) => que.id === item
        );
        if (questionField) {
          const question = questionField.question;
          const answer = questionField?.options
            ? questionField?.options.find((opt: any) => {
              return opt.id === data[item];
            })
            : data[item];

          if (answer) {
            const newItem = {
              question: question,
              answers: answer,
            };
            acc.push(newItem);
          }
        }
      }
      return acc;
    }, []);

    try {
      const res = await postQuestionnaireAnswers({
        id: id || "",
        content: dataForReq,
      });

      window.location.reload();
    } catch (error: unknown) {
      setResponseError((error as Error)?.message || "Something went wrong");
    }
  };

  const renderFormFields = () => {
    if (!questionnaireFields && responseError) {
      return (
        <ErrorBox
          sx={{
            my: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          Can't get questionnaire
        </ErrorBox>
      );
    }

    return (
      <>
        {questionnaireFields && <Box>
          {questionnaireFields.map((field: any, index: number) => {
            const isLastField = index === questionnaireFields.length - 1;
            const showField = field?.visibleWhenOptionIdIsActive
              ? Object.values(watchAllFields).some(
                (item: any) =>
                  item && item.includes(field?.visibleWhenOptionIdIsActive)
              )
              : true;

            if (field?.options) {
              return (
                <FormControl
                  sx={{ mb: isLastField ? "12px" : "24px" }}
                  component="fieldset"
                  key={field.id + index}
                >
                  <FormLabel
                    sx={{ mb: "16px" }}
                    focused={false}
                    color={"secondary"}
                    component="legend"
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: field.question }}
                      style={{ display: "contents" }}
                    />
                  </FormLabel>
                  <Controller
                    name={field.id}
                    control={control}
                    defaultValue={field.options[0].id}
                    rules={{ required: "This field is required" }}
                    render={({ field: controllerField }) => (
                      <RadioGroup {...controllerField}>
                        {field.options.map((option: any) => (
                          <FormControlLabel
                            key={option.id}
                            value={option.id}
                            control={<RadioControl />}
                            label={option.text}
                            disabled={disabledFields[field.id]}
                          />
                        ))}
                      </RadioGroup>
                    )}
                  />
                  {errors[field.id] && (
                    <ErrorBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      {errors[field.id]?.message}
                    </ErrorBox>
                  )}
                </FormControl>
              );
            } else if (field?.isLabel) {
              return (
                <FormControl
                  sx={{ mb: "12px" }}
                  component="fieldset"
                  key={field.id + index}
                >
                  <FormLabel
                    sx={{ mb: "8px" }}
                    focused={false}
                    color={"secondary"}
                    component="legend"
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: field.question }}
                      style={{ display: "contents" }}
                    />
                  </FormLabel>
                </FormControl>
              );
            } else {
              return (
                <FormControl
                  sx={{
                    display: showField ? "inline-flex" : "none",
                    mb: isLastField ? "12px" : "24px",
                    width: "100%",
                  }}
                  component="fieldset"
                  key={field.id + index}
                >
                  <FormLabel
                    sx={{ mb: "16px" }}
                    color={"secondary"}
                    component="legend"
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: field.question }}
                      style={{ display: "contents" }}
                    />
                  </FormLabel>
                  <Controller
                    key={field.id}
                    name={field.id}
                    control={control}
                    rules={
                      field?.visibleWhenOptionIdIsActive
                        ? { required: false }
                        : {
                          required:
                            "This field is required. If not applicable, please type N/A.",
                        }
                    }
                    render={({ field: controllerField }) => (
                      <InputText
                        disabled={disabledFields[field.id]}
                        inputProps={{ ...controllerField }}
                        error={!!errors[field.name]}
                        placeholder="Description"
                        multiline
                        rows={2}
                        helperText={
                          errors[field.id]
                            ? (errors[field.id] as FieldError).message
                            : ""
                        }
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              );
            }
          })}
          {responseError && (
            <ErrorBox
              sx={{
                my: "8px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {responseError}
            </ErrorBox>
          )}
          {!questionnaireFieldsAnswers || (questionnaireFieldsAnswers && isRebook) ? (
            <Box>
              <Button
                fullWidth
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
              <Box
                sx={{
                  mt: "32px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <AlertIcon sx={{ mb: "8px", width: "17px", height: "17px" }} />
                <Typography>
                  This data cannot be changed, so make sure everything is entered
                  correctly.
                </Typography>
              </Box>
            </Box>
          ) : ''}
        </Box>}
      </>
    );
  };

  return (
    <Box
      sx={[
        { display: "flex", height: "100%" },
        (theme) => ({
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
          },
        }),
      ]}
    >
      <Box
        sx={[
          {
            position: "relative",
            minHeight: "62px",
          },
        ]}
      >
        <Box
          sx={[
            { pt: "32px", mr: "12px" },
            (theme) => ({
              [theme.breakpoints.down("sm")]: {
                mr: 0,
                position: "absolute",
                left: "-20px",
                right: "-20px",
                py: "14px",
                px: "20px",
                borderBottom: "1px solid #DDDFDD",
              },
            }),
          ]}
        >
          <Button onClick={() => navigate(`/dashboard`)} variant="table">
            To Dashboard
          </Button>
        </Box>
      </Box>
      <Box
        sx={[
          {
            py: "96px",
            maxWidth: "570px",
            height: "100%",
            borderLeft: "1px solid #DDDFDD",
            borderRight: "1px solid #DDDFDD",
            width: "100%",
            margin: "auto",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            overflow: "auto",
          },
          (theme) => ({
            [theme.breakpoints.down("sm")]: {
              pt: "24px",
              pb: "50px",
              px: "10px",
              borderLeft: "0px",
              borderRight: "0px",
            },
          }),
        ]}
      >
        <Typography
          variant="h1"
          component={"h1"}
          sx={{
            mb: "32px",
          }}
        >
          Service details
        </Typography>
        <Box
          sx={{
            boxSizing: "border-box",
            maxWidth: "315px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            {isLoading ? (
              <Box
                sx={{
                  mt: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "300px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box>{renderFormFields()}</Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FillQuestionnaire;
