import {
  useTheme,
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useResponseError } from "../../hooks/useResponseError";
import { PostJobStep2 } from "./steps/PostJobStep2";
import { PostJobStep3 } from "./steps/PostJobStep3";
import { PostJobStep1 } from "./steps/PostJobStep1";
import {
  addJob,
  getJob,
  getJobCategories,
  getPriceEstimate,
} from "../../services/jobService";
import { CancelDrawer } from "./CancelDrawer";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import dayjs from "dayjs";
import utcPlugin from "dayjs/plugin/utc";
import { STORAGE_PAYMENT_METHOD } from "../../constants/storage";
import {
  addPaymentMethod,
  getPaymentMethod,
} from "../../services/paymentService";
import { PostJobStepSuccess } from "./steps/PostJobStepSuccess";
import { useNavigate } from "react-router-dom";
import { useLayout } from "../Layouts/LayoutDashboard";

dayjs.extend(utcPlugin);

interface IJobState {
  [key: string]: any;
}

type TPostJobDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  rebookId?: string;
};

const PostJobDialog = ({
  isOpen,
  handleClose,
  rebookId,
}: TPostJobDialogProps) => {
  const [jobStep, setJobStep] = useState(1);
  const [jobState, setJobState] = useState<IJobState>({});
  const [rebookJob, setRebookJob] = useState<any>();
  const [startDateTime, setStartDateTime] = useState<Date | null>(dayjs().toDate());
  const [endDateTime, setEndDateTime] = useState<Date | null>(dayjs().toDate());
  const [jobCategories, setJobCategories] = useState<any>();
  const [categoryState, setCategoryState] = useState<string>("");
  const [priceEstimateState, setPriceEstimateState] = useState<string>("");
  const [paymentMethod, setPaymentMethod] = useState();
  const [paymentMethodAdded, setPaymentMethodAdded] = useState<boolean>(false);
  const { responseError, setResponseError } = useResponseError(15000);
  const [isCancelDrawerOpen, setIsCancelDrawerOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const stripe = useStripe();
  const elements: any = useElements();

  useEffect(() => {
    if (isOpen) {
      setJobStep(1);
    }
  }, [isOpen]);

  useEffect(() => {
    const getJobCategoriesValues = async () => {
      try {
        const res = await getJobCategories();

        setJobCategories(res);
        // setValue('serviceAreaId', formatedItems[0].id);
      } catch (error: unknown) {
        setResponseError((error as Error)?.message || "Something went wrong");
      }
    };

    getJobCategoriesValues();

    return () => {};
  }, []);

  useEffect(() => {
    const getJobById = async (rebookId: string) => {
      try {
        const res = await getJob({
          id: rebookId,
        });

        setRebookJob(res);
      } catch (error: unknown) {
        setResponseError((error as Error)?.message || "Something went wrong");
      }
    };

    if (rebookId) {
      getJobById(rebookId);
    } else {
      setRebookJob(null);
    }

    return () => {};
  }, [rebookId]);

  useEffect(() => {
    if (jobStep === 3) {
      const getPriceEstimateCategory = async (categoryState: string) => {
        try {
          const res = await getPriceEstimate({
            categoryId: categoryState,
            startDate: dayjs(startDateTime).toISOString(),
            endDate: dayjs(endDateTime).toISOString(),
          });

          setPriceEstimateState(res?.priceEstimate);
        } catch (error: unknown) {
          setResponseError((error as Error)?.message || "Something went wrong");
        }
      };

      if (categoryState) {
        getPriceEstimateCategory(categoryState);
      }

      return () => {};
    }
  }, [
    categoryState,
    endDateTime,
    startDateTime,
    jobState,
    jobStep,
    setResponseError,
  ]);


  useEffect(() => {
    const getUserObj = async () => {
      try {
        const resPaymentMethod = await getPaymentMethod();
        if (resPaymentMethod) {
          setPaymentMethod(resPaymentMethod);
          localStorage.setItem(
            STORAGE_PAYMENT_METHOD,
            JSON.stringify(resPaymentMethod)
          );
        }
      } catch (error: unknown) {
        console.error("error", error);
      }
    };

    getUserObj();

    return () => {};
  }, [paymentMethodAdded]);

  const handleError = (error: unknown) => {
    setLoading(false);
    setResponseError((error as Error).message);
  };

  const clearErrors = () => {
    setResponseError("");
  };

  const handleUpdateJobState = async (data: IJobState) => {
    const newState: IJobState = { ...jobState, ...data };
    setJobState(newState);

    if (jobStep === 2) {
      const isSubCategory = jobCategories.find((item: any) =>
        item.children.some((ch: any) => ch.id === newState?.subCategory)
      );
      const categoryId = isSubCategory
        ? newState.subCategory
        : newState.mainCategory;
      setCategoryState(categoryId);
    }

    if (jobStep === 3) {
      clearErrors();

      if (!paymentMethod) {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        // event.preventDefault();

        if (!stripe) {
          // Stripe.js hasn't yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }

        setLoading(true);

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
          setResponseError(submitError);
          return;
        }

        try {
          // Create the SetupIntent and obtain clientSecret
          const res = await addPaymentMethod({
            confirmedFutureCharges: data?.saveMethod,
          });

          const clientSecret = res?.clientSecret;

          // Confirm the SetupIntent using the details collected by the Payment Element
          const { error } = await stripe.confirmSetup({
            elements,
            clientSecret,
            redirect: "if_required",
            confirmParams: {
              return_url: window.location.href,
            },
          });

          if (error) {
            // This point is only reached if there's an immediate error when
            // confirming the setup. Show the error to your customer (for example, payment details incomplete)
            handleError(error);
            return;
          } else {
            // Your customer is redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer is redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.

            clearErrors();
          }
          setPaymentMethodAdded(true);
        } catch (error: unknown) {
          handleError(error);
        }
      }

      try {
        const startTime = dayjs(newState?.startTime);
        const endTime = dayjs(newState?.endTime);
        const startDate = dayjs(newState?.date)
          .set("hour", startTime.get("hour"))
          .set("minute", startTime.get("minute"))
          .set("second", startTime.get("second"))
          .utc();
        const endDate = dayjs(newState?.date)
          .set("hour", endTime.get("hour"))
          .set("minute", endTime.get("minute"))
          .set("second", endTime.get("second"))
          .utc();

        let data: any = {
          repeats: newState?.repeats,
          categoryId: categoryState,
          repeatInterval: "WEEK",
          repeatUntil: dayjs(newState?.date).utcOffset(0).startOf('date').toISOString(),
          repeatEach: 1,
          endDate: endDate,
          startDate: startDate,
          address: newState?.address,
          description: newState?.description,
          name: newState?.name,
          latitude: newState?.latitude,
          longitude: newState?.longitude,
        };
        if (newState?.repeats) {
          data = {
            ...data,
            repeatInterval: "WEEK",
            repeatUntil: dayjs(newState?.expirationDate).utcOffset(0).startOf('date').toISOString(),
            repeatEach: Number(newState?.often),
          };
        }

        if (rebookId) {
          data = {
            ...data,
            rebooksJobId: rebookJob?.id,
          };
        }

        const res = await addJob(data);
        setJobStep(4);
        setEndDateTime(dayjs().toDate());
        setJobState({});
      } catch (error: unknown) {
        setResponseError((error as Error).message);
      }
    }
  };

  const renderStep = () => {
    let step;

    switch (jobStep) {
      case 1:
        step = (
          <PostJobStep1
            jobState={jobState}
            jobStep={jobStep}
            rebookJob={rebookJob}
            handleUpdateJobState={handleUpdateJobState}
            changeStep={changeStep}
            responseError={responseError}
          />
        );
        break;

      case 2:
        step = (
          <PostJobStep2
            jobState={jobState}
            jobStep={jobStep}
            jobCategories={jobCategories}
            rebookJob={rebookJob}
            handleUpdateJobState={handleUpdateJobState}
            changeStep={changeStep}
            responseError={responseError}
          />
        );
        break;

      case 3:
        step = (
          <PostJobStep3
            jobState={jobState}
            jobStep={jobStep}
            rebookJob={rebookJob}
            priceEstimate={priceEstimateState}
            paymentMethod={paymentMethod}
            handleUpdateJobState={handleUpdateJobState}
            changeStep={changeStep}
            responseError={responseError}
            setStartDateTime={setStartDateTime}
            startDateTime={startDateTime}
            setEndDateTime={setEndDateTime}
            endDateTime={endDateTime}
          />
        );
        break;

      case 4:
        step = (
          <PostJobStepSuccess
            handleClose={() => {
              handleClose();
              navigate(`/dashboard`, {
                replace: true,
              });
            }}
          />
        );
        break;

      default:
        step = (
          <PostJobStep1
            jobState={jobState}
            jobStep={jobStep}
            handleUpdateJobState={handleUpdateJobState}
            changeStep={changeStep}
            responseError={responseError}
          />
        );
        break;
    }

    return step;
  };

  const changeStep = (step: number) => {
    if (step >= 4) {
      return;
    } else {
      setJobStep(step);
    }
  };

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      sx={[
        {
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          ".MuiDialog-paper": {
            px: "64px",
            boxSizing: "border-box",
          },
        },
        (theme) => ({
          [theme.breakpoints.down("sm")]: {
            ".MuiDialog-paper": {
              px: "0px",
            },
          },
        }),
      ]}
    >
      <Box
        sx={[
          { display: "flex", height: "100%" },
          (theme) => ({
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
          }),
        ]}
      >
        <Box
          sx={[
            { pt: "32px", mr: "12px" },
            (theme) => ({
              [theme.breakpoints.down("sm")]: {
                py: "14px",
                px: "20px",
                borderBottom: "1px solid #DDDFDD",
              },
            }),
          ]}
        >
          <Button
            onClick={() => {
              setIsCancelDrawerOpen(true);
            }}
            variant="table"
          >
            Cancel
          </Button>
          <CancelDrawer
            open={isCancelDrawerOpen}
            handleClose={() => {
              handleClose();
              setIsCancelDrawerOpen(false);
            }}
            handleCloseDrawer={() => setIsCancelDrawerOpen(false)}
          />
        </Box>
        <Box
          sx={[
            {
              py: "96px",
              maxWidth: "570px",
              height: "100%",
              borderLeft: "1px solid #DDDFDD",
              borderRight: "1px solid #DDDFDD",
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: jobStep !== 4 ? "flex-start" : "center",
              alignItems: "center",
              flexDirection: "column",
              overflow: "auto",
            },
            (theme) => ({
              [theme.breakpoints.down("sm")]: {
                pt: "24px",
                pb: "50px",
                px: "30px",
                borderLeft: "0px",
                borderRight: "0px",
              },
            }),
          ]}
        >
          <DialogTitle>{jobStep !== 4 && "Request Service"}</DialogTitle>
          <Typography variant="subtitle1">
            {jobStep !== 4 && `Step ${jobStep} / 3`}
          </Typography>
          <Box
            sx={{
              boxSizing: "border-box",
              maxWidth: "315px",
              width: "100%",
            }}
          >
            <DialogActions
              sx={{
                width: "100%",
                px: 0,
                py: 0,
                mt: "20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
              >
                {renderStep()}
              </Box>
            </DialogActions>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default PostJobDialog;
