import React from 'react';
import { AuthTemplate } from '../../../components/Auth/AuthTemplate';
import { LoginForm } from '../../../components/Auth/LoginForm';

const Login = () => {
  return (
    <AuthTemplate
      footer={{
        title: 'No account?',
        buttonText: 'Registration',
        buttonUrl: '/signup',
      }}
    >
      <LoginForm />
    </AuthTemplate>
  );
};

export default Login;
