import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, Dialog, DialogContentText, DialogTitle, DialogContent, Typography, IconButton } from '@mui/material';
import { STORAGE_AUTH_TOKEN, STORAGE_USER, STORAGE_USER_CONFIRM_EMAIL, STORAGE_USER_CONFIRM_TOKEN } from '../../constants/storage';
import { resendEmail, verifyAuth } from '../../services/authService';
import { useResponseError } from '../../hooks/useResponseError';
import { getUserData } from '../../services/userService';
import { useAuth } from '../../hooks/useAuth';
import { CloseModal } from '../Modals/CloseModal';

const ConfirmForm = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const { responseError, setResponseError } = useResponseError();
  const tokenCookies = localStorage.getItem(STORAGE_USER_CONFIRM_TOKEN) || '';
  const confirmEmail = localStorage.getItem(STORAGE_USER_CONFIRM_EMAIL) || '';

  const [isSuccessRequest, setIsSuccessRequest] = useState<boolean | null>(null);
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [isModalResentOpened, setIsModalResentOpened] = useState<boolean>(false);


  const handleSubmit = async () => {
    if (tokenCookies) {
      try {
        const res = await resendEmail({ token: tokenCookies, email: confirmEmail });

        setIsModalResentOpened(true);
      } catch (error: unknown) {
        setResponseError((error as Error).message)
      }
    } else {
      setResponseError('Something is wrong with your confirm token');
    }
  }

  return <>
    <Box
      sx={{
        maxWidth: '315px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <Typography
        align="center"
        variant="h1"
        sx={{
          mb: '16px'
        }}
      >
        Email confirmation
      </Typography>
      <Typography
        align="center"
        variant="body1"
        sx={{
          mb: '16px'
        }}
      >
        We've sent you an email <br /> to confirm your registration.
      </Typography>
      <Button variant="contained" onClick={handleSubmit}>Send email Again</Button>
    </Box>
    <Dialog
      sx={[{
        '.MuiDialog-paper': {
          px: '55px',
          py: '10px',
          pt: '20px',
          maxWidth: '440px',
          boxSizing: 'border-box',
          width: '100%'
        }
      }, (theme) => ({
        [theme.breakpoints.down('md')]: {
          '.MuiDialog-paper': {
            px: '15px',
          }
        }
      })]
      }
      open={isModalResentOpened}
      onClose={() => setIsModalResentOpened(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CloseModal close={() => setIsModalResentOpened(false)} />
      <DialogTitle sx={{
        fontWeight: '700',
        textAlign: 'center'
      }}>
        {responseError ? "Something went wrong" : `Request has been submitted successfully`}
      </DialogTitle>
      {!responseError && <DialogContent>
        <DialogContentText sx={{
          fontWeight: '400',
          color: '#008986',
          mb: '5px',
          textAlign: 'center'
        }}>
          Please check your email. If you do not see the message in your inbox, please check spam.
        </DialogContentText>
      </DialogContent>}
    </Dialog>
  </>;
};

export default ConfirmForm;
