import * as yup from 'yup';

export const passwordConfirmRegisterValidation = yup
  .string()
  .required('New Password is required')
  .min(8, 'New Password must have at least 8 characters')
  .matches(/[A-Z]/, 'New Password must contain at least one uppercase letter')
  .matches(/[a-z]/, 'New Password must contain at least one lowercase letter')
  .matches(/[0-9]/, 'New Password must contain at least one number')
  .matches(/[^A-Za-z0-9]/, 'New Password must contain at least one symbol');

export const passwordRegisterValidation = yup
  .string()
  .required('Password is required')
  .min(8, 'Password must have at least 8 characters')
  .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
  .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
  .matches(/[0-9]/, 'Password must contain at least one number')
  .matches(/[^A-Za-z0-9]/, 'Password must contain at least one symbol');

export const emailValidation = yup
  .string()
  .required('Email is required')
  .max(100, 'Email should not exceed 100 characters')
  .matches(
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    "Email isn't valid"
  );