import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { emailValidation, passwordConfirmRegisterValidation, passwordRegisterValidation } from './validation';
import {
  filledInputClasses,
  Box,
  useTheme,
  Button,
  Typography,
  FormControlLabel,
  Link,
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material';
import { InputText } from '../../elements/InputText';
import { useResponseError } from '../../hooks/useResponseError';
import { requestPasswordResetAuth } from '../../services/authService';
import { ErrorBox } from '../../elements/ErrorBox';

export const ResetPassword = () => {
  const { palette, typography } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { responseError, setResponseError } = useResponseError();
  const [succesMsg, setSuccessMsg] = useState('');
  const [isModalResentOpened, setIsModalResentOpened] = useState<boolean>(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    try {
      const res = await requestPasswordResetAuth(data);
      setIsModalResentOpened(true);
      setSuccessMsg('If a user with this email exists in our system, we will send a link to reset your password.')
    } catch (error: unknown) {
      setResponseError((error as Error).message)
    }

  }

  return (
    <Box
      sx={{
        maxWidth: '315px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box
        sx={{
          width: '100%'
        }}
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography
          align="center"
          variant="h1"
          sx={{
            mb: '16px'
          }}
        >
          Forgot password?
        </Typography>
        <Typography
          align="center"
          variant="body1"
          sx={{
            mb: '16px'
          }}
        >
          Enter your email address <br />to receive a link to reset your password.
        </Typography>
        <Box sx={{ mb: '4px' }}>
          <InputText
            inputProps={{ ...register('email') }}
            type='email'
            label='Email address'
            placeholder='Email address'
            error={!!errors['email']}
            helperText={errors['email'] ? errors['email'].message : ''}
            fullWidth
          />
        </Box>
        {responseError && <ErrorBox
          sx={{
            mb: '8px',
            textAlign: 'left',
          }}>
          {responseError || 'Something went wrong'}
        </ErrorBox>
        }
        <Button fullWidth variant="contained" type="submit">Send Email</Button>
      </Box>
      <Dialog
        sx={[{
          '.MuiDialog-paper': {
            px: '55px',
            py: '10px',
            pt: '20px',
            maxWidth: '440px',
            boxSizing: 'border-box',
            width: '100%'
          }
        }, (theme) => ({
          [theme.breakpoints.down('md')]: {
            '.MuiDialog-paper': {
              px: '15px',
            }
          }
        })]
        }
        open={isModalResentOpened}
        onClose={() => setIsModalResentOpened(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={() => setIsModalResentOpened(false)}
          aria-label="close"
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            justifyContent: 'flex-end',
            maxWidth: '25px',
            width: '100%',
            padding: '2px',
            ml: 'auto'
          }}
        >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 6L14 14" stroke="#333333" strokeWidth="2" />
            <path d="M6 14L14 6" stroke="#333333" strokeWidth="2" />
          </svg>
        </IconButton>
        <DialogTitle sx={{
          fontWeight: '700',
          textAlign: 'center'
        }}>
          {responseError ? "Something went wrong" : `Request has been submitted successfully`}
        </DialogTitle>
        {!responseError && succesMsg && succesMsg.length > 0 && <DialogContent>
          <DialogContentText sx={{
            fontWeight: '400',
            fontSize: '16px',
            color: palette.success.main,
            mb: '5px',
            textAlign: 'center'
          }}>
            {succesMsg}
          </DialogContentText>
        </DialogContent>}
      </Dialog>
    </Box>
  );
};

const schema = yup.object().shape({
  email: emailValidation,
});
