import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Link as MaterialLink } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TBreadcrumbs } from '../types/types';

type TProps = {
  links: TBreadcrumbs[];
}

export default function Breadcrumb({ links }: TProps) {
  const navigate = useNavigate();

  return (
    <Breadcrumbs sx={{
      marginBottom: '25px',
      display: 'flex',
      alignItems: 'center',
      '.MuiBreadcrumbs-li': {
        display: 'flex',
        alignItems: 'center',
        fontSize: '13px',
      },
      '.MuiBreadcrumbs-separator': {
        mx: '4px',
        fontSize: '13px',
      }
    }} aria-label="breadcrumb">
      {
        links.map((item: TBreadcrumbs) => {
          if (item.title === links[links.length - 1].title) {
            return (
              <Typography
                sx={{
                  textTransform: 'capitalize'
                }}
                key={item.title + item.url}
                color="text.secondary"
                variant="subtitle1"
              >
                {item.title}
              </Typography>
            )
          }
          return (
            <MaterialLink
              onClick={() => navigate(item.url)}
              key={item.title + item.url}
              color="inherit"
              to={item.url}
              component={Link as any}
              sx={{
                fontSize: '12px',
                lineHeight: '16px',
                cursor: 'pointer',
                textTransform: 'capitalize',
                borderBottom: 'none',
                '&:hover': {
                  borderBottom: `1px solid #3D9B3D`,
                }
              }}
            >
              {item.title}
            </MaterialLink>
          )
        })
      }
    </Breadcrumbs>
  );
}