import React from 'react';
import {
  Drawer,
  Button,
  IconButton,
  Box,
  Link as MuiLink,
  Typography,
  drawerClasses,
} from '@mui/material';

type Props = {
  open: boolean,
  isSuspended: boolean,
  fullName: string,
  email: string,
  id: string,
  onClose: () => void
  onSubmitSuspend: (id: string, suspend: boolean) => void
}

export const SuspendDrawer = ({ open, onClose, onSubmitSuspend, isSuspended, fullName, email, id }: Props) => {

  const handleClose = () => {
    onClose()
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      hideBackdrop
      sx={{
        [`& .${drawerClasses.paper}`]: {
          boxShadow: 'none',
          borderLeft: '1px solid #DDDFDD',
          maxWidth: '570px',
          width: '100%',
        }
      }}
    >
      <Box sx={{
        py: 3.5,
        px: 5,
        height: 1,
        maxWidth: '570px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Box sx={{
          height: 1,
          maxWidth: '315px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center'
        }}>
          <Typography variant='h1' component={'h2'} sx={{ mb: '16px' }}>
            {isSuspended ? 'Un-Suspend User?' : 'Suspend User?'}
          </Typography>
          <Typography sx={{
            mb: '16px',
          }}>
            {isSuspended ? 'The user will be able to log in.' : 'The user will not be able to log in.'}
          </Typography>
          <Box sx={{
            mb: '32px',
            width: '100%',
          }}>
            {isSuspended ? <Button fullWidth variant="contained" color="primary" onClick={() => onSubmitSuspend(id, false)}>Un-Suspend User</Button>
              : <Button fullWidth variant="contained" color="error" onClick={() => onSubmitSuspend(id, true)}>Suspend User</Button>}
          </Box>
          <Box sx={{
            width: '100%',
            borderTop: '1px solid #DDDFDD',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <Typography
              sx={{
                mt: '32px',
                mb: '10px',
                textTransform: 'none',
                fontSize: 'inherit',
                lineHeight: 'inherit'
              }}
              variant="body2"
            >
              {fullName}
            </Typography>
            <Typography
              sx={{
                color: '#455445'
              }}
              variant="body2"
            >
              {email}
            </Typography>
          </Box>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              fontSize: '40px',
              cursor: 'pointer'
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
              <rect width="40" height="40" />
              <path d="M27.0713 12.929L12.9292 27.0711" stroke="#666666" />
              <path d="M27.0713 27.071L12.9292 12.9289" stroke="#666666" />
            </svg>
          </IconButton>
        </Box>

      </Box>
    </Drawer>
  )
}