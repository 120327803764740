import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTheme, SvgIcon } from '@mui/material';

export default function InputPassword({ ...props }) {
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Box sx={{
      display: 'flex',
      flexWrap: 'wrap',
      borderRadius: '4px',
      paddingTop: '4px',
      paddingBottom: '4px',
      position: 'relative',
      marginBottom: '8px',
      '& .MuiFormLabel-root': {
        position: 'static',
        transform: 'none',
        marginBottom: '8px',
        color: '#333333',
        fontSize: '12px',
        lineHeight: '16px',
        paddingLeft: '12px'
      },
      '& .MuiOutlinedInput-root': {
        paddingRight: '0',
        overflow: 'hidden',
        borderRadius: '4px',
        backgroundColor: '#fff',
        border: '1px solid',
        borderColor: '#CCCCCC',
        transition: theme.transitions.create([
          'border-color',
          'background-color',
          'box-shadow',
        ]),
        '&:hover': {
          backgroundColor: '#fff',
        },
        '&.Mui-focused': {
          backgroundColor: '#fff',
          borderColor: theme.palette.primary.main,
        },
        '&.Mui-error': {
          borderColor: '#EB5757',
        },
        '& .MuiInputBase-input': {
          paddingLeft: '12px',
          paddingTop: '8px',
          paddingBottom: '8px'
        },
        '& .Mui-disabled': {
          backgroundColor: 'grey'
        },
        '& .MuiInputAdornment-root': {
          position: 'absolute',
          right: '15px',
        }
      },
      '.MuiFormLabel-asterisk': {
        display: 'none',
      },
      '.MuiFormHelperText-contained': {
        bottom: '-12px',
      },
      '.MuiOutlinedInput-notchedOutline': {
        display: 'none',
      },
    }}>
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="password" shrink >{props?.label ? props.label : 'Password'}</InputLabel>
        <OutlinedInput
          id="password"
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ?
                  <SvgIcon>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="20" height="20" />
                      <path d="M10 6C7.32515 6 4.89946 7.40288 3.10954 9.68154C2.96349 9.86822 2.96349 10.129 3.10954 10.3157C4.89946 12.5971 7.32515 14 10 14C12.6748 14 15.1005 12.5971 16.8905 10.3185C17.0365 10.1318 17.0365 9.87097 16.8905 9.68428C15.1005 7.40288 12.6748 6 10 6ZM10.1919 12.8167C8.41628 12.9238 6.94998 11.5209 7.06168 9.81606C7.15332 8.41043 8.34182 7.2711 9.80812 7.18325C11.5837 7.07618 13.05 8.47907 12.9383 10.1839C12.8438 11.5868 11.6553 12.7261 10.1919 12.8167ZM10.1031 11.5154C9.14657 11.5731 8.35614 10.8181 8.41915 9.90117C8.46783 9.14345 9.10934 8.53123 9.89977 8.48181C10.8563 8.42416 11.6467 9.17914 11.5837 10.0961C11.5322 10.8566 10.8907 11.4688 10.1031 11.5154Z" fill="#455445" />
                    </svg>
                  </SvgIcon> :
                  <SvgIcon>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="20" height="20" />
                      <path d="M10 6C7.32515 6 4.89946 7.40288 3.10954 9.68154C2.96349 9.86822 2.96349 10.129 3.10954 10.3157C4.89946 12.5971 7.32515 14 10 14C12.6748 14 15.1005 12.5971 16.8905 10.3185C17.0365 10.1318 17.0365 9.87097 16.8905 9.68428C15.1005 7.40288 12.6748 6 10 6ZM10.1919 12.8167C8.41628 12.9238 6.94998 11.5209 7.06168 9.81606C7.15332 8.41043 8.34182 7.2711 9.80812 7.18325C11.5837 7.07618 13.05 8.47907 12.9383 10.1839C12.8438 11.5868 11.6553 12.7261 10.1919 12.8167ZM10.1031 11.5154C9.14657 11.5731 8.35614 10.8181 8.41915 9.90117C8.46783 9.14345 9.10934 8.53123 9.89977 8.48181C10.8563 8.42416 11.6467 9.17914 11.5837 10.0961C11.5322 10.8566 10.8907 11.4688 10.1031 11.5154Z" fill="#DDDFDD" />
                    </svg>
                  </SvgIcon>}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
          {...props}
        />
      </FormControl>
    </Box>
  );
}
