import React from 'react';
import { AuthTemplate } from '../../../components/Auth/AuthTemplate';
import ConfirmForm from '../../../components/Auth/ConfirmForm';
import SignupComplete from '../../../components/Auth/SignupComplete';
import { useSearchParams } from 'react-router-dom';

const ConfirmSignup = () => {
  const [searchParams] = useSearchParams();
	const token = searchParams.get("token");

  return (
    <AuthTemplate
      footer={token ? undefined : {
        title: 'Already have an account?',
        buttonText: 'login',
        buttonUrl: '/login',
      }}
    >
      { token ?  <SignupComplete /> : <ConfirmForm />}
    </AuthTemplate>
  );
};

export default ConfirmSignup;
