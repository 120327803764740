import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { STORAGE_AUTH_TOKEN } from "../constants/storage";


interface AuthContextType {
  user: any;
  login: (user: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType>(null!);

export const AuthProvider = ({ children, storageValue }:any) => {
  const [user, setUser] = useLocalStorage(STORAGE_AUTH_TOKEN, (storageValue || null) );
  const navigate = useNavigate();

  const login = async (data:any) => {
    setUser(data);
  };

  const logout = () => {
    setUser(null);
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login", { replace: true });
    window.location.reload();
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};