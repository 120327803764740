import { Box, useTheme, Pagination, PaginationItem, SvgIcon } from '@mui/material';

export const CustomPagination = ({ totalPages, page, handleChangePage }: any) => {
  const { palette, typography } = useTheme();

  const PrevIcon = () => (
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 1L2 6L6 11" stroke="#455445" strokeWidth="2" />
    </svg>
  );

  const NextIcon = () => (
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L5 6L1 11" stroke="#455445" strokeWidth="2" />
    </svg>
  );

  return (
    <Pagination
      sx={[
        {
          mr: '12px',
        },
        (theme) => ({
          [theme.breakpoints.down('xs')]: {
            mb: '16px'
          },
        }),
      ]}
      count={totalPages}
      page={page}
      onChange={handleChangePage}
      variant="outlined"
      shape="rounded"
      color="primary"
      renderItem={(item) => (
        <PaginationItem
          sx={{
            '&.MuiPaginationItem-root': {
              borderColor: '#DDDFDD'
            },
            '&.MuiPaginationItem-previousNext': {
              border: 'none',
              color: palette.text.secondary,
            },
            '&.Mui-selected': {
              backgroundColor: palette.primary.main,
              color: 'white'
            },
            '&.Mui-selected:hover': {
              backgroundColor: palette.secondary.main,
              color: 'white'
            },
          }}
          slots={{
            previous: PrevIcon,
            next: NextIcon
          }}
          {...item}
        />
      )}
    />
  );
}