import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Box, Link as MuiLink, } from '@mui/material';

interface TermsOfServiceDialogProps {
  open: boolean;
  onClose: () => void;
}

export const TermsOfServiceDialog = ({ open, onClose }: TermsOfServiceDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h6">Terms of Service</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">Effective Date: 1 July 2024</Typography>
        <Typography variant="h6">1. Acceptance of Terms</Typography>
        <Typography variant="body1">
          By accessing or using the Lucky Services web application (the "App") or website, you agree to comply with and be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, do not use the App or website.
        </Typography>
        <Typography variant="h6">2. Services Provided</Typography>
        <Typography variant="body1">
          Lucky Services offers a variety of services including but not limited to:
        </Typography>
        <ul>
          <Typography variant="body1" component="li">Regular Cleaning</Typography>
          <Typography variant="body1" component="li">Deep Cleaning</Typography>
          <Typography variant="body1" component="li">Short-term Rental (STR) Cleaning</Typography>
          <Typography variant="body1" component="li">Pet Services</Typography>
          <Typography variant="body1" component="li">Light Handyman Work</Typography>
          <Typography variant="body1" component="li">Delivery Services</Typography>
          <Typography variant="body1" component="li">Labor Services (e.g., snow shoveling)</Typography>
        </ul>
        <Typography variant="body1">
          A comprehensive list of services and their respective prices can be found on the Lucky Services website. Users must accept the Privacy Policy upon registration and the Terms of Service when providing payment information through the App.
        </Typography>
        <Typography variant="h6">3. Geographic Scope</Typography>
        <Typography variant="body1">
          All web app services are currently provided exclusively within the state of Colorado, specifically targeting the Durango, CO area and the Ouray-Ridgway-Montrose, CO area.
        </Typography>
        <Typography variant="h6">4. Regulatory Compliance</Typography>
        <Typography variant="body1">
          Lucky Services complies with applicable state and federal regulations in Colorado. Users are responsible for complying with all local laws and regulations when using our services.
        </Typography>
        <Typography variant="h6">5. User Authentication and Security</Typography>
        <Typography variant="body1">
          Users must verify their email address upon registration and adhere to complex password requirements. User account information, including name, email, and password, is securely stored and processed by a third-party Authentication Processor. The use and storage of this information are governed by the Authentication Processor's privacy policy.
        </Typography>
        <Typography variant="h6">6. User Profile Management</Typography>
        <Typography variant="body1">
          Users can update their personal information, such as email address, phone number, first and last name, primary service area, and billing address, via the Client Dashboard/Settings area. Billing information updates are facilitated through an embedded Stripe feature. Password reset requests trigger a password reset email to the registered email address.
        </Typography>
        <Typography variant="h6">7. Data Retention and Deletion</Typography>
        <Typography variant="body1">
          User data is retained as specified in our Privacy Policy. Users can request the deletion of their data by contacting Lucky Services. Deletion requests will be processed in accordance with legal and business requirements.
        </Typography>
        <Typography variant="h6">8. Payment Processing</Typography>
        <Typography variant="body1">
          Payments are processed through Stripe, our third-party Payment Processor. Users authorize Lucky Services to charge the card on file for services rendered immediately after completion. Payment information is securely handled in compliance with Stripe's privacy policy.
        </Typography>
        <Typography variant="h6">9. Refund Policy</Typography>
        <Typography variant="body1">
          Refunds and credits are discretionary and evaluated on a case-by-case basis by Lucky Services management. Cancellations or changes to scheduled services must be received and confirmed by Lucky Services at least 48 hours in advance to avoid a $50 fee. Use
          <MuiLink
            sx={{
              ml: '4px',
              color: 'inherit',
              textTransform: 'none',
              fontSize: 'inherit',
              lineHeight: 'inherit',
              borderBottom: 'none',
            }}
            underline="none"
            href="tel: 1-877-246-5825"
            variant="body1"
          >
            1-877-246-5825
          </MuiLink>
        </Typography>
        <Typography variant="h6">10. User Obligations</Typography>
        <Typography variant="body1">
          Users are expected to provide accurate and complete information when submitting service requests. Courteous and respectful interactions with Lucky Assistants and employees are mandatory. Users must ensure that their contact information is up-to-date and accessible for communication purposes.
        </Typography>
        <Typography variant="h6">11. Service Limitations</Typography>
        <Typography variant="body1">
          Lucky Services endeavors to provide high-quality services but does not guarantee specific outcomes. Users should contact Lucky Services customer service at             <MuiLink
            sx={{
              ml: '4px',
              color: 'inherit',
              textTransform: 'none',
              fontSize: 'inherit',
              lineHeight: 'inherit',
              borderBottom: 'none',
            }}
            underline="none"
            href="tel: 1-877-246-5825"
            variant="body1"
          >
            1-877-246-5825
          </MuiLink> for any issues or concerns.
        </Typography>
        <Typography variant="h6">12. Dispute Resolution</Typography>
        <Typography variant="body1">
          Any disputes arising out of or relating to these Terms or the use of services will be resolved through binding arbitration in accordance with the rules of the American Arbitration Association. Arbitration will take place in Colorado, and users waive their right to participate in class-action lawsuits.
        </Typography>
        <Typography variant="h6">13. Privacy and Data Protection</Typography>
        <Typography variant="body1">
          The collection, use, and protection of personal information are detailed in our Privacy Policy. This includes information on data collection methods, usage, sharing with third parties, and user rights regarding personal data.
        </Typography>
        <Typography variant="h6">14. Service Booking and Cancellation</Typography>
        <Typography variant="body1">
          Users can book services through the App by creating an account and providing necessary details. Service requests are reviewed by the Lucky Services team for availability. Confirmations are provided via the service history view in the App and through email. Users must contact Lucky Services directly for any changes to confirmed services.
        </Typography>
        <Typography variant="h6">15. Billing and Invoicing</Typography>
        <Typography variant="body1">
          Users authorize Lucky Services to charge the card on file for the actual services performed, which may vary from initial estimates. Invoices are sent through Stripe the week following service completion.
        </Typography>
        <Typography variant="h6">16. Onboarding and Communication</Typography>
        <Typography variant="body1">
          New customers receive a detailed service confirmation email, including contact information for Lucky Services. The primary communication channel is phone, with email serving as a secondary option. Response times are prioritized based on the urgency of the matter.
        </Typography>
        <Typography variant="h6">17. Service Rates and Estimates</Typography>
        <Typography variant="body1">
          Service rates are calculated based on user-provided information during the service request process in the App. Estimates are subject to change based on actual services performed. Users may be contacted for additional authorization if service details do not substantively match initial inputs.  Mileage outside of city limits is charged at a rate of $0.80 per mile.  Reimbursable expenses, applicable mileage expenses, local taxes, and other fees are calculated at time invoice.
          Local taxes are calculated by service address.<br />
          Mileage is calculated at time of service and is computed by driving distance from edge of the city limits of the following Service Areas:<br />
        </Typography>
        <Box sx={{
          my: 0,
          listStyleType: 'none',
        }} component="ul">
          <Typography variant="body1" component="li">Durango, CO: City limits of the City of Durango, Colorado</Typography>
          <Typography variant="body1" component="li">Ouray-Ridgway-Montrose, CO: City limits of the City of Montrose, Colorado</Typography>
          <Typography variant="body1" component="li">Sante Fe, NM: City limits of the City of Sante Fe, New Mexico</Typography>
        </Box>
        <Typography variant="h6">18. Service Guarantees and Quality Assurance</Typography>
        <Typography variant="body1">
          Lucky Services is committed to customer satisfaction. While we strive to meet high standards, mistakes can occur. Users should report any service issues to Lucky Services management, not directly to the Lucky Assistant. Issues are handled on a case-by-case basis to ensure resolution.
        </Typography>
        <Typography variant="h6">19. Additional Services</Typography>
        <Typography variant="body1">
          In addition to cleaning services, Lucky Services offers light handyman work, pet services, and labor services such as snow shoveling. Availability of these services depends on staff capabilities and user requirements.
        </Typography>
        <Typography variant="h6">20. Legal Document Updates</Typography>
        <Typography variant="body1">
          The Terms of Service and Privacy Policy are reviewed and updated annually or as needed. Users will be notified of significant changes via email. For minor updates, users will be prompted to review and accept the updated documents before booking a service request.
        </Typography>
        <Typography variant="h6">21. Contact Information</Typography>
        <Typography variant="body1">
          For any questions or concerns regarding these Terms, please contact us at:
        </Typography>
        <ul>
          <Typography variant="body1" component="li">Phone:
            <MuiLink
              sx={{
                ml: '4px',
                color: 'inherit',
                textTransform: 'none',
                fontSize: 'inherit',
                lineHeight: 'inherit',
                borderBottom: 'none',
              }}
              underline="none"
              href="tel: 1-877-246-5825"
              variant="body1"
            >
              1-877-246-5825
            </MuiLink>
          </Typography>
          <Typography variant="body1" component="li">Email:
            <MuiLink
              sx={{
                ml: '4px',
                color: 'inherit',
                textTransform: 'none',
                fontSize: 'inherit',
                lineHeight: 'inherit',
                borderBottom: 'none',
              }}
              underline="none"
              href="mailto:office@luckyservices.com"
              variant="body2"
            >
              office@luckyservices.com
            </MuiLink>
          </Typography>
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};