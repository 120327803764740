import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAuth } from '../../hooks/useAuth';

export const SettingsLogout = () => {
  const { logout } = useAuth();

  return (
    <Box>
      <Typography
        variant="h2"
        sx={{
          mb: '16px'
        }}
      >
        Logout
      </Typography>
      <Button
        variant="outlined"
        color="error"
        onClick={() => logout()}>
        Logout
      </Button>
    </Box>
  );
}
