import { createTheme } from '@mui/material/styles';
import { outlinedInputClasses, inputLabelClasses } from '@mui/material';
import { BorderColor } from '@mui/icons-material';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 500,
      sm: 700,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: 'Poppins',
    fontSize: 16,
    allVariants: {
      color: '#0A1E0B'
    },
    h1: {
      fontFamily: 'Poppins',
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 500,
    },
    h2: {
      fontFamily: 'Poppins',
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 500,
      color: '#455445'
    },
    h3: {
      fontFamily: 'Poppins',
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 500,
    },
    button: {
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: '0.04em',
      lineHeight: '16px',
      color: '#FFFFFF'
    },
    body1: {
      fontSize: '16px',
      lineHeight: '24px'
    },
    body2: {
      fontSize: '10px',
      lineHeight: '14px'
    },
    subtitle1: {
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
    },
    subtitle2: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '16px',
    }
  },
  palette: {
    primary: {
      main: '#3D9B3D',
    },
    secondary: {
      main: '#6FBB4B',
    },
    error: {
      main: '#FF5F5C',
    },
    text: {
      primary: '#0A1E0B',
      secondary: '#455445',
      disabled: '#A3AAA3'
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '20px',
          paddingRight: '20px',
          '@media (min-width:600px)': {
            paddingLeft: '22px',
            paddingRight: '22px',
          },
          '@media (min-width:960px)': {
            paddingLeft: '42px',
            paddingRight: '42px',
          },
          '@media (min-width:1280px)': {
            paddingLeft: '64px',
            paddingRight: '64px',
          },
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#0A1E0B'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          lineHeight: '20px',
          color: '#455445',
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '.MuiTypography-root': {
            color: '#455445',
          },
          '& .MuiRadio-root.Mui-checked + .MuiTypography-root': {
            color: '#0A1E0B',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          borderRadius: '4px',
          backgroundColor: '#fff',
          paddingTop: '2px',
          paddingBottom: '2px',
          '& .MuiSelect-select': {
            paddingLeft: '12px',
            paddingTop: '8px',
            paddingBottom: '8px',
            fontSize: '14px',
            lineHeight: '20px'
          },
          '&:hover': {
            backgroundColor: '#fff',
          },
          '&.Mui-focused': {
            backgroundColor: '#fff',
            borderColor: '#3D9B3D',
          },
          '& .MuiSelect-select:focus': {
            backgroundColor: '#fff',
          },
          '& .select-icon': {
            width: 10,
            height: 7,
            display: 'flex',
            right: '15px',
            position: 'absolute',
            cursor: 'pointer',
            pointerEvents: 'none',
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid #3D9B3D`,
          fontWeight: '400',
          textTransform: 'uppercase',
          textDecoration: 'none',
          fontSize: '12px',
          lineHeight: '16px',
          letterSpacing: '0.04em',
          '&:hover': {
            borderBottom: `1px solid #6FBB4B`,
            color: '#6FBB4B'
          }
        }
      }
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            [`& .${outlinedInputClasses.root}`]: {
              padding: 0,
              fontSize: '14px',
              lineHeight: '20px'
            },
            [`& .${outlinedInputClasses.input}`]: {
              paddingLeft: '12px',
              paddingTop: '8px',
              paddingBottom: '8px'
            },
          }
        },
      ]
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          borderRadius: '10px',
          paddingTop: '8px',
          paddingBottom: '8px',
          paddingLeft: '12px',
          paddingRight: '12px',
          '@media (max-width:700px)': {
            paddingLeft: '12px',
            paddingRight: '12px',
          },
        },
        containedError: {
          color: '#fff',
          fontWeight: '500',
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: '#6FBB4B',
          },
        },
        outlinedPrimary: {
          '&:hover': {
            backgroundColor: '#6FBB4B',
            border: '1px solid #6FBB4B',
            color: '#FFFFFF',
          },
        },
      },
      variants: [
        {
          props: { variant: 'table' },
          style: {
            border: '1px solid #3D9B3D',
            color: '#3D9B3D',
            '&.Mui-disabled': {
              borderColor: '#A3AAA3'
            },
            '&:hover': {
              backgroundColor: '#6FBB4B',
              border: '1px solid #6FBB4B',
              color: '#FFFFFF',
            },
          },
        },
      ]
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          lineHeight: '20px'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          '::-webkit-scrollbar': {
            width: '15px',
          },
          '::-webkit-scrollbar-thumb': {
            background: '#3D9B3D',
          },
          '::-webkit-scrollbar-thumb:hover': {
            opacity: 0.7,
          },
        },
        body: {
          '::-webkit-scrollbar': {
            width: '15px',
          },
          '::-webkit-scrollbar-thumb': {
            background: '#3D9B3D',
          },
          '::-webkit-scrollbar-thumb:hover': {
            opacity: 0.7,
          },
        },
      },
    },
  },
  spacing: 8,
});
