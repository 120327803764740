import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useResponseError } from '../../hooks/useResponseError';
import { InputText } from '../../elements/InputText';
import SelectControl from '../../elements/SelectControl';
import { useEffect, useState } from 'react';
import { getServiceAreas } from '../../services/userService';
import { phoneRegex } from '../../utils';
import { STORAGE_USER } from '../../constants/storage';
import { ErrorBox } from '../../elements/ErrorBox';

export const PersonalInfo = ({ control, register, errors, userProfile }: any) => {
  const { responseError, setResponseError } = useResponseError();
  const [selectOptions, setSelectOptions] = useState();
  const { palette, typography } = useTheme();

  useEffect(() => {
    const getServiceAreasValues = async () => {
      try {
        const res = await getServiceAreas();

        const formatedItems = res.map((item: {
          id: string,
          name: string,
        }) => ({
          value: item.id,
          label: item.name
        }));

        setSelectOptions(formatedItems);
        // setValue('serviceAreaId', formatedItems[0].id);
      } catch (error: unknown) {
        setResponseError((error as Error)?.message || "Something went wrong");
      }
    };

    getServiceAreasValues();

    return () => { };
  }, []);

  return (
    <Box sx={{
      width: '100%'
    }}>
      <Typography
        variant="h2"
        sx={{
          mb: '16px'
        }}
      >
        Personal information
      </Typography>
      <Box>
        <Box>
          <InputText
            inputProps={{ ...register('email') }}
            type='email'
            label='Email address'
            placeholder='Email address'
            error={!!errors['email']}
            helperText={errors['email'] ? errors['email'].message : ''}
            fullWidth
            disabled
            defaultValue={userProfile.email}
          />
        </Box>
        <Box>
          <InputText
            inputProps={{ ...register('phoneNumber') }}
            type='tel'
            label='Phone number'
            placeholder='Phone number'
            error={!!errors['phoneNumber']}
            helperText={errors['phoneNumber'] ? errors['phoneNumber'].message : ''}
            fullWidth
          />
        </Box>
        <Box>
          <InputText
            inputProps={{ ...register('firstName') }}
            type='text'
            label='First name'
            placeholder='First name'
            error={!!errors['firstName']}
            helperText={errors['firstName'] ? 'This is required' : ''}
            fullWidth
          />
        </Box>
        <Box>
          <InputText
            inputProps={{ ...register('lastName') }}
            type='text'
            label='Last name'
            placeholder='Last name'
            error={!!errors['lastName']}
            helperText={errors['lastName'] ? 'This is required' : ''}
            fullWidth
          />
        </Box>
        {selectOptions && <Box sx={{
          position: 'relative',
        }}>
          <Controller
            control={control}
            name='serviceAreaId'
            render={({ field: { onChange, onBlur, value } }) => (
              <SelectControl
                label='Primary service area'
                items={selectOptions}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />
          {errors['serviceAreaId'] &&
            <ErrorBox
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {errors['serviceAreaId'].message}
            </ErrorBox>
          }
        </Box>}
      </Box>
    </Box>
  );
}