import React, { Fragment, useEffect } from 'react';
import { Box, Button, FormControl, FormControlLabel, FormLabel, RadioGroup, Typography, useTheme } from '@mui/material';
import RadioControl from '../../../elements/RadioControl';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ErrorBox } from '../../../elements/ErrorBox';

const mainOptions = {
  cleaning: [
    "General Home Cleaning",
    "Office cleaning",
    "AirBnB/Vacation Rental Cleaning"
  ],
  "Moving Assistance/ Packing and Unpacking": [
    "Moving Assistance",
    "Packing and Unpacking"
  ],
  "Heavy Lifting & Loading": [],
  "Handyman Work": [
    "Furniture Building",
    "General Mounting",
    "Light Carpentry"
  ],
  "Yard Work": [],
  "Snow Removal": [],
  "Organization": [],
  "Personal Assistant": [],
  "Event Staffing": [],
  "Grocery Delivery": [],
  "Errands & Deliveries": [],
  "Dog Walking": [],
  "Pet Sitting/House Sitting": [],
};

type MainCategory = keyof typeof mainOptions;

type FormValues = {
  mainCategory: MainCategory;
  subCategory: string;
};

interface IJobState {
  [key: string]: any;
}

type TPostJobStep2Props = {
  jobState: any;
  jobCategories: any;
  rebookJob?: any;
  jobStep: number;
  responseError: string | null;
  handleUpdateJobState: (data: IJobState) => void;
  changeStep: (step: number) => void;
};

export const PostJobStep2 = ({ jobState, handleUpdateJobState, changeStep, jobStep, jobCategories, rebookJob, responseError }: TPostJobStep2Props) => {
  const { palette } = useTheme();

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(schema),
  });

  const selectedMainCategory = watch('mainCategory');
  const selectedSubCategory = watch('subCategory');


  useEffect(() => {
    if (rebookJob) {
      if (rebookJob?.category?.parentId) {
        setValue('mainCategory', rebookJob?.category?.parentId);
        setValue('subCategory', rebookJob?.category?.id);
      } else {
        setValue('mainCategory', rebookJob?.category?.id);
        setValue('subCategory', '');
      }

    }
  }, [rebookJob]);



  const onSubmit = async (data: any): Promise<any> => {
    handleUpdateJobState(data);
    changeStep(jobStep + 1);
  }

  const renderMainCategoryRadioGroup = () => (
    <FormControl component="fieldset">
      <Controller
        name="mainCategory"
        control={control}
        defaultValue={jobCategories[0].id}
        render={({ field }) => (
          <RadioGroup
            {...field}
            onChange={(e) => {
              const value = e.target.value;

              const mainCategory = jobCategories.find((category: any) =>
                category.children.some((sub: any) => sub.id === selectedSubCategory)
              );

              const selectedMainCategoryObject = jobCategories.find((category: any) =>
                category.id === value
              );

              if (mainCategory?.id !== value) {
                setValue('subCategory', '');
        
                if (selectedMainCategoryObject?.children && selectedMainCategoryObject?.children.length > 1) {
                  setValue('subCategory', selectedMainCategoryObject?.children[0].id);
                }
              }

              field.onChange(value)
            }}>
            {jobCategories.map((category: any, id: number) => (
              <Fragment key={id + category}>
                <FormControlLabel
                  key={id}
                  value={category.id}
                  control={<RadioControl key={category.id} />}
                  label={category.name}
                />
                {category?.children.length > 0 && renderSubCategoryRadioGroup(category?.children)}
              </Fragment>
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  );

  const renderSubCategoryRadioGroup = (categories: any[]) => {
    return (
      <Box sx={{ pl: '30px' }}>
        <FormControl component="fieldset">
          <Controller
            name="subCategory"
            control={control}
            defaultValue={categories[0].id}
            render={({ field }) => (
              <RadioGroup
                {...field}
                onChange={(e) => {
                  const value = e.target.value;

                  const mainCategory = jobCategories.find((category: any) =>
                    category.children.some((sub: any) => sub.id === value)
                  );

                  if (mainCategory) {
                    setValue('mainCategory', mainCategory.id);
                  }

                  field.onChange(value)
                }}>
                {categories.map((subCategory, index) => (
                  <FormControlLabel
                    key={subCategory.id}
                    value={subCategory.id}
                    control={<RadioControl key={subCategory.id} />}
                    label={subCategory.name}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
      </Box>
    )
  };

  return (
    <>
      <Box sx={{ width: '100%', mb: '32px' }}>
        <Typography
          align="center"
          variant="h2"
          sx={{
            mb: '32px'
          }}
        >
          Service category
        </Typography>
        {renderMainCategoryRadioGroup()}
        {responseError &&
          <ErrorBox
            sx={{
              mt: '8px',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            {responseError}
          </ErrorBox>
        }
      </Box>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Button sx={{ mr: '16px' }} variant="table" onClick={() => changeStep(jobStep - 1)}>Back</Button>
        <Button fullWidth variant="contained" onClick={handleSubmit(onSubmit)}>Next step</Button>
      </Box>
    </>
  );
};

const schema = yup.object().shape({
  mainCategory: yup.string().required('MainCategory is required'),
  subCategory: yup.string(),
});