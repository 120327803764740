
import { handleApiError } from './handleApiError';
import instance, { baseURL } from './index';

type LoginProps = {
  email: string;
  password: string;
};;

type VerifyProps = {
  token: string;
}

type ResendProps = {
  token: string;
}

type PasswordResetProps = {
  email: string | null;
};

type TRegistrationProps = {
  email: string,
  firstName: string,
  phoneNumber: string,
  lastName: string,
  serviceAreaId: string,
  password: string,
  passwordConfirm: string,
};

type TResetPassword = {
  token: string,
  userId: string,
  password: string,
}

type TResetPasswordVerify = {
  token: string,
  userId: string,
}

type TPatchPassword = {
  oldPassword: string,
  password: string,
  passwordConfirm: string,
}

export const registerAuth = async (data: TRegistrationProps) => {
  try {
    const response = await instance.post(`/user/signup`, data,);
    return response.data;
  } catch (error: unknown) {
    handleApiError(error);
  }
}

export const loginAuth = async (data: LoginProps) => {
  try {
    const response = await instance.post(`/auth/login`, data);
    return response.data;
  } catch (error: unknown) {
    handleApiError(error);
  }
};

export const verifyAuth = async (data: VerifyProps) => {
  try {
    const response = await instance.post(`/user/verify`, {
      token: data.token
    }, {
      headers: {
        'Authorization': `Bearer ${data.token}`
      }
    });
    return response.data;
  } catch (error: unknown) {
    handleApiError(error);
  }
};

export const resendEmail = async ({ token, email }: {
  token: any, email: string
}) => {
  try {
    const response = await instance.post(`/user/resend/verify`, {
      email
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error: unknown) {
    handleApiError(error);
  }
};


export const requestPasswordResetAuth = async (data: PasswordResetProps) => {
  try {
    await instance.post(`/user/password-reset/request`, {
      email: data.email,
    });
  } catch (error: unknown) {
    handleApiError(error);
  }
};

export const passwordResetAuth = async (data: TResetPassword) => {
  try {
    await instance.post(`/user/password-reset`, data);
  } catch (error: unknown) {
    handleApiError(error);
  }
};

export const passwordResetVerify = async (data: TResetPasswordVerify) => {
  try {
    await instance.post(`/user/password-reset/verify`, data);
  } catch (error: unknown) {
    handleApiError(error);
  }
};
