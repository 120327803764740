import { Box, useTheme, styled } from '@mui/material';
import { MobileTimePicker, TimePicker, TimePickerProps } from '@mui/x-date-pickers';

export const CustomTimePicker = styled((props: any) => (
  <MobileTimePicker
    {...props}
    slotProps={{
      toolbar: {
        sx: {
          '& .MuiPickersToolbarText-root': {
            fontSize: '3rem',
            lineHeight: '1.167',
            letterSpacing: '0em',
            fontWeight: '400',
            opacity: '0.5'
          },
          '& .MuiTimePickerToolbar-ampmSelection .MuiTimePickerToolbar-ampmLabel': {
            fontSize: '1.5rem',
            lineHeight: '1.2',
            opacity: '0.5'
          },
          '& .MuiTimePickerToolbar-ampmSelection .MuiTimePickerToolbar-ampmLabel.Mui-selected, & .MuiPickersToolbarText-root.Mui-selected': {
            opacity: '1'
          }
        }
      },
      textField: props.slotProps.textField
    }}
  />
))(({ theme }) => ({
  '&': {
    borderRadius: '4px',
    paddingTop: '4px',
    paddingBottom: '4px',
    position: 'relative',
    marginBottom: '8px'
  },
  '& .MuiPickersToolbarText-root': {
    fontSize: '3rem',
    lineHeight: '1.167',
    letterSpacing: '0em',
  },
  '& .MuiFormLabel-root': {
    position: 'static',
    transform: 'none',
    marginBottom: '8px',
    color: '#333333',
    fontSize: '12px',
    lineHeight: '16px',
    paddingLeft: '12px'
  },
  '& .MuiOutlinedInput-root': {
    paddingRight: '12px',
    overflow: 'hidden',
    borderRadius: '4px',
    backgroundColor: '#fff',
    border: '1px solid',
    borderColor: '#CCCCCC',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-error': {
      borderColor: '#EB5757',
    },
    '& .MuiInputBase-input': {
      paddingLeft: '12px',
      paddingTop: '8px',
      paddingBottom: '8px'
    },
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '20px'
    }
  },
  '.MuiFormLabel-asterisk': {
    display: 'none',
  },
  '.MuiFormHelperText-root': {
    fontSize: '14px',
    color: theme.palette.error.main,
  },
  '.MuiFormHelperText-contained': {
    // position: 'absolute',
    bottom: '-12px',
  },
  '.MuiOutlinedInput-notchedOutline': {
    display: 'none',
  }
}));