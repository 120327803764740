import {SvgIcon } from '@mui/material';

export const AlertIcon = ({ sx }: any) => {
  return (
    <SvgIcon
      sx={{
        ...sx
      }}
    >
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.527 11.8019L10.7453 1.17367C9.81638 -0.390184 7.55194 -0.392262 6.62176 1.17367L0.840348 11.8019C-0.109262 13.3999 1.04043 15.4235 2.90164 15.4235H14.4652C16.3249 15.4235 17.4766 13.4015 16.527 11.8019ZM8.68353 13.5055C8.15487 13.5055 7.72453 13.0751 7.72453 12.5465C7.72453 12.0178 8.15487 11.5874 8.68353 11.5874C9.2122 11.5874 9.64254 12.0178 9.64254 12.5465C9.64254 13.0751 9.2122 13.5055 8.68353 13.5055ZM9.64254 9.66943C9.64254 10.1981 9.2122 10.6284 8.68353 10.6284C8.15487 10.6284 7.72453 10.1981 7.72453 9.66943V4.87439C7.72453 4.34572 8.15487 3.91538 8.68353 3.91538C9.2122 3.91538 9.64254 4.34572 9.64254 4.87439V9.66943Z" fill="#F09E44" />
      </svg>
    </SvgIcon>
  );
}