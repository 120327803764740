import { Theme, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/system';

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '4px 8px',
    backgroundColor: '#fff',
    color: theme.palette.text.primary,
    fontSize: (theme as Theme).typography.subtitle1.fontSize,
    lineHeight: (theme as Theme).typography.subtitle1.lineHeight,
    textTransform: 'uppercase',
    borderRadius: '55px',
    border: '1px solid #DDDFDD',
  },
}));