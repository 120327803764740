import { Typography, Box, useTheme, Button } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { TermsOfServiceDialog } from '../Modals/TermsOfServiceDialog';
import PrivacyPolicyDialog from '../Modals/PrivacyPolicyDialog';

export const Footer = () => {
  const location = useLocation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenPrivacy = () => {
    setOpenPrivacy(true);
  };

  const handleClosePrivacy = () => {
    setOpenPrivacy(false);
  };


  return (
    <Box
      sx={[
        {
          position: 'fixed',
          bottom: 0,
          // right: '15px',
          maxWidth: '100%',
          width: '100%',
          height: '60px',
          py: '18px',
          backgroundColor: '#fff',
          borderTop: '1px solid #DDDFDD',
          textTransform: 'uppercase',
          zIndex: '1',
          '& .MuiTypography-root': {
            color: theme.palette.text.disabled
          }
        },
        (theme) => ({
          [theme.breakpoints.down('sm')]: {
            height: '92px',
            py: '10px',
          }
        }),
      ]}
      component="footer"
    >
      <Container sx={{ height: '100%' }} maxWidth={false}>
        <Box sx={[
          {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap'
          },
          (theme) => ({
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexWrap: 'nowrap'
            }
          }),
        ]}>
          <Typography
            sx={[
              (theme) => ({
                [theme.breakpoints.down('sm')]: {
                  mb: '10px'
                }
              }),
            ]}
            variant='button'
          >
            Lucky Services {(new Date().getFullYear())}
          </Typography>
          <Box sx={[
            { ml: 'auto', display: 'flex', alignItems: 'baseline' },
            (theme) => ({
              [theme.breakpoints.down('sm')]: {
                ml: '0px',
                width: '100%',
                justifyContent: 'flex-start'
              }
            }),
          ]}>
            <Box sx={[
              {
                mr: '32px',
              },
              (theme) => ({
                [theme.breakpoints.down('sm')]: {
                  mr: '0px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                },
                [theme.breakpoints.down('xs')]: {
                  mr: '0px',
                  paddingLeft: '0px',
                  paddingRight: '10px',
                },
              }),
            ]}>
              <Link
                to={'mailto:office@goluckyservices.com'}
                style={{ textDecoration: 'none' }}
              >
                <Typography variant='button'>
                  Contact
                </Typography>
              </Link>
            </Box>
            <Button
              sx={{
                textTransform: 'uppercase',
                textDecoration: 'none',
                '@media (max-width:500px)': {
                  paddingLeft: '5px',
                  paddingRight: '5px',
                },
              }}
              onClick={handleOpenPrivacy}
            >
              <Typography variant='button'>
                Privacy Policy
              </Typography>
            </Button>
            <Button
              sx={{
                textTransform: 'uppercase',
                textDecoration: 'none',
                '@media (max-width:500px)': {
                  paddingLeft: '5px',
                  paddingRight: '5px',
                },
              }}
              onClick={handleOpen}
            >
              <Typography variant='button'>
                Terms of Service
              </Typography>
            </Button>
            <TermsOfServiceDialog open={open} onClose={handleClose} />
            <PrivacyPolicyDialog open={openPrivacy} onClose={handleClosePrivacy} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}